// This is (simplified) React recommended way of dealing with canceling setState before unmount
// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
type cancelableCallback = <T>(value?: T) => void;

export class CancelableCallback {
    private hasCanceled = false;
    private readonly callback;

    constructor(callback: cancelableCallback) {
        this.callback = callback;
    }

    getCallback = <T>(value?: T) => (this.hasCanceled ? value : this.callback(value));

    cancel = () => (this.hasCanceled = true);
}
