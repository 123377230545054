import { Reducer } from 'redux';

import type { RootState } from '../../app/store';
import { en } from '../constants/languages/en';
import { TranslationKeys, Translations } from '../constants/stringKeys';

const languageReducer: Reducer<Translations> = (state = en) => state;

export default languageReducer;

export const getTranslatedText = (state: RootState['language'], key: TranslationKeys) => state[key] || '';

export const getTranslatedTemplateText = (
    state: RootState['language'] = en,
    key: TranslationKeys,
    replaces: Record<string, string>
): string =>
    Object.keys(replaces).reduce(
        (text, key) => text.replace(new RegExp(`{${key}}`, 'g'), replaces[key]),
        state[key] || ''
    );
