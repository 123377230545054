import { FC } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '../../../app/store';
import {
    CAREER_STRUCTURE_ID,
    CATERER_GLOBAL_ID,
    CATERER_ID,
    CW_JOBS_ID,
    IRISH_JOBS_ID,
    JOBS_IE_ID,
    MILKROUND_ID,
    NI_JOBS_COM_ID,
    RETAIL_CHOICE_ID,
    TJG_ID,
    TJobBoard
} from '../../constants/jobBoard';
import { session } from '../../reducers';
import CareerStructureFooter from './brand/CareerStructureFooter';
import CatererFooter from './brand/CatererFooter';
import Catererglobal from './brand/Caterereglobal';
import CwJobsFooter from './brand/CwJobsFooter';
import IrishJobsFooter from './brand/IrishJobsFooter';
import JobsIeFooter from './brand/JobsIeFooter';
import Milkround from './brand/MilkroundFooter';
import NiJobsComFooter from './brand/NiJobsComFooter';
import RetailChoiceFooter from './brand/RetailChoiceFooter';
import TotaljobsFooter from './brand/TotaljobsFooter';

interface IProps {
    id?: TJobBoard;
}

export const Footer: FC<IProps> = ({ id }) => {
    switch (id) {
        case TJG_ID:
            return TotaljobsFooter();
        case CATERER_ID:
            return CatererFooter();
        case IRISH_JOBS_ID:
            return IrishJobsFooter();
        case JOBS_IE_ID:
            return JobsIeFooter();
        case RETAIL_CHOICE_ID:
            return RetailChoiceFooter();
        case CW_JOBS_ID:
            return CwJobsFooter();
        case CAREER_STRUCTURE_ID:
            return CareerStructureFooter();
        case NI_JOBS_COM_ID:
            return NiJobsComFooter();
        case CATERER_GLOBAL_ID:
            return Catererglobal();
        case MILKROUND_ID:
            return Milkround();
        default:
            return null;
    }
};

const mapStateToProps = (state: RootState) => ({ ...session.getJobBoardData(state) });

export default connect(mapStateToProps)(Footer);
