import { Action } from '../action';
import { POST } from '../constants/http-methods';
import { LOG_TYPE, SEND_LOG } from '../constants/log';

type LogAction =
    | typeof LOG_TYPE.FORM_RENDERED
    | typeof LOG_TYPE.FRONTEND_ATS_REDIRECT
    | typeof LOG_TYPE.FRONTEND_ERROR_OCCURRED
    | typeof LOG_TYPE.URL_PARSE_ERROR
    | typeof LOG_TYPE.NO_RESPONSE_FROM_SERVER
    | typeof LOG_TYPE.POST_APPLICATION_URL_PARSE_ERROR
    | typeof LOG_TYPE.FILE_UPLOAD_FAILURE;

export const sendLog = <T extends Record<string, unknown>>(
    conversationId: string,
    actionType: LogAction,
    params: T
): Action<typeof SEND_LOG, T> => {
    fetch('/log', {
        method: POST,
        body: JSON.stringify({ conversationId, action_type: actionType, ...params })
    });

    return { type: SEND_LOG, actionType, ...params };
};
