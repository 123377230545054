import Cookies from 'js-cookie';
import { ThunkAction } from 'redux-thunk';

import {
    Action,
    createFailureName,
    createRequestName,
    createSuccessName,
    FailedActionName,
    RequestActionName,
    SuccessActionName
} from '../../common/action';
import { session } from '../../common/reducers';
import { FormStructureDto, ResponseError, ResponseKeepAlive, ResponseProvide, ResponseRaw } from '../../mirage/api';
import { job } from '../../mirage/reducers';
import { ValueOf } from '../../utils';
import { BASE_URL, CSRF_TOKEN_COOKIE_NAME, JSON_HEADERS, X_XSRF_TOKEN_HEADER_NAME } from '../constants';
import { GET, POST } from '../constants/http-methods';
import { ACTION_FAILURE_REASON } from '../constants/log';

type FetchDataRequest<ActionName, HTTPMethod> = Partial<Omit<RequestInit, 'headers' | 'method'>> & {
    actionName: ActionName;
    method: HTTPMethod;
    requestData?: RequestInit;
    url: string;
};

export type ResponseOrError<T> = T | ResponseRaw | ResponseError;

export type FormStructureResponse = FormStructureDto;

type EndpointToResponseMap = {
    // POST
    'apply/action': FormStructureResponse;
    'apply/upload': FormStructureResponse;
    'apply/submit': FormStructureResponse;
    'session/keepAlive': ResponseKeepAlive;
    // GET
    'form/provide': ResponseProvide;
    'form/structure': FormStructureResponse;
};

type FailedActionPayload<R> = {
    /** Predictable and consistent error cause described by `ACTION_FAILURE_REASON` */
    cause: ValueOf<typeof ACTION_FAILURE_REASON>;
    /** Usually it should be an Error object */
    error: unknown;
    request: R;
    /** HTTP status code */
    status: number;
};

const fetchData: {
    // Overload for GET request where actions have optional request payload
    <ActionName extends string, Pathname extends keyof EndpointToResponseMap, HTTPMethod extends typeof GET, R>(
        { actionName, url, method, body, ...otherData }: FetchDataRequest<ActionName, HTTPMethod>,
        request?: R
    ): ThunkAction<
        Promise<EndpointToResponseMap[Pathname]>,
        any,
        void,
        | Action<RequestActionName<ActionName>, { request: R }>
        | Action<SuccessActionName<ActionName>, { request: R; response: EndpointToResponseMap[Pathname] }>
        | Action<FailedActionName<ActionName>, FailedActionPayload<R>>
    >;
    // Overload for POST request where actions have mandatory request payload
    <ActionName extends string, Pathname extends keyof EndpointToResponseMap, HTTPMethod extends typeof POST, R>(
        { actionName, url, method, body, ...otherData }: FetchDataRequest<ActionName, HTTPMethod>,
        request: R
    ): ThunkAction<
        Promise<EndpointToResponseMap[Pathname]>,
        any,
        void,
        | Action<RequestActionName<ActionName>, { request: R }>
        | Action<SuccessActionName<ActionName>, { request: R; response: EndpointToResponseMap[Pathname] }>
        | Action<FailedActionName<ActionName>, FailedActionPayload<R>>
    >;
} =
    <
        ActionName extends string,
        Pathname extends keyof EndpointToResponseMap,
        HTTPMethod extends typeof GET | typeof POST,
        R
    >(
        { actionName, url, method, body, ...otherData }: FetchDataRequest<ActionName, HTTPMethod>,
        request?: R
    ): ThunkAction<
        Promise<EndpointToResponseMap[Pathname]>,
        any,
        void,
        | Action<RequestActionName<ActionName>, { request: R | undefined }>
        | Action<SuccessActionName<ActionName>, { request: R | undefined; response: EndpointToResponseMap[Pathname] }>
        | Action<FailedActionName<ActionName>, FailedActionPayload<R | undefined>>
    > =>
    async (dispatch, getState) => {
        dispatch({ type: createRequestName(actionName), request });

        const state = getState();
        const headers = [
            [X_XSRF_TOKEN_HEADER_NAME, Cookies.get(CSRF_TOKEN_COOKIE_NAME)] as const,
            ['conversationUuid', session.getConversationUuid(state)] as const,
            ['jobBoardId', session.getJobBoardId(state)] as const,
            ['atsId', job.getAtsId(state)] as const,
            ['integrationId', job.getIntegrationId(state)] as const,
            ['jobId', job.getJobId(state)] as const,
            ['companyId', job.getCompanyId(state)] as const
        ].reduce(
            (headers: Record<string, string>, [headerName, headerValue]) => {
                if (headerValue) {
                    headers[headerName] = headerValue;
                }
                return headers;
            },
            {
                ...JSON_HEADERS
            }
        );

        let response: Response;
        try {
            response = await window.fetch(`${BASE_URL}/${url}`, {
                method,
                headers,
                body,
                ...otherData.requestData
            });
        } catch (error) {
            return dispatch({
                type: createFailureName(actionName),
                cause: ACTION_FAILURE_REASON.NETWORK_ERROR,
                error,
                request,
                status: 0
            });
        }

        let jsonResponse;
        try {
            jsonResponse = await response.json();
        } catch (error) {
            return dispatch({
                type: createFailureName(actionName),
                cause: ACTION_FAILURE_REASON.MALFORMED_RESPONSE,
                error,
                request,
                status: response.status
            });
        }

        try {
            dispatch({
                type: createSuccessName(actionName),
                response: jsonResponse,
                request
            });
            return jsonResponse;
        } catch (error) {
            return dispatch({
                type: createFailureName(actionName),
                cause: ACTION_FAILURE_REASON.RESPONSE_PROCESSING_ERROR,
                error,
                request,
                status: response.status
            });
        }
    };

export const getData = <
    ActionName extends string,
    Pathname extends keyof EndpointToResponseMap,
    RequestData extends RequestInit | undefined
>(
    actionName: ActionName,
    url: Pathname,
    requestData?: RequestData
) =>
    fetchData<ActionName, Pathname, typeof GET, RequestData>(
        {
            actionName,
            url,
            method: GET,
            requestData
        },
        requestData
    );

export const postData = <
    ActionName extends string,
    Pathname extends keyof EndpointToResponseMap,
    RequestBody extends Record<string, any>
>(
    actionName: ActionName,
    url: Pathname,
    data: RequestBody,
    requestData?: RequestInit
) =>
    fetchData<ActionName, Pathname, typeof POST, RequestBody>(
        {
            actionName,
            url,
            method: POST,
            body: JSON.stringify(data),
            requestData
        },
        data
    );
