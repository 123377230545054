import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { IMirageAccordionWidget } from '@stepstone/ats-scrapers-core/types';
import styles from './AccordionWidget.module.scss';
import { clickAndWaitForElement } from '../../../../../common/actions/form';

export type OwnProps = { id: string };

export type ComponentProps = IMirageAccordionWidget;

export type StateProps = {
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

export type DispatchProps = {
    clickAndWaitForElement: typeof clickAndWaitForElement;
};

export type Props = Omit<StateProps, 'xPath' | 'waitForCssSelector' | 'conversationUuid'> & {
    handleClick: () => void;
};

const AccordionWidgetView: React.FC<Props> = ({ handleClick, text, isAccordionExpanded, dataRole }) => (
    <div
        className={classNames('mb-3', styles.accordionContainer)}
        onClick={handleClick}
        data-role={dataRole}
        aria-expanded={isAccordionExpanded}
    >
        <div className={styles.text}>{text}</div>
        <FontAwesomeIcon icon={isAccordionExpanded ? 'caret-up' : 'caret-down'} className={styles.icon} />
    </div>
);

AccordionWidgetView.displayName = 'AccordionWidgetView';

export default AccordionWidgetView;
