import { connect } from 'react-redux';

import { form } from '../../../../../common/reducers';

import { Props, InlineMultiFieldView, OwnProps } from './InlineMultiFieldView';
import type { RootState } from '../../../../../app/store';

const mapStateToProps = (state: RootState, { id }: OwnProps): Props => ({
    ...form.getElement<Props>(state, id)
});

export default connect(mapStateToProps)(InlineMultiFieldView);
