import { ThunkDispatch } from 'redux-thunk';

const FAILURE_SUFFIX = 'FAILURE';
export type FailedActionName<T extends string> = `${T}_${typeof FAILURE_SUFFIX}`;
export const createFailureName = <T extends string>(name: T): FailedActionName<T> => `${name}_${FAILURE_SUFFIX}`;

const REQUEST_SUFFIX = 'REQUEST';
export type RequestActionName<T extends string> = `${T}_${typeof REQUEST_SUFFIX}`;
export const createRequestName = <T extends string>(name: T): RequestActionName<T> => `${name}_${REQUEST_SUFFIX}`;

const SUCCESS_SUFFIX = 'SUCCESS';
export type SuccessActionName<T extends string> = `${T}_${typeof SUCCESS_SUFFIX}`;
export const createSuccessName = <T extends string>(name: T): SuccessActionName<T> => `${name}_${SUCCESS_SUFFIX}`;

export type Action<T extends string, P = Record<string, unknown>> = { type: T } & P;

type InferActionFromThunkDispatch<T> = T extends ThunkDispatch<any, any, infer Z> ? Z : never;

type InferSafelyFirstParameter<T extends unknown[]> = T extends [infer H, ...any[]] ? H : never;

export type InferActionFromThunk<T extends (...args: any) => any> = InferActionFromThunkDispatch<
    InferSafelyFirstParameter<Parameters<ReturnType<T>>>
>;
