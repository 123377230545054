import React from 'react';

const IrishJobsFooter = () => (
    <div className="copyright" style={{ padding: '20px 0 5px 0', marginTop: '10px', background: '#000' }}>
        <div className="container">
            <div className="row">
                <div className="col-xs-12 content" style={{ textAlign: 'center', lineHeight: '35px' }}>
                    <div className="footer-container">
                        <ul className="list-inline footer m-2" style={{ paddingBottom: '20px' }}>
                            <li className="first">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.irishjobs.ie/about/about-us"
                                >
                                    About us
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.irishjobs.ie/about/terms-and-conditions"
                                >
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.irishjobs.ie/about/cookies"
                                >
                                    Cookie Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.irishjobs.ie/about/privacy-policy"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.irishjobs.ie/about/partners"
                                >
                                    Partner Sites
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.irishjobs.ie/about/help-and-support"
                                >
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div style={{ width: '100%', borderTop: '1px solid #222' }}>
                        <span style={{ textAlign: 'center', color: '#666666', fontSize: '0.9em' }}>
                            © {new Date().getFullYear()} IrishJobs.ie Registered in Ireland under Company Number 260762
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default IrishJobsFooter;
