import React, { Ref } from 'react';

import { IMirageText } from '@stepstone/ats-scrapers-core/types';
import RenderTextArray from '../../../../common/components/ui/RenderTextArray';

type TTextIntrinsicElements = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'div' | 'span';

export type TTextElementProps = {
    text: IMirageText[] | string;
    tagName: TTextIntrinsicElements;
    id: string;
    type: string;
};

type TTextElementTagNameMap = {
    p: HTMLParagraphElement;
    h1: HTMLHeadingElement;
    h2: HTMLHeadingElement;
    h3: HTMLHeadingElement;
    h4: HTMLHeadingElement;
    div: HTMLDivElement;
    span: HTMLSpanElement;
};

export type TRef = Ref<TTextElementTagNameMap[TTextElementProps['tagName']]>;

// TODO correct ref type (TRef)
const TextElementView = React.forwardRef(({ text, tagName }: TTextElementProps, ref: TRef) => {
    const TagName = tagName.toLowerCase();
    // @ts-expect-error tagName is not recognized as IntrinsicElement
    return <TagName ref={ref}>{<RenderTextArray text={text} />}</TagName>;
});

TextElementView.displayName = 'TextElement';

export default TextElementView;
