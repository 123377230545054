import React, { FC } from 'react';

import CustomPage from '../ui/CustomPage';
import { ALREADY_APPLIED_PAGE_MESSAGE, ALREADY_APPLIED_PAGE_TITLE } from '../../constants/stringKeys';
import TranslatedText from '../ui/TranslatedText';

const AlreadyAppliedPage: FC = () => (
    <CustomPage
        icon="user-times"
        message={<TranslatedText textKey={ALREADY_APPLIED_PAGE_MESSAGE} />}
        title={<TranslatedText textKey={ALREADY_APPLIED_PAGE_TITLE} />}
    />
);

export default AlreadyAppliedPage;
