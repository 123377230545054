import {
    APPLY_BUTTON_TEXT,
    COMPANY_MESSAGE,
    CREATING_MESSAGE,
    EXPIRE_PAGE_MESSAGE,
    EXPIRE_PAGE_TITLE,
    FETCHING_MESSAGE,
    LOADING_PAGE_TITLE,
    NEXT_BUTTON_TEXT,
    REGISTER_BUTTON_TEXT,
    PREFILLING_MESSAGE,
    BACK_BUTTON_TEXT,
    REDIRECT_PAGE_MESSAGE,
    REDIRECT_PAGE_TITLE,
    REQUIRED_FIELDS_MESSAGE,
    TERMS_MESSAGE,
    THANK_YOU_PAGE_MESSAGE,
    THANK_YOU_PAGE_TITLE,
    WRONG_FORM_PAGE_MESSAGE,
    WRONG_FORM_PAGE_TITLE,
    NO_FILE_TEXT,
    BROWSE_TEXT,
    DEFAULT_COMPANY_NAME,
    ACCOUNT_CREATION_MESSAGE,
    COLLAPSE_BTN_SHOW_TEXT,
    COLLAPSE_BTN_HIDE_TEXT,
    REQUIREMENTS_NOT_MET_PAGE_TITLE,
    REQUIREMENTS_NOT_MET_PAGE_MESSAGE,
    ALREADY_APPLIED_PAGE_TITLE,
    ALREADY_APPLIED_PAGE_MESSAGE,
    UPLOADING_LABEL_TEXT,
    UPLOADED_LABEL_TEXT,
    OK,
    CANCEL,
    FILE_TOO_LARGE_MESSAGE,
    Translations,
    REFRESH_CAPTCHA_BTN_TEXT
} from '../stringKeys';

export const pl: Translations = {
    [EXPIRE_PAGE_TITLE]: 'Sesja wygasła',
    [EXPIRE_PAGE_MESSAGE]: 'Sesja wygasła. Spróbuj ponownie.',
    [REDIRECT_PAGE_TITLE]: 'Przekierowanie na stronę rekrutera',
    [REDIRECT_PAGE_MESSAGE]: 'Trwa przekierowanie na stronę rekrutera. Powodzenia w kolejnych etapach rekrutacji!',
    [THANK_YOU_PAGE_TITLE]: 'Aplikacja zakończona pomyślnie',
    [THANK_YOU_PAGE_MESSAGE]: 'Twoja aplikacja została przesłana. Powodzenia w kolejnych etapach rekrutacji',
    [WRONG_FORM_PAGE_TITLE]: 'Formularz niedostępny',
    [WRONG_FORM_PAGE_MESSAGE]: 'Przepraszamy, formularz oferty nie jest dostępny. Spróbuj ponownie później.',
    [LOADING_PAGE_TITLE]: 'Wczytywanie danych',
    [PREFILLING_MESSAGE]: 'Uzupełnianie danych kandydata...',
    [CREATING_MESSAGE]: 'Inicjalizowanie sesji...',
    [FETCHING_MESSAGE]: 'Pobieranie danych.',
    [TERMS_MESSAGE]:
        'Aplikując na tę ofertę, wyrażasz zgodę na przekazanie Twojej aplikacji do {companyName} oraz akceptujesz Regulamin i Politykę Prywatności {companyName}.',
    [ACCOUNT_CREATION_MESSAGE]:
        'By registering your account, you consent to your application being transferred to {companyName} and you agree with {companyName}’s Terms & Conditions and' +
        ' Privacy Policy',
    [REGISTER_BUTTON_TEXT]: 'Register and continue',
    [REQUIRED_FIELDS_MESSAGE]: 'Pole wymagane',
    [COMPANY_MESSAGE]: 'Aplikujesz na ofertę {companyName} udostępnianą poprzez platformę {jobBoardName}',
    [APPLY_BUTTON_TEXT]: 'Wysyłam',
    [BACK_BUTTON_TEXT]: 'Wstecz',
    [NEXT_BUTTON_TEXT]: 'Dalej',
    [COLLAPSE_BTN_SHOW_TEXT]: 'Rozwiń',
    [COLLAPSE_BTN_HIDE_TEXT]: 'Zwiń',
    [NO_FILE_TEXT]: 'Nie wybrano pliku.',
    [BROWSE_TEXT]: 'Przeglądaj ...',
    [DEFAULT_COMPANY_NAME]: 'rekrutera',
    [REQUIREMENTS_NOT_MET_PAGE_TITLE]: 'Brak wymaganych kwalifikacji',
    [REQUIREMENTS_NOT_MET_PAGE_MESSAGE]: 'Niestety, nie spełniasz minimalnych wymagań na to stanowisko. ',
    [ALREADY_APPLIED_PAGE_TITLE]: 'Aplikacja wykonana w przeszłości',
    [ALREADY_APPLIED_PAGE_MESSAGE]: 'Wygląda na to, że już wykonano aplikację na tę ofertę z Twoimi danymi.',
    [UPLOADING_LABEL_TEXT]: 'Dodawanie...',
    [UPLOADED_LABEL_TEXT]: 'Usuń plik',
    [OK]: 'Ok',
    [CANCEL]: 'Anuluj',
    [FILE_TOO_LARGE_MESSAGE]: 'Załącznik może mieć maksymalnie {fileSize}. Prześlij mniejszy plik.',
    [REFRESH_CAPTCHA_BTN_TEXT]: 'Odśwież kod captchy'
};
