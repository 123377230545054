import { IMirageLoadingOverlayWidget } from '@stepstone/ats-scrapers-core/types';
import React, { useEffect } from 'react';

import LoadingOverlay from '../../../../../common/components/ui/LoadingOverlay';
import { formStructurePollingStart, formStructurePollingStop } from '../../../../state/form/action';

export type OwnProps = { id: string };

type ComponentProps = IMirageLoadingOverlayWidget;

export type StateProps = OwnProps & ComponentProps;

export type DispatchProps = {
    startPolling: typeof formStructurePollingStart;
    stopPolling: typeof formStructurePollingStop;
};

export type Props = StateProps & DispatchProps;

export const LoadingOverlayWidgetView: React.FC<Props> = ({ startPolling, stopPolling, xPath, ...props }) => {
    useEffect(() => {
        startPolling();
        return () => void stopPolling();
    }, [startPolling, stopPolling, xPath]);

    return <LoadingOverlay {...props} />;
};
