import React, { FC } from 'react';

import './AsteriskHelp.css';
import TranslatedText from './TranslatedText';
import { REQUIRED_FIELDS_MESSAGE } from '../../constants/stringKeys';

interface IProps {
    hasRequired: boolean;
}

const AsteriskHelp: FC<IProps> = ({ hasRequired }) =>
    hasRequired ? (
        <div className="row">
            <div className="col-xs-12 input-row">
                <span className="asterisk-help">
                    <TranslatedText textKey={REQUIRED_FIELDS_MESSAGE} />
                </span>
            </div>
        </div>
    ) : null;

export default AsteriskHelp;
