import { Middleware } from 'redux';

import { createAlert } from '../../common/actions/gui';
import { FILE_TOO_LARGE_FORM_STATE, POST_UPLOAD_SUCCESS_ACTION } from '../../common/constants/form';
import { MAX_FILE_SIZE } from '../../common/constants/session';
import { formatFileTooLargeMessage } from '../../common/fileUploadUtils';

export const showAlertIfFileTooLargeStateMiddleware: Middleware = store => next => action => {
    if (
        POST_UPLOAD_SUCCESS_ACTION === action.type &&
        action.response &&
        action.response.status === FILE_TOO_LARGE_FORM_STATE
    ) {
        store.dispatch(
            createAlert({
                message: formatFileTooLargeMessage(store.getState(), MAX_FILE_SIZE)
            })
        );
    }

    next(action);
};
