import { IMirageModal } from '@stepstone/ats-scrapers-core/types';
import React, { FC } from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { createPostActionWithType } from '../../../actions/form';
import { ACTION_TYPE } from '../../../constants/form';
import { CANCEL, OK } from '../../../constants/stringKeys';
import { gui, session } from '../../../reducers';
import RenderTextArray from '../RenderTextArray';
import TranslatedText from '../TranslatedText';
import './Modal.css';
import type { RootState } from '../../../../app/store';

const BUTTON_TEXT = {
    CANCEL: CANCEL,
    OK: OK
} as const;

type ComponentProps = IMirageModal;

type StateProps = {
    conversationUuid: string;
    isAvailable: boolean;
} & ComponentProps;

type DispatchProps = {
    createPostActionWithType: typeof createPostActionWithType;
};

type Props = Omit<StateProps, 'conversationUuid' | 'closeBtnXPath' | 'confirmBtnXPath'> & {
    handleClose: () => void;
    handleConfirmation: () => void;
    showFooterButtons: boolean;
};

const Modal: FC<Props> = ({
    showFooterButtons,
    handleClose,
    handleConfirmation,
    elements,
    isAvailable,
    closeBtnText,
    confirmBtnText,
    headerTitle
}) =>
    isAvailable ? (
        <BootstrapModal
            animation={false}
            scrollable={true}
            show={isAvailable}
            backdrop={true}
            onHide={handleClose}
            size="lg"
        >
            <BootstrapModal.Header closeButton>
                {headerTitle && <BootstrapModal.Title>{headerTitle}</BootstrapModal.Title>}
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                <RenderTextArray text={elements} />
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
                <Button onClick={handleClose} variant="secondary">
                    {closeBtnText || <TranslatedText textKey={BUTTON_TEXT.CANCEL} />}
                </Button>
                {showFooterButtons && (
                    <Button onClick={handleConfirmation} variant="primary">
                        {confirmBtnText || <TranslatedText textKey={BUTTON_TEXT.OK} />}
                    </Button>
                )}
            </BootstrapModal.Footer>
        </BootstrapModal>
    ) : null;

export default connect(
    (state: RootState): StateProps => ({
        isAvailable: gui.isModalAvailable(state),
        conversationUuid: session.getConversationUuid(state),
        ...gui.getModal(state)
    }),
    { createPostActionWithType },
    (
        { closeBtnXPath, confirmBtnXPath, conversationUuid, ...props }: StateProps,
        { createPostActionWithType }: DispatchProps
    ): Props => ({
        ...props,
        handleClose: () => createPostActionWithType(ACTION_TYPE.CLICK, '', closeBtnXPath, conversationUuid),
        handleConfirmation: () =>
            confirmBtnXPath && createPostActionWithType(ACTION_TYPE.CLICK, '', confirmBtnXPath, conversationUuid),
        showFooterButtons: !!confirmBtnXPath
    })
)(Modal);
