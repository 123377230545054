import { createRequestName, createSuccessName, createFailureName } from '../../common/action';

export const FETCH_FORM_ACTION = 'FETCH_FORM';
export const FETCH_FORM_REQUEST_ACTION = createRequestName(FETCH_FORM_ACTION);
export const FETCH_FORM_SUCCESS_ACTION = createSuccessName(FETCH_FORM_ACTION);
export const FETCH_FORM_FAILURE_ACTION = createFailureName(FETCH_FORM_ACTION);

export const GET_STRUCTURE_ACTION = 'GET_STRUCTURE';
export const GET_STRUCTURE_REQUEST_ACTION = createRequestName(GET_STRUCTURE_ACTION);
export const GET_STRUCTURE_SUCCESS_ACTION = createSuccessName(GET_STRUCTURE_ACTION);
export const GET_STRUCTURE_FAILURE_ACTION = createFailureName(GET_STRUCTURE_ACTION);

export const POSTPONE_STRUCTURE_REFRESH_ACTION = 'POSTPONE_STRUCTURE_REFRESH';

export const POST_ACTION_ACTION = 'POST_ACTION';
export const POST_ACTION_REQUEST_ACTION = createRequestName(POST_ACTION_ACTION);
export const POST_ACTION_SUCCESS_ACTION = createSuccessName(POST_ACTION_ACTION);
export const POST_ACTION_FAILURE_ACTION = createFailureName(POST_ACTION_ACTION);

export const POST_UPLOAD_ACTION = 'POST_UPLOAD';
export const POST_UPLOAD_REQUEST_ACTION = createRequestName(POST_UPLOAD_ACTION);
export const POST_UPLOAD_SUCCESS_ACTION = createSuccessName(POST_UPLOAD_ACTION);
export const POST_UPLOAD_FAILURE_ACTION = createFailureName(POST_UPLOAD_ACTION);

export const FORM_SUBMIT_ACTION = 'FORM_SUBMIT';
export const FORM_SUBMIT_REQUEST_ACTION = createRequestName(FORM_SUBMIT_ACTION);
export const FORM_SUBMIT_SUCCESS_ACTION = createSuccessName(FORM_SUBMIT_ACTION);
export const FORM_SUBMIT_FAILURE_ACTION = createFailureName(FORM_SUBMIT_ACTION);

export const SYNCHRONIZE_FORM_VALUES_ACTION = 'SYNCHRONIZE_FORM_VALUES';

export const FORM_PARAMS_NAMES = ['companyLogoUrl', 'jobTitle', 'companyName'];

export const SUCCESS_FORM_STATE = 'SUCCESS';
export const APPLIED_FORM_STATE = 'APPLIED';
export const REQUIREMENTS_NOT_MET_STATE = 'REQUIREMENTS_NOT_MET';
export const ALREADY_APPLIED_STATE = 'ALREADY_APPLIED';
export const EXPIRED_FORM_STATE = 'EXPIRED';
export const ERROR_FORM_STATE = 'ERROR';
export const SESSION_NOT_FOUND_FORM_STATE = 'SESSION_NOT_FOUND';
export const FILE_TOO_LARGE_FORM_STATE = 'FILE_TOO_LARGE';

export const APPLY_ACTION_URL = 'apply/action';

export const LOGO_ERROR_ACTION = 'LOGO_ERROR';

export const INVALIDATE_SESSION_ACTION = 'INVALIDATE_SESSION';

export const INITIALIZATION_STATE_CREATING = 'CREATING';
export const INITIALIZATION_STATE_PREFILLING = 'PREFILLING';
export const INITIALIZATION_STATE_INITIALIZED = 'INITIALIZED';
export const INITIALIZATION_STATE_ERROR = 'ERROR';
export const INITIALIZATION_STATE_JOB_EXPIRED = 'JOB_EXPIRED';
export const INITIALIZATION_ERROR_MAX_COUNT = 5;

export const DEFAULT_ANCHOR_X_PATH = '//DUMMY_X_PATH';

export const CLICK_AND_WAIT_FOR_FRAME = 'CLICK_AND_WAIT_FOR_FRAME';
export const CLICK_AND_WAIT_FOR_CLOSE = 'CLICK_AND_WAIT_FOR_CLOSE';
export const CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_VISIBLE = 'CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_VISIBLE';
export const CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE = 'CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE';
export const PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK = 'PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK';

export const INVALIDATE_FILE_UPLOAD_ACTION = 'INVALIDATE_FILE_UPLOAD';

export const SurveyType = {
    REGISTRATION: 'REGISTRATION',
    APPLICATION_FOR_A_JOB: 'APPLICATION_FOR_A_JOB'
} as const;

export const ACTION_TYPE = {
    TEXT_INPUT: 'TEXT_INPUT',
    CLICK: 'CLICK',
    CLICK_OUT: 'CLICK_OUT',
    ANCHOR_CLICK: 'ANCHOR_CLICK',
    SELECT: 'SELECT',
    CHANGE: 'CHANGE',
    CLEAR: 'CLEAR',
    SELECT_HIDDEN_OPTION: 'SELECT_HIDDEN_OPTION',
    KEEP_ALIVE: 'KEEP_ALIVE',
    SCROLL_TO_THE_BOTTOM_OF_ELEMENT: 'SCROLL_TO_THE_BOTTOM_OF_ELEMENT',
    CLICK_WITH_JS: 'CLICK_WITH_JS',
    FOCUS: 'FOCUS',
    BLUR: 'BLUR'
} as const;

export const FLOW_TYPE = {
    INTERACTIVE: 'INTERACTIVE',
    ZERO_TOUCH: 'ZERO_TOUCH'
} as const;
