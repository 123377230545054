import {
    ACCOUNT_CREATION_MESSAGE,
    ALREADY_APPLIED_PAGE_MESSAGE,
    ALREADY_APPLIED_PAGE_TITLE,
    APPLY_BUTTON_TEXT,
    BACK_BUTTON_TEXT,
    BROWSE_TEXT,
    COLLAPSE_BTN_HIDE_TEXT,
    COLLAPSE_BTN_SHOW_TEXT,
    COMPANY_MESSAGE,
    CREATING_MESSAGE,
    DEFAULT_COMPANY_NAME,
    EXPIRE_PAGE_MESSAGE,
    EXPIRE_PAGE_TITLE,
    FETCHING_MESSAGE,
    LOADING_PAGE_TITLE,
    NEXT_BUTTON_TEXT,
    NO_FILE_TEXT,
    PREFILLING_MESSAGE,
    REDIRECT_PAGE_MESSAGE,
    REDIRECT_PAGE_TITLE,
    REGISTER_BUTTON_TEXT,
    REQUIRED_FIELDS_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_TITLE,
    TERMS_MESSAGE,
    THANK_YOU_PAGE_MESSAGE,
    THANK_YOU_PAGE_TITLE,
    WRONG_FORM_PAGE_MESSAGE,
    WRONG_FORM_PAGE_TITLE,
    UPLOADING_LABEL_TEXT,
    UPLOADED_LABEL_TEXT,
    OK,
    CANCEL,
    FILE_TOO_LARGE_MESSAGE,
    Translations,
    REFRESH_CAPTCHA_BTN_TEXT
} from '../stringKeys';

export const fr: Translations = {
    [EXPIRE_PAGE_TITLE]: 'Session expirée',
    [EXPIRE_PAGE_MESSAGE]: 'Votre session a expiré. Essayez encore une fois. Bonne chance!',
    [REDIRECT_PAGE_TITLE]: 'Rediriger vers le site du recruteur',
    [REDIRECT_PAGE_MESSAGE]: 'Vous allez être redirigé vers le site du recruteur. Bonne chance !',
    [THANK_YOU_PAGE_TITLE]: 'Votre candidature a été envoyée',
    [THANK_YOU_PAGE_MESSAGE]: 'Votre candidature a été envoyée. Bonne chance!',
    [WRONG_FORM_PAGE_TITLE]: 'Le formulaire est invalide',
    [WRONG_FORM_PAGE_MESSAGE]: 'Le formulaire de demande est invalide. Réessayez plus tard...',
    [LOADING_PAGE_TITLE]: 'Chargement des données',
    [PREFILLING_MESSAGE]: 'Pré-remplissage du formulaire...',
    [CREATING_MESSAGE]: "Session en cours d'initialisation...",
    [FETCHING_MESSAGE]: 'Téléchargement de données. Veuillez patienter.',
    [TERMS_MESSAGE]:
        "En cliquant sur postuler, vous acceptez que votre candidature soit transférée à {companyName} et vous acceptez les Conditions générales d'utilisation et la politique de confidentialité de {companyName}",
    [ACCOUNT_CREATION_MESSAGE]:
        "En enregistrant votre compte, vous acceptez le transfert de votre candidature à {companyName} et les Conditions générales d'utilisation et la politique de confidentialité de {companyName}",
    [REQUIRED_FIELDS_MESSAGE]: 'Les champs obligatoires sont identifiés par',
    [COMPANY_MESSAGE]:
        'Vous postulez à un poste sur le site web de {companyName} qui vous est présenté par {jobBoardName}',
    [REGISTER_BUTTON_TEXT]: 'Inscrivez-vous et continuez',
    [APPLY_BUTTON_TEXT]: 'Postuler',
    [BACK_BUTTON_TEXT]: 'Retour',
    [NEXT_BUTTON_TEXT]: 'Continuer',
    [COLLAPSE_BTN_SHOW_TEXT]: 'Afficher',
    [COLLAPSE_BTN_HIDE_TEXT]: 'Masquer',
    [NO_FILE_TEXT]: 'Aucune pièce jointe.',
    [BROWSE_TEXT]: 'Ajouter',
    [DEFAULT_COMPANY_NAME]: 'recruteur',
    [REQUIREMENTS_NOT_MET_PAGE_TITLE]: 'Les exigences ne sont pas satisfaites',
    [REQUIREMENTS_NOT_MET_PAGE_MESSAGE]: 'Désolé, vous ne répondez pas aux exigences pour ce poste',
    [ALREADY_APPLIED_PAGE_TITLE]: 'Déjà postulé',
    [ALREADY_APPLIED_PAGE_MESSAGE]: 'Désolé, vous avez déjà postulé pour ce poste',
    [UPLOADING_LABEL_TEXT]: 'Veuillez patienter...',
    [UPLOADED_LABEL_TEXT]: 'Retirez le fichier',
    [OK]: 'Bien',
    [CANCEL]: 'Annuler',
    [FILE_TOO_LARGE_MESSAGE]:
        'Votre pièce jointe ne dois pas dépasser {fileSize}. Merci de télécharger un fichier moins volumineux.',
    [REFRESH_CAPTCHA_BTN_TEXT]: 'Actualiser le code captcha'
};
