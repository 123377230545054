import React from 'react';

const NiJobsComFooter = () => (
    <div className="copyright" style={{ padding: '15px 0', marginTop: '10px', background: '#000' }}>
        <div className="container">
            <div className="row">
                <div className="col-xs-12 content" style={{ textAlign: 'center', lineHeight: '50px' }}>
                    <div className="footer-container">
                        <ul className="list-inline footer m-2">
                            <li className="first">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.nijobs.com/about">
                                    About us
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.nijobs.com/about/contact"
                                >
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.nijobs.com/about/privacy"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.nijobs.com/about/terms">
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.nijobs.com/about/cookie-policy"
                                >
                                    Cookie Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.nijobs.com/about/international"
                                >
                                    Partner Sites
                                </a>
                            </li>
                        </ul>
                    </div>
                    <span style={{ textAlign: 'center', color: 'white', fontSize: '0.9em' }}>
                        © 2019 NIJobs.com is a part of saongroup.com
                    </span>
                </div>
            </div>
        </div>
    </div>
);

export default NiJobsComFooter;
