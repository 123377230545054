import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AsyncFileUploadMode, IMirageAsyncFileUploadWithDeleteWidget } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import once from 'lodash/once';
import React, { ChangeEvent, RefObject, createRef } from 'react';
import { Button, FormLabel } from 'react-bootstrap';

import {
    createFileUploadAction,
    createGetCurrentStructureAction,
    createPostActionWithType,
    createSynchronizeFormValuesAction,
    postponeStructureRefresh
} from '../../../../../common/actions/form';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import TranslatedText from '../../../../../common/components/ui/TranslatedText';
import { BROWSE_TEXT, UPLOADING_LABEL_TEXT } from '../../../../../common/constants/stringKeys';
import { isEmptyValidation } from '../../../../../common/fileUploadUtils';
import { formStructurePollingStart, formStructurePollingStop } from '../../../../state/form/action';
import FormGroup from '../../FormGroup';
import HelpText, { STATUS } from '../../fields/HelpText';
import TooltipWidget from '../TooltipWidget';
import { CancelableCallback } from './utils';

type FileDescriptorProps = {
    fileName: string;
};

const FileDescriptor = ({ fileName }: FileDescriptorProps) => <span>{fileName}</span>;

export type OwnProps = { id: string };

export type ComponentProps = Pick<
    IMirageAsyncFileUploadWithDeleteWidget,
    | 'accept'
    | 'customProps'
    | 'dataRole'
    | 'fileName'
    | 'fileSizeLimit'
    | 'forceDeleteButton'
    | 'help'
    | 'isRequired'
    | 'label'
    | 'mode'
    | 'postponeGetStructureTime'
    | 'status'
    | 'tooltipMessage'
    | 'xPath'
    | 'uploadMethod'
>;

export type StateProps = {
    customValidation: string | undefined;
    isUploading: boolean;
    isUploaded: boolean;
    isEmpty: boolean;
    error: boolean;
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

export type DispatchProps = {
    refreshForm: typeof createGetCurrentStructureAction;
    createFileUploadAction: typeof createFileUploadAction;
    createPostActionWithType: typeof createPostActionWithType;
    postponeStructureRefresh: typeof postponeStructureRefresh;
    syncValues: typeof createSynchronizeFormValuesAction;
    startPolling: typeof formStructurePollingStart;
    stopPolling: typeof formStructurePollingStop;
};

export type Props = Omit<
    StateProps,
    'conversationUuid' | 'xPath' | 'postponeGetStructureTime' | 'fileSizeLimit' | 'uploadMethod'
> &
    Pick<DispatchProps, 'refreshForm' | 'syncValues' | 'startPolling' | 'stopPolling'> & {
        uploadFile: (e: ChangeEvent<HTMLInputElement>) => ReturnType<ReturnType<typeof createFileUploadAction>>;
        removeFile: () => void;
    };

type State = {
    isUploading: boolean;
};

class AsyncSingleFileInputWithDeleteView extends React.Component<Props, State> {
    static displayName = 'AsyncSingleFileInputWithDeleteView';

    private cancelableSetState: CancelableCallback | undefined;
    private input: RefObject<HTMLInputElement>;
    private syncValues: Props['syncValues'];

    constructor(props: Props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.getDiv = this.getDiv.bind(this);
        this.state = {
            isUploading: false
        };
        this.syncValues = props.syncValues;
        this.input = createRef<HTMLInputElement>();
    }

    componentDidMount() {
        this.cancelableSetState = new CancelableCallback(() => this.setState({ isUploading: false }));

        if (this.props.isUploading) {
            this.startPolling();
        }
        if (this.props.isUploaded || this.props.isEmpty || this.props.error) {
            this.syncValues();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.isUploading && this.props.isUploading) {
            this.startPolling();
        }
        if (prevProps.isUploading && (this.props.isUploaded || this.props.isEmpty || this.props.error)) {
            this.stopPolling();
            this.syncValues();
        }
    }

    componentWillUnmount() {
        this.stopPolling();
        this.setState({ isUploading: false });
        if (this.cancelableSetState) {
            this.cancelableSetState.cancel();
        }
    }

    render() {
        const {
            accept,
            isUploading,
            isUploaded,
            isRequired,
            label,
            fileName,
            help,
            status,
            id,
            dataRole,
            tooltipMessage,
            customValidation,
            mode = AsyncFileUploadMode.REMOVE
        } = this.props;

        return (
            <FormGroup
                className={classNames({
                    required: label && isRequired,
                    'has-error': status === STATUS.ERROR || !isEmptyValidation(customValidation)
                })}
                controlId={id}
            >
                {label && (
                    <FormLabel>
                        <RenderTextArray text={label} />
                        <TooltipWidget tooltipMessage={tooltipMessage} />
                    </FormLabel>
                )}
                {this.getDiv({
                    isUploading: isUploading || this.state.isUploading,
                    isUploaded,
                    fileName,
                    accept,
                    id,
                    dataRole,
                    mode
                })}
                <HelpText help={help} status={status} id={`${id}_HelpText`} />
                <HelpText help={customValidation} status={STATUS.ERROR} id={`${id}_CustomValidation`} />
            </FormGroup>
        );
    }

    getDiv({
        isUploading,
        isUploaded,
        fileName,
        accept,
        id,
        dataRole,
        mode
    }: Pick<Props, 'isUploading' | 'isUploaded' | 'fileName' | 'accept' | 'id' | 'dataRole' | 'mode'>) {
        if (mode === AsyncFileUploadMode.REMOVE && (isUploaded || this.props.forceDeleteButton) && fileName) {
            return (
                <div>
                    <FileDescriptor fileName={fileName} />
                    <FontAwesomeIcon
                        className="delete-icon"
                        onClick={once(this.props.removeFile)}
                        icon="times"
                        role="img"
                        aria-label="Remove"
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <input
                        ref={this.input}
                        type="file"
                        accept={accept}
                        onChange={this.uploadFile}
                        style={{ display: 'none' }}
                        aria-hidden="true"
                        data-role={dataRole}
                    />
                    <Button
                        id={id}
                        style={{ marginRight: '10px' }}
                        onClick={this.selectFile}
                        aria-required={this.props.isRequired}
                        aria-invalid={this.props.status === 'ERROR'}
                        aria-describedby={`${this.props.id}_HelpText`}
                        disabled={isUploading}
                        variant="primary"
                    >
                        {isUploading && <FontAwesomeIcon icon="spinner" spin className="me-1" />}
                        <TranslatedText textKey={isUploading ? UPLOADING_LABEL_TEXT : BROWSE_TEXT} />
                    </Button>
                    {mode === AsyncFileUploadMode.REPLACE && isUploaded && fileName ? (
                        <FileDescriptor fileName={fileName} />
                    ) : null}
                </div>
            );
        }
    }

    selectFile() {
        const input = this.input.current;
        if (input) {
            input.value = '';
            input.click();
        }
    }

    uploadFile(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ isUploading: true });
        this.props
            .uploadFile(event)
            .then(() => this.props.refreshForm())
            .finally(() => this.cancelableSetState && this.cancelableSetState.getCallback());
    }

    startPolling() {
        this.props.startPolling();
    }

    stopPolling() {
        this.props.stopPolling();
    }
}

export default AsyncSingleFileInputWithDeleteView;
