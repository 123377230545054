import { format } from 'date-fns';
import { de, fr, pl } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';

import { createPostActionWithType } from '../../../../../common/actions/form';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import { form, session } from '../../../../../common/reducers';
import { getLanguage } from '../../../../../index';
import { DatePickerWidget, DispatchProps, OwnProps, Props, StateProps } from './DatePickerWidgetView';
import type { RootState } from '../../../../../app/store';

registerLocale('de', de);
registerLocale('pl', pl);
registerLocale('fr', fr);

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getElement(state, id),
    conversationUuid: session.getConversationUuid(state)
});

const mapDispatchToProps: DispatchProps = {
    createPostActionWithType
};

const mergeProps = (
    { xPath, conversationUuid, locale, triggerClickOnFocus = true, ...stateProps }: StateProps,
    { createPostActionWithType }: DispatchProps
): Props => {
    const postAction = (value: string) =>
        createPostActionWithType(ACTION_TYPE.TEXT_INPUT, value, xPath, conversationUuid);

    const sendBlurAction = () => createPostActionWithType(ACTION_TYPE.BLUR, xPath, xPath, conversationUuid);

    return {
        ...stateProps,

        setValue: (value, dateFormat) => {
            if (value) {
                // @ts-expect-error Problem is related to thunk action which on this level is unpacked
                return postAction(format(value, dateFormat)).then(() => sendBlurAction());
            } else {
                // clear value
                // @ts-expect-error Problem is related to thunk action which on this level is unpacked
                return createPostActionWithType(ACTION_TYPE.CLEAR, xPath, xPath, conversationUuid).then(() =>
                    sendBlurAction()
                );
            }
        },

        onCalendarOpen: () => {
            if (triggerClickOnFocus) {
                return createPostActionWithType(ACTION_TYPE.CLICK, xPath, xPath, conversationUuid);
            }
            return;
        },

        onClickOutside: () => sendBlurAction(),

        locale: locale || getLanguage()
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DatePickerWidget);
