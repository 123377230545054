import { IMirageInputNode } from '@stepstone/ats-scrapers-core/types';
import React, { createRef, useCallback, useState } from 'react';
import { FormControl } from 'react-bootstrap';

import { debouncedAction } from '../../../../../common/actions/form';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import GenericInput from '../GenericInput';
import { useRefreshId } from '../hooks';
import { form } from '../../../../../common/reducers';
import { connectInput } from '../utils';

export type TextareaInputProps = {
    fieldValue: IMirageInputNode['value'];
    postAction: (e: React.ChangeEvent<HTMLInputElement>) => void;
    postActionWithType: (value: string, type: string) => void;
    refreshId: ReturnType<typeof form.getRefreshId>;
    isEnabledBrowserValidation: boolean;
} & IMirageInputNode;

const TextareaInput = ({
    customProps,
    dataRole,
    help,
    id,
    isDisabled,
    isRequired,
    label,
    postAction,
    postActionWithType,
    postponeGetStructureTime,
    refreshId,
    status,
    type,
    tooltipMessage,
    fieldValue,
    maxLength,
    isEnabledBrowserValidation,
    ...controlProps
}: TextareaInputProps) => {
    const [valueLength, setValueLength] = useState(fieldValue ? fieldValue.length : 0);
    const onChange = useCallback(debouncedAction(postActionWithType), []);
    const ref = createRef<HTMLTextAreaElement>();

    useRefreshId(ref, fieldValue, refreshId);

    return (
        <GenericInput
            {...{ help, label, status, isRequired, isDisabled, id, tooltipMessage, isEnabledBrowserValidation }}
            ref={ref}
        >
            <FormControl
                {...controlProps}
                ref={ref}
                aria-describedby={`${id}_HelpText`}
                aria-invalid={!!status}
                required={isRequired}
                aria-required={isRequired}
                as={'textarea'}
                autoComplete="off"
                data-role={dataRole}
                type={type}
                maxLength={maxLength}
                onChange={event => {
                    onChange(event.target.value, ACTION_TYPE.TEXT_INPUT);
                    setValueLength(event.target.value.length);
                }}
                disabled={isDisabled}
            />
            {maxLength ? <Counter valueLength={valueLength} maxLength={maxLength} /> : undefined}
        </GenericInput>
    );
};

type CounterProps = {
    maxLength: number;
    valueLength: number;
};

const Counter = ({ valueLength, maxLength }: CounterProps) => (
    <div className="form-text text-muted text-right">
        {valueLength} / {maxLength}
    </div>
);

TextareaInput.displayName = 'TextareaInput';

export default connectInput<TextareaInputProps>(form.getTextFieldData, TextareaInput);
