import React, { FC } from 'react';

import CustomPage from '../ui/CustomPage';
import { THANK_YOU_PAGE_MESSAGE, THANK_YOU_PAGE_TITLE } from '../../constants/stringKeys';
import TranslatedText from '../ui/TranslatedText';

const ThankYouPage: FC = () => (
    <CustomPage
        icon="check"
        iconColor="#66bd00"
        message={<TranslatedText textKey={THANK_YOU_PAGE_MESSAGE} />}
        title={<TranslatedText textKey={THANK_YOU_PAGE_TITLE} />}
    />
);

export default ThankYouPage;
