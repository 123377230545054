import prettyBytes from 'pretty-bytes';

import { MAX_FILE_SIZE } from './constants/session';
import { FILE_TOO_LARGE_MESSAGE } from './constants/stringKeys';
import { form, language } from './reducers';

/**
 * Checks whether max file size was exceeded
 * @param fileSizeLimit expressed in bytes
 */
export const isFileTooLarge = (file: File, fileSizeLimit = MAX_FILE_SIZE): boolean => file.size > fileSizeLimit;

export const formatFileTooLargeMessage = (state: any, fileSizeLimit: number): string =>
    language.getTranslatedTemplateText(state, FILE_TOO_LARGE_MESSAGE, {
        fileSize: prettyBytes(fileSizeLimit)
    });

export const getCustomValidation = (
    state: any,
    elementId: string,
    fileSizeLimit = MAX_FILE_SIZE
): string | undefined =>
    form.hasFileUploadValidation(state, elementId) ? formatFileTooLargeMessage(state, fileSizeLimit) : undefined;

export const isEmptyValidation = (str: string | undefined): boolean => !str || !str.trim();
