import React from 'react';
import { connect } from 'react-redux';
import { TranslationKeys } from '../../constants/stringKeys';

import { language } from '../../reducers';
import type { RootState } from '../../../app/store';

const TranslatedText: React.FC<{ text?: string }> = ({ text }) =>
    text ? <span className="translate">{text}</span> : null;

TranslatedText.displayName = 'TranslatedText';

type OwnProps = { textKey: TranslationKeys };

const mapStateToProps = (state: RootState, { textKey }: OwnProps) => ({
    text: language.getTranslatedText(state, textKey)
});

export default connect(mapStateToProps)(TranslatedText);
