import { from, of } from 'rxjs';
import {
    delay,
    distinctUntilKeyChanged,
    filter,
    map,
    scan,
    skipUntil,
    switchMap,
    take,
    takeUntil,
    withLatestFrom
} from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {
    formStructurePollingDisabled,
    formStructurePollingEnabled,
    formStructurePollingStart,
    formStructurePollingStop
} from './action';
import { initFormCompleted } from '../init/action';
import { form, session, gui } from '../../../common/reducers';
import { job, statistics } from '../../reducers';
import { sendLog } from '../../../common/state/log/action';
import { redirectTo } from '../../../common/state/redirectTo/action';
import { STATUS_PARAM_NAME, ERROR } from '../../constants/applicationStatus';
import { createGetCurrentStructureAction } from '../../../common/actions/form';
import { LOG_TYPE, REDIRECT_REASON_KEY, REDIRECT_REASONS } from '../../../common/constants/log';
import { INITIALIZE_INTERVAL_TIME } from '../../../common/constants/session';
import { setUrlParam } from '../../../utils';
import {
    FETCH_FORM_SUCCESS_ACTION,
    INITIALIZATION_STATE_INITIALIZED,
    GET_STRUCTURE_FAILURE_ACTION,
    GET_STRUCTURE_SUCCESS_ACTION
} from '../../../common/constants/form';

export const formRenderedEpic = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_FORM_SUCCESS_ACTION),
        filter(action => action.response && action.response.status === INITIALIZATION_STATE_INITIALIZED),
        withLatestFrom(state$),
        map(([, state]) =>
            sendLog({
                actionType: LOG_TYPE.FORM_RENDERED,
                ...job.getJobLoggingContext(state),
                formRenderingTime: Date.now() - statistics.getSessionStarted(state)
            })
        )
    );

export const formErrorEpic = (action$, state$) =>
    action$.pipe(
        skipUntil(action$.pipe(ofType(initFormCompleted))),
        withLatestFrom(state$),
        filter(([, state]) => {
            const { isValid } = form.getFormStatuses(state);
            return !isValid;
        }),
        switchMap(([, state]) => {
            const originalUrl = session.getEffectiveEndpointUrl(state);
            const postApplicationUrl = session.getPostApplicationUrl(state);
            const causedBy = form.getRedirectReason(state);
            const actions = [];

            if (postApplicationUrl || originalUrl) {
                const url = postApplicationUrl
                    ? setUrlParam(postApplicationUrl, STATUS_PARAM_NAME, ERROR)
                    : originalUrl;

                actions.push(
                    sendLog({
                        actionType: LOG_TYPE.FRONTEND_ATS_REDIRECT,
                        [REDIRECT_REASON_KEY]: causedBy || REDIRECT_REASONS.INTERMEDIATE_STAGE_ERROR,
                        ...job.getJobLoggingContext(state),
                        redirectTo: url
                    }),
                    redirectTo(url)
                );
            }

            return from(actions);
        }),
        takeUntil(
            action$.pipe(
                withLatestFrom(state$),
                filter(([, state]) => {
                    const { isSuccessfullyApplied, isSessionExpired } = form.getFormStatuses(state);
                    return isSuccessfullyApplied || isSessionExpired;
                })
            )
        ),
        // Ensure we log and redirect only once (two actions)
        take(2)
    );

export const formStructurePollingControllerEpic = (action$, state$) =>
    action$.pipe(
        ofType(formStructurePollingStart, formStructurePollingStop),
        // For each start action we increase steering value, or decrease for stop respectively
        map(({ type }) => (type === `${formStructurePollingStart}` ? 1 : -1)),
        // Emit current accumulation of steering value whenever the source emits a value
        scan((acc, curr) => Math.max(acc + curr, 0), 0),
        // Map steering value to executive actions
        map(controlValue => (controlValue > 0 ? formStructurePollingEnabled() : formStructurePollingDisabled())),
        // Emit only when action type changes from polling enabled to disabled and vice versa
        distinctUntilKeyChanged('type'),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            // Emit controlling stop when alert appear, user have to dismiss it first to continue
            if (gui.isAlertAvailable(state)) {
                return of(formStructurePollingDisabled());
            }
            // Emit controlling start and first dispatch get structure action that will begin interval
            if (action.type === `${formStructurePollingEnabled}`) {
                return of(action, createGetCurrentStructureAction(session.getConversationUuid(state)));
            }
            // Emit controlling stop
            return of(action);
        })
    );

export const formStructurePollingEpic = (action$, state$) =>
    action$.pipe(
        ofType(formStructurePollingEnabled),
        // Start polling form structure after receiving control action
        switchMap(() =>
            action$.pipe(
                // React only on get structure actions
                ofType(GET_STRUCTURE_SUCCESS_ACTION, GET_STRUCTURE_FAILURE_ACTION),
                withLatestFrom(state$),
                switchMap(([, state]) => {
                    // Emit controlling stop when alert appear during polling, user have to dismiss it first to continue
                    if (gui.isAlertAvailable(state)) {
                        return of(formStructurePollingDisabled());
                    }
                    // Dispatch get structure action that will continue polling
                    return of(createGetCurrentStructureAction(session.getConversationUuid(state))).pipe(
                        delay(INITIALIZE_INTERVAL_TIME)
                    );
                }),
                // Placement of takeUntil() inside our switchMap() is important because we want to cancel only get structure action,
                // not stop the Epic from listening for any future actions
                takeUntil(action$.pipe(ofType(formStructurePollingDisabled)))
            )
        )
    );
