import { from, of } from 'rxjs';
import { delay, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { INITIALIZE_INTERVAL_TIME } from '../../../common/constants/session';
import {
    FETCH_FORM_FAILURE_ACTION,
    FETCH_FORM_SUCCESS_ACTION,
    INITIALIZATION_STATE_ERROR,
    INITIALIZATION_STATE_INITIALIZED,
    INITIALIZATION_STATE_JOB_EXPIRED
} from '../../../common/constants/form';
import { form, session } from '../../../common/reducers';
import { job } from '../../reducers';
import { fetchInitialData } from '../../../common/actions/form';
import { initForm, initFormCompleted, initFormError, initFormRetry, initFormTimeout } from './action';
import { ERROR, JOB_EXPIRED, STATUS_PARAM_NAME } from '../../constants/applicationStatus';
import { setUrlParam } from '../../../utils';
import { sendLog } from '../../../common/state/log/action';
import { LOG_TYPE, REDIRECT_REASON_KEY, REDIRECT_REASONS } from '../../../common/constants/log';
import { invalidateSessionAction } from '../../../common/actions/form';
import { redirectTo } from '../../../common/state/redirectTo/action';

export const initEpic = (action$, state$) =>
    action$.pipe(
        ofType(initForm),
        withLatestFrom(state$),
        map(([, state]) => fetchInitialData(session.getConversationUuid(state))),
        // ensure we don't start session init process when user already successfully applied
        takeUntil(
            action$.pipe(
                withLatestFrom(state$),
                filter(([, state]) => {
                    const { isSuccessfullyApplied } = form.getFormStatuses(state);
                    return isSuccessfullyApplied;
                })
            )
        )
    );

export const initTimeoutEpic = (action$, state$) =>
    action$.pipe(
        ofType(initForm),
        withLatestFrom(state$),
        // pass only first init message to start timeout timer
        take(1),
        switchMap(([, state]) =>
            // start with timeout action
            of(initFormTimeout(REDIRECT_REASONS.INIT_TIMEOUT)).pipe(
                // and wait to dispatch it
                delay(session.getInitTimeout(state)),
                // stop emitting if form was successfully initialized or max number of retries was reached
                takeUntil(action$.pipe(ofType(initFormCompleted, initFormError)))
            )
        ),
        // ensure we don't start timeout for init process when user already successfully applied
        takeUntil(
            action$.pipe(
                withLatestFrom(state$),
                filter(([, state]) => {
                    const { isSuccessfullyApplied } = form.getFormStatuses(state);
                    return isSuccessfullyApplied;
                })
            )
        )
    );

export const initRetryEpic = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_FORM_SUCCESS_ACTION, FETCH_FORM_FAILURE_ACTION),
        withLatestFrom(state$),
        // emit new actions base on latest initialization state
        switchMap(([, state]) => {
            const { initializationStatus, initRetriesLeft, isFetching, isSessionExpired, isValid } =
                form.getFormStatuses(state);

            if (!isFetching && isValid) {
                return of(initFormCompleted());
            }

            if (isSessionExpired) {
                return of(initFormError(REDIRECT_REASONS.SESSION_EXPIRED));
            }

            if (!isValid) {
                // early exits when invalid state occur
                if (initializationStatus === INITIALIZATION_STATE_JOB_EXPIRED) {
                    return of(initFormError(REDIRECT_REASONS.JOB_EXPIRED));
                }

                const causedBy = form.getRedirectReason(state) || REDIRECT_REASONS.INITIALIZATION_ERROR;

                if (
                    initializationStatus === INITIALIZATION_STATE_ERROR ||
                    initializationStatus === INITIALIZATION_STATE_INITIALIZED
                ) {
                    return of(initFormError(causedBy));
                }

                // if retry is possible
                return initRetriesLeft > 0
                    ? // emit two new actions:
                      // retry action (which controls number of retries left)
                      // and new init form action (which will trigger actual fetch) with specified delay
                      of(initFormRetry(), initForm()).pipe(delay(INITIALIZE_INTERVAL_TIME))
                    : // max number of operation retries have been exceeded
                      of(initFormError(causedBy));
            }

            // while we are is some valid state we will continue init process until explicit error occur or timeout
            return of(initForm()).pipe(delay(INITIALIZE_INTERVAL_TIME));
        }),
        // stop emitting if form was successfully initialized, error or timeout occurred
        takeUntil(action$.pipe(ofType(initFormCompleted, initFormError, initFormTimeout)))
    );

export const initErrorEpic = (action$, state$) =>
    action$.pipe(
        ofType(initFormError, initFormTimeout),
        withLatestFrom(state$),
        switchMap(([{ payload: causedBy }, state]) => {
            const originalUrl = session.getEffectiveEndpointUrl(state);
            const postApplicationUrl = session.getPostApplicationUrl(state);

            const actions = [invalidateSessionAction(causedBy)];

            if (postApplicationUrl || originalUrl) {
                const url = postApplicationUrl
                    ? setUrlParam(
                          postApplicationUrl,
                          STATUS_PARAM_NAME,
                          causedBy === REDIRECT_REASONS.JOB_EXPIRED ? JOB_EXPIRED : ERROR
                      )
                    : originalUrl;

                actions.push(
                    sendLog({
                        actionType: LOG_TYPE.FRONTEND_ATS_REDIRECT,
                        [REDIRECT_REASON_KEY]: causedBy,
                        ...job.getJobLoggingContext(state),
                        redirectTo: url
                    }),
                    redirectTo(url)
                );
            }
            // emit new actions:
            return from(actions);
        })
    );
