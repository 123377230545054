import { combineReducers } from 'redux';
import sessionReducer from '../../common/reducers/session';
import jobReducer, * as jobSelectors from './job';
import formReducer from './form';
import guiReducer from '../../common/reducers/gui';
import languageReducer from '../../common/reducers/language';
import * as constants from '../../common/constants';
import statisticsReducer, * as statisticsSelectors from './statistics';
import { prepareSelectors } from '../../common/reducers';

export default combineReducers({
    [constants.FORM_KEY]: formReducer,
    [constants.GUI_KEY]: guiReducer,
    [constants.JOB_KEY]: jobReducer,
    [constants.SESSION_KEY]: sessionReducer,
    [constants.LANGUAGE_KEY]: languageReducer,
    [constants.STATISTICS_KEY]: statisticsReducer
});

export const job = prepareSelectors(constants.JOB_KEY, jobSelectors);

export const statistics = prepareSelectors(constants.STATISTICS_KEY, statisticsSelectors);
