import React, { Component, MouseEvent } from 'react';
import classNames from 'classnames';

import TextElement from './TextElement/TextElement';
import TranslatedText from '../../../common/components/ui/TranslatedText';
import { COLLAPSE_BTN_HIDE_TEXT, COLLAPSE_BTN_SHOW_TEXT } from '../../../common/constants/stringKeys';

type TFormTextElementProps = {
    id: string;
};

type TFormTextElementState = {
    textElemHeight: number;
    isCollapsed: boolean;
};

type TTextElement = HTMLHeadingElement | HTMLSpanElement | HTMLDivElement | HTMLParagraphElement;

class FormTextElement extends Component<TFormTextElementProps, TFormTextElementState> {
    readonly state: TFormTextElementState = { textElemHeight: 0, isCollapsed: true };
    private readonly textElem = React.createRef<TTextElement>();

    static measureElemHeight = (textElem: TTextElement): number => {
        return (textElem && textElem.offsetHeight) || 0;
    };

    componentDidMount() {
        if (this.textElem?.current) {
            this.setState({ textElemHeight: FormTextElement.measureElemHeight(this.textElem.current) });
        }
    }

    componentDidUpdate() {
        if (this.textElem?.current) {
            const tmpHeight = FormTextElement.measureElemHeight(this.textElem.current);
            if (this.state.textElemHeight !== tmpHeight) {
                this.setState({ textElemHeight: tmpHeight });
            }
        }
    }

    changeCollapseState = (e: MouseEvent<HTMLButtonElement>) => {
        if (e.cancelable) {
            e.preventDefault();
        }
        this.setState(({ isCollapsed }) => ({ isCollapsed: !isCollapsed }));
    };

    isThereRoomForElem = (): boolean => this.state.textElemHeight < 300;

    renderToggle = () => (
        <div className="logTextCollapseToggle">
            <button className="btn btn-primary btn-sm" onClick={this.changeCollapseState}>
                <TranslatedText textKey={this.state.isCollapsed ? COLLAPSE_BTN_SHOW_TEXT : COLLAPSE_BTN_HIDE_TEXT} />
            </button>
        </div>
    );

    render() {
        const { id } = this.props;
        const isLongElem = !this.isThereRoomForElem();

        return (
            <div className="row">
                <div className={classNames('col-xs-12', 'input-row')}>
                    <div className={classNames({ 'longTextElem expand': isLongElem && this.state.isCollapsed })}>
                        <TextElement ref={this.textElem} id={id} />
                    </div>
                    {isLongElem && this.renderToggle()}
                </div>
            </div>
        );
    }
}

export default FormTextElement;
