import { createAction } from '@reduxjs/toolkit';

import { actionType } from './actionType';

export const initForm = createAction(actionType.INIT_FORM);

export const initFormCompleted = createAction(actionType.INIT_FORM_COMPLETED);

export const initFormError = createAction(actionType.INIT_FORM_ERROR, error => ({
    error: true,
    payload: error
}));

export const initFormRetry = createAction(actionType.INIT_FORM_RETRY);

export const initFormTimeout = createAction(actionType.INIT_FORM_TIMEOUT, error => ({
    error: true,
    payload: error
}));
