import {
    FORM_SUBMIT_SUCCESS_ACTION,
    POST_ACTION_SUCCESS_ACTION,
    POST_UPLOAD_SUCCESS_ACTION,
    GET_STRUCTURE_SUCCESS_ACTION
} from '../../common/constants/form';
import { createAlert } from '../../common/actions/gui';

export const showAlertIfRequiredMiddleware = store => next => action => {
    if (
        [
            FORM_SUBMIT_SUCCESS_ACTION,
            POST_ACTION_SUCCESS_ACTION,
            POST_UPLOAD_SUCCESS_ACTION,
            GET_STRUCTURE_SUCCESS_ACTION
        ].includes(action.type) &&
        action.response &&
        action.response.alert
    ) {
        store.dispatch(createAlert({ message: action.response.alert }));
    }

    next(action);
};
