import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormLabel, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormGroup from '../FormGroup';
import { form, session } from '../../../../common/reducers';
import { clickAndWaitForElement } from '../../../../common/actions/form';
import RenderTextArray from '../../../../common/components/ui/RenderTextArray';
import TranslatedText from '../../../../common/components/ui/TranslatedText';
import HelpText from '../fields/HelpText';
import LoadingOverlay from '../../../../common/components/ui/LoadingOverlay';
import {
    CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE,
    CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_VISIBLE
} from '../../../../common/constants/form';
import { ConditionalWrap } from '../fields/ConditionalWrap';

export const ClickAndWaitForElementWidget = ({
    customLabel,
    help,
    status,
    label,
    addOrRemoveElement,
    buttonTxt,
    isRequired,
    isDisabled
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const clickHandler = () => {
        setIsLoading(true);
        addOrRemoveElement()
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    };

    return (
        <div className="row mt-2 mb-2">
            <div className="col-xs-12 input-row">
                {label && (
                    <FormGroup className={classNames('d-flex, w-100', { required: isRequired })}>
                        <FormLabel className="form-label">
                            <RenderTextArray text={label} />
                        </FormLabel>
                    </FormGroup>
                )}
                {isLoading && <LoadingOverlay />}
                <Button
                    className="button-row"
                    variant="primary"
                    key="button"
                    type="button"
                    onClick={clickHandler}
                    disabled={isLoading || isDisabled}
                >
                    {isLoading && <FontAwesomeIcon icon="spinner" spin={true} />}
                    {!!customLabel ? (
                        <ConditionalWrap
                            condition={!Array.isArray(customLabel)}
                            wrap={children => <span>{children}</span>}
                        >
                            <RenderTextArray text={customLabel} />
                        </ConditionalWrap>
                    ) : (
                        <TranslatedText textKey={buttonTxt} />
                    )}
                </Button>
                {help && (
                    <div className="w-100">
                        <HelpText help={help} status={status} />
                    </div>
                )}
            </div>
        </div>
    );
};

ClickAndWaitForElementWidget.displayName = 'ClickAndWaitForElementWidget';

const additionalFields = ['selectorToAddElement', 'selectorToRemoveElement', 'customLabel'];

const mapStateToProps = (state, { id }) => ({
    ...form.getCommonFieldDataWithAdditionalFields(state, id, ...additionalFields),
    conversationUuid: session.getConversationUuid(state)
});

const mapDispatchToProps = {
    addOrRemoveElement: clickAndWaitForElement
};

const mergeProps = (
    { conversationUuid, xPath, type, selectorToAddElement, selectorToRemoveElement, ...stateProps },
    { addOrRemoveElement }
) => ({
    ...stateProps,
    addOrRemoveElement: () => {
        return selectorToAddElement
            ? addOrRemoveElement(
                  CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_VISIBLE,
                  selectorToAddElement,
                  xPath,
                  conversationUuid
              )
            : addOrRemoveElement(
                  CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE,
                  selectorToRemoveElement,
                  xPath,
                  conversationUuid
              );
    }
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickAndWaitForElementWidget);
