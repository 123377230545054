import { REFRESH_SESSION_SUCCESS_ACTION } from '../../common/constants/session';
import { createGetCurrentStructureAction } from '../../common/actions/form';

export const refreshOnScraperChangeMiddleware = store => next => action => {
    if (
        process.env.REACT_APP_CONFIG_MODE_ENABLED &&
        action.type === REFRESH_SESSION_SUCCESS_ACTION &&
        action.response.scraperUpdated
    ) {
        store.dispatch(createGetCurrentStructureAction((action.response || {}).conversationUuid));
    }
    next(action);
};
