import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IMirageInputNode, IMiragePreClickedUploadWidget } from '@stepstone/ats-scrapers-core/types';
import { form, session } from '../../../../../common/reducers';
import { createPreClickUploadWithValidationCheck, fileUploadErrorHandler } from '../../../../../common/actions/form';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import TranslatedText from '../../../../../common/components/ui/TranslatedText';
import HelpText, { STATUS } from '../../fields/HelpText';
import { BROWSE_TEXT, UPLOADING_LABEL_TEXT } from '../../../../../common/constants/stringKeys';
import { getCustomValidation, isEmptyValidation } from '../../../../../common/fileUploadUtils';
import { PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK } from '../../../../../common/constants/form';
import type { RootState } from '../../../../../app/store';

type OwnProps = { id: string };

type Props = Pick<IMirageInputNode, 'help' | 'status'> & {
    customLabel: string;
    ifLoading: boolean;
    accept: string;
    customValidation: ReturnType<typeof getCustomValidation>;
    uploadFile: (e: React.ChangeEvent<HTMLInputElement>) => ReturnType<typeof createPreClickUploadWithValidationCheck>;
} & OwnProps;

type StateProps = Pick<IMiragePreClickedUploadWidget, 'buttonSelector' | 'uploadType'> & {
    conversationUuid: string;
    xPath: string;
} & Props;

type DispatchProps = {
    uploadFile: typeof createPreClickUploadWithValidationCheck;
};

const PreClickedUploadWithValidationCheckWidget: FC<Props> = ({
    customLabel,
    help,
    status,
    uploadFile,
    ifLoading,
    accept,
    customValidation,
    id
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = React.createRef<HTMLInputElement>();

    const addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(ifLoading);
        // @ts-expect-error Problem is related to thunk action which here is already unpacked
        uploadFile(event).finally(() => setIsLoading(false));
    };

    const handleClickButton = () => {
        const currentInput = inputRef.current;
        if (currentInput) {
            currentInput.value = '';
            currentInput.click();
        }
    };

    return (
        <div className="row mt-2 mb-2">
            <div className="col-xs-12 input-row">
                <input ref={inputRef} type="file" onChange={addFile} style={{ display: 'none' }} accept={accept} />
                <Button className="button-row" variant="primary" key="button" type="button" onClick={handleClickButton}>
                    {isLoading && <FontAwesomeIcon icon="spinner" spin />}
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        <TranslatedText textKey={isLoading ? UPLOADING_LABEL_TEXT : BROWSE_TEXT} />
                    )}
                </Button>
                {(help || !isEmptyValidation(customValidation)) && (
                    <div className="w-100">
                        <HelpText help={help} status={status} id={`${id}_HelpText`} />
                        <HelpText help={customValidation} status={STATUS.ERROR} id={`${id}_CustomValidation`} />
                    </div>
                )}
            </div>
        </div>
    );
};

PreClickedUploadWithValidationCheckWidget.displayName = 'PreClickedUploadWithValidationCheckWidget';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getFileFieldData(state, id),
    conversationUuid: session.getConversationUuid(state),
    customValidation: getCustomValidation(state, id),
    id
});

const mapDispatchToProps = {
    uploadFile: createPreClickUploadWithValidationCheck
};

const mergeProps = (
    { conversationUuid, xPath, buttonSelector, id, ...stateProps }: StateProps,
    { uploadFile }: DispatchProps
): Props => ({
    ...stateProps,
    id,
    uploadFile: e =>
        uploadFile(
            e.target.files,
            PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK,
            buttonSelector,
            xPath,
            conversationUuid,
            id
            // @ts-expect-error Problem is related to thunk action which here is already unpacked
        ).catch(fileUploadErrorHandler)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PreClickedUploadWithValidationCheckWidget);
