import React, { FC } from 'react';

import CustomPage from '../ui/CustomPage';
import { EXPIRE_PAGE_MESSAGE, EXPIRE_PAGE_TITLE } from '../../constants/stringKeys';
import TranslatedText from '../ui/TranslatedText';

const SessionExpired: FC = () => (
    <CustomPage
        icon={['far', 'calendar-times']}
        message={<TranslatedText textKey={EXPIRE_PAGE_MESSAGE} />}
        title={<TranslatedText textKey={EXPIRE_PAGE_TITLE} />}
    />
);

export default SessionExpired;
