import { ElementTag, PreFillType, WidgetType } from '@stepstone/ats-scrapers-core/types';
import React, { FC } from 'react';

import FieldElement from './FieldElement';
import { ConditionalWrap } from './fields/ConditionalWrap';
import FormTextElement from './FormTextElement';
import LabelElement from './labelElement/LabelElement';
import MultiField from './multi/MultiField';
import WidgetElement from './WidgetElement';
import { BaseNode } from '../../reducers/form';

type Props = BaseNode & {
    preFillLink?: PreFillType;
    tagName?: string;
};

const Element: FC<Props> = ({ id, preFillLink, tagName, type, isVisible }) => {
    if (!isVisible) {
        return null;
    }
    if (preFillLink) {
        return null;
    }
    if (!tagName) {
        return null;
    }
    switch (tagName.toUpperCase()) {
        case ElementTag.H1:
        case ElementTag.H2:
        case ElementTag.H3:
        case ElementTag.H4:
        case ElementTag.P:
        case ElementTag.SPAN:
        case ElementTag.DIV:
            return <FormTextElement id={id} />;
        case ElementTag.LABEL:
            return <LabelElement id={id} />;
        case ElementTag.WIDGET:
            return (
                <ConditionalWrap
                    condition={
                        type === WidgetType.ASYNC_SINGLE_FILE_UPLOAD_WITH_DELETE ||
                        type === WidgetType.ASYNC_SELECT_AUTOSUGGEST_WIDGET
                    }
                    wrap={children => (
                        <div className="row">
                            <div className="col-xs-12 input-row">{children}</div>
                        </div>
                    )}
                >
                    <WidgetElement id={id} type={type} />
                </ConditionalWrap>
            );
        // TODO to be moved under widget
        case ElementTag.MULTI_FIELD:
            return <MultiField id={id} />;
        default:
            return (
                <ConditionalWrap
                    condition={type !== 'submit' && type !== 'button'}
                    wrap={children => (
                        <div className="row">
                            <div className="col-xs-12 input-row">{children}</div>
                        </div>
                    )}
                >
                    <FieldElement id={id} type={type} />
                </ConditionalWrap>
            );
    }
};

Element.displayName = 'Element';

export default Element;
