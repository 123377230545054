import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './SkeletonLoader.module.scss';
import { LoaderType } from '../../constants/loaderType';

const bootstrapFormClassnames = 'col-xs-12 w-100 mx-auto';

const responsiveSpacing = 'mx-0 px-0 px-sm-1';

const range = (range: number) => Array.from(Array(range).keys());

export const SkeletonHeader: FC = () => (
    <Row className={bootstrapFormClassnames}>
        <Col md={2} xs={4} className="ms-n3">
            <div className={styles.rect} />
        </Col>
        <Col md={5} xs={8}>
            <div className={styles.text} />
            <div className={styles.textBold} />
            <div className={styles.text} />
        </Col>
    </Row>
);

const SkeletonFormHeader = (
    <>
        <Row className={classNames(bootstrapFormClassnames, responsiveSpacing, 'd-none d-sm-block')}>
            <Col xs={{ span: 4, offset: 4 }}>
                <div className={styles.rect100} />
            </Col>
        </Row>
        <Row className={classNames(bootstrapFormClassnames, responsiveSpacing)}>
            <Col xs={{ span: 6, offset: 3 }} className={'px-0 px-sm-1 mb-5 mb-sm-4'}>
                <div className={styles.textBold} />
            </Col>
        </Row>
        <Row className={classNames(bootstrapFormClassnames, responsiveSpacing)}>
            <Col xs={{ span: 12 }} className={'px-0 px-sm-1'}>
                <div className={styles.text} />
            </Col>
            <Col xs={{ span: 12 }} className={'px-0 px-sm-1 d-sm-none'}>
                <div className={styles.text} />
            </Col>
        </Row>
    </>
);

const SkeletonFormInputs = range(5).map((id: number) => (
    <Row key={id} className={classNames(bootstrapFormClassnames, responsiveSpacing)}>
        <Col xs={5} className={responsiveSpacing}>
            <div className={styles.text} />
        </Col>
        <Col xs={12} className={responsiveSpacing}>
            <div className={styles.skeletonInput} />
        </Col>
    </Row>
));

type SkeletonLoaderProps = {
    loaderType: LoaderType;
};

export const SkeletonForm: FC<SkeletonLoaderProps> = ({ loaderType }) => {
    return (
        <>
            {loaderType === LoaderType.LOADER_SUBMIT ? SkeletonFormHeader : SkeletonFormInputs}
            <Row className={classNames(bootstrapFormClassnames, responsiveSpacing)}>
                <Col xs={12} className={responsiveSpacing}>
                    <div className={styles.skeletonButton} />
                </Col>
            </Row>
        </>
    );
};
