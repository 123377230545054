import { FC } from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import type { RootState } from '../../../app/store';
import { createPostActionWithType } from '../../actions/form';
import { deleteAlertWithRefresh } from '../../actions/gui';
import { ALERT_CONFIRMATION, ALERT_DISMISS } from '../../constants/gui';
import { CANCEL, OK } from '../../constants/stringKeys';
import { gui, session } from '../../reducers';
import RenderTextArray from './RenderTextArray';
import TranslatedText from './TranslatedText';

interface IProps {
    handleClose?: () => void;
    handleConfirmation?: () => void;
    isAvailable: boolean;
    message?: string;
    title?: string;
}

const BUTTON_TEXT = {
    CANCEL: CANCEL,
    OK: OK
} as const;

const Alert: FC<IProps> = ({ handleClose, handleConfirmation, isAvailable, message, title }) =>
    isAvailable ? (
        <BootstrapModal animation={false} show={isAvailable} backdrop={true} onHide={handleClose} size="lg">
            <BootstrapModal.Header closeButton>
                {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                <RenderTextArray text={message} />
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
                <Button onClick={handleClose} variant="secondary">
                    {<TranslatedText textKey={BUTTON_TEXT.CANCEL} />}
                </Button>
                <Button onClick={handleConfirmation} variant="primary">
                    {<TranslatedText textKey={BUTTON_TEXT.OK} />}
                </Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    ) : null;

const defaultBtnXPath = 'btnXpath';

export default connect(
    (state: RootState) => ({
        isAvailable: gui.isAlertAvailable(state),
        ...(gui.getAlert(state) || {}),
        conversationUuid: session.getConversationUuid(state)
    }),
    { createPostActionWithType, deleteAlertWithRefresh },
    ({ conversationUuid, ...props }, { createPostActionWithType, deleteAlertWithRefresh }) => ({
        ...props,
        handleClose: () => {
            createPostActionWithType(ALERT_DISMISS, defaultBtnXPath, defaultBtnXPath, conversationUuid);
            deleteAlertWithRefresh();
        },
        handleConfirmation: () => {
            createPostActionWithType(ALERT_CONFIRMATION, defaultBtnXPath, defaultBtnXPath, conversationUuid);
            deleteAlertWithRefresh();
        }
    })
)(Alert);
