import React, { useState, useRef, useEffect, FC, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { FormLabel, FormSelect } from 'react-bootstrap';
import classNames from 'classnames';
import { IMirageHiddenSelectWidget, IMirageFormNodeOption } from '@stepstone/ats-scrapers-core/types';

import FormGroup from '../form/FormGroup';
import HelpText from '../form/fields/HelpText';
import { form, session } from '../../../common/reducers';
import RenderTextArray from '../../../common/components/ui/RenderTextArray';
import LoadingOverlay from '../../../common/components/ui/LoadingOverlay';
import { ACTION_TYPE } from '../../../common/constants/form';
import TooltipWidget from '../form/widgets/TooltipWidget';
import { createGetCurrentStructureAction, createPostActionWithType } from '../../../common/actions/form';
import { handlePostponedStructureCheck } from '../form/fields/handlePostponedStructureCheck';
import type { RootState } from '../../../app/store';

type ComponentProps = IMirageHiddenSelectWidget;

type OwnProps = { id: string };

type StateProps = {
    conversationUuid: string;
} & ComponentProps;

type DispatchProps = {
    postActionWithType: typeof createPostActionWithType;
    createGetCurrentStructureAction: typeof createGetCurrentStructureAction;
};

type Props = Omit<StateProps, 'preFillLink' | 'conversationUuid' | 'xPath' | 'value'> & {
    defaultValue: IMirageHiddenSelectWidget['value'];
    postActionWithType: (value: string) => unknown;
};

const renderOption = (option: IMirageFormNodeOption) => (
    <option value={option.value} key={option.value}>
        {option.text}
    </option>
);

const HiddenSelect: FC<Props> = ({
    placeholder,
    options,
    label,
    isRequired,
    status,
    help,
    dataRole,
    id,
    defaultValue,
    postponeGetStructureTime,
    postActionWithType,
    tooltipMessage
}) => {
    const inputRef = useRef<HTMLSelectElement>(null);
    const loadingTimer = useRef<number>();
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        postActionWithType(event.target.value);
        if (postponeGetStructureTime) {
            if (loadingTimer.current) {
                clearTimeout(loadingTimer.current);
            }
            setIsLoading(true);
            loadingTimer.current = window.setTimeout(() => setIsLoading(false), postponeGetStructureTime);
        }
    };

    useEffect(() => {
        const input = inputRef.current;
        if (input) {
            input.value = defaultValue;
        }
    }, [defaultValue]);

    useEffect(() => {
        return () => {
            if (loadingTimer.current) {
                clearTimeout(loadingTimer.current);
            }
        };
    }, []);

    return (
        <div className="row">
            <div className="col-xs-12 input-row">
                <FormGroup className={classNames({ required: isRequired, 'has-error': status === 'error' })}>
                    <FormLabel className="form-label">
                        <RenderTextArray text={label} />
                        <TooltipWidget tooltipMessage={tooltipMessage} />
                    </FormLabel>
                    {isLoading && <LoadingOverlay />}
                    <FormSelect
                        onChange={handleChange}
                        ref={inputRef}
                        data-role={dataRole}
                        id={id}
                        aria-describedby={id && `${id}_HelpText`}
                        aria-required={isRequired}
                        aria-invalid={status === 'error'}
                    >
                        {placeholder && (
                            <option selected={true} disabled>
                                {placeholder}
                            </option>
                        )}
                        {options && options.map(option => renderOption(option))}
                    </FormSelect>
                    <HelpText help={help} status={status} />
                </FormGroup>
            </div>
        </div>
    );
};

HiddenSelect.displayName = 'HiddenSelect';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getSelectFieldData<ComponentProps>(state, id),
    conversationUuid: session.getConversationUuid(state)
});

const mergeProps = (
    { conversationUuid, postponeGetStructureTime, xPath, value, preFillLink, ...props }: StateProps,
    { postActionWithType, createGetCurrentStructureAction }: DispatchProps
): Props => ({
    ...props,
    postponeGetStructureTime,
    defaultValue: value,
    postActionWithType: value => {
        handlePostponedStructureCheck(postponeGetStructureTime, createGetCurrentStructureAction, conversationUuid);
        postActionWithType(ACTION_TYPE.SELECT_HIDDEN_OPTION, value, xPath, conversationUuid);
    }
});

export default connect(
    mapStateToProps,
    {
        postActionWithType: createPostActionWithType,
        createGetCurrentStructureAction
    },
    mergeProps
)(HiddenSelect);
