import { sendLog } from './common/actions/log';
import { CONVERSATION_UUID_PARAM_NAME } from './common/constants';
import { LOG_TYPE } from './common/constants/log';

type FileContents = {
    name: string;
    /** base 64 encoded */
    value: string;
};

export const getBase64 = (file: File): Promise<FileContents> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            resolve({
                name: file.name,
                value:
                    typeof this.result === 'string' && this.result !== 'data:'
                        ? this.result.substring(this.result.indexOf(',') + 1)
                        : ''
            });
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });

export const getUrlParam = <T extends string>(parameter: string): T | null => {
    let url = null;

    try {
        url = new URL(window.location.href);
    } catch (e) {
        return null;
    }

    if (!url.searchParams.has(parameter)) {
        return null;
    }

    return url.searchParams.get(parameter) as T;
};

export const setUrlParam = (url: string | URL, param: string, val: string): URL => {
    const ret = new URL(url);
    ret.searchParams.set(param, val);
    return ret;
};

export const isValidUrl = (url: string): boolean => {
    try {
        new URL(url);
        return true;
    } catch (error) {
        const conversationId = getUrlParam(CONVERSATION_UUID_PARAM_NAME);
        if (conversationId) {
            sendLog(conversationId, LOG_TYPE.POST_APPLICATION_URL_PARSE_ERROR, { url });
        }
        return false;
    }
};

export type ValueOf<T> = T[keyof T];
