import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUserTimes,
    faExclamationCircle,
    faExclamationTriangle,
    faCheckCircle,
    faInfoCircle,
    faQuestionCircle,
    faCheck,
    faSpinner,
    faExternalLinkAlt,
    faTimes,
    faArrowRight,
    faArrowLeft,
    faTrashAlt,
    faAngleUp,
    faAngleRight,
    faAngleDown,
    faAngleLeft,
    faEdit,
    faCaretUp,
    faCaretDown,
    faRedo
} from '@fortawesome/free-solid-svg-icons';
import {
    faCalendarTimes,
    faFileExcel,
    faSquare,
    faMinusSquare,
    faCheckSquare
} from '@fortawesome/free-regular-svg-icons';

export function registerFaIcons() {
    library.add(
        faUserTimes,
        faExclamationCircle,
        faExclamationTriangle,
        faCheckCircle,
        faInfoCircle,
        faQuestionCircle,
        faCheck,
        faSpinner,
        faExternalLinkAlt,
        faTimes,
        faArrowRight,
        faArrowLeft,
        faTrashAlt,
        faAngleUp,
        faAngleRight,
        faAngleDown,
        faAngleLeft,
        faEdit,
        faCaretDown,
        faCaretUp,
        faRedo,

        faCalendarTimes,
        faFileExcel,
        faSquare,
        faMinusSquare,
        faCheckSquare
    );
}
