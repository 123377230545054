import { ThunkDispatch } from 'redux-thunk';

import { Action } from '../action';
import { CREATE_ALERT_ACTION, DELETE_ALERT_ACTION } from '../constants/gui';
import { postponeStructureRefresh } from './form';

export type AlertContent = { message: string; title: string };

type CreateAlertAction = Action<typeof CREATE_ALERT_ACTION, AlertContent>;

export const createAlert = ({ message, title = '' }: { message: string; title?: string }): CreateAlertAction => ({
    type: CREATE_ALERT_ACTION,
    message,
    title
});

type DeleteAlertAction = Action<typeof DELETE_ALERT_ACTION>;

export const deleteAlert = (): DeleteAlertAction => ({ type: DELETE_ALERT_ACTION });

export const deleteAlertWithRefresh =
    (timeout = 1000) =>
    (dispatch: ThunkDispatch<any, void, any>): void => {
        dispatch(deleteAlert());
        dispatch(postponeStructureRefresh(timeout));
    };

export type GUIActions = CreateAlertAction | DeleteAlertAction;
