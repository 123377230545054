import { IMirageAnchor, IMirageClickableAnchor } from '@stepstone/ats-scrapers-core/types';
import { FC, MouseEvent, ReactNode } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '../../../app/store';
import { sendAnchorClickEvent } from '../../../common/actions/form';
import { ACTION_TYPE } from '../../../common/constants/form';
import { session } from '../../reducers';

type OwnProps = {
    actionType?: typeof ACTION_TYPE.CLICK | typeof ACTION_TYPE.ANCHOR_CLICK;
    children: ReactNode;
    style?: Record<string, unknown>;
    xPath?: IMirageClickableAnchor['xPath'];
} & Omit<IMirageAnchor, 'text' | 'type'>;

type StateProps = {
    conversationUuid: string;
} & OwnProps;

type DispatchProps = {
    sendAnchorClickEvent: typeof sendAnchorClickEvent;
};

export type Props = Omit<StateProps, 'conversationUuid'> & { handleClick: (event: MouseEvent) => void };

const Anchor: FC<Props> = ({ handleClick, href, stayOnPage, children }) => (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
        href={href}
        target={!stayOnPage ? '_blank' : undefined}
        rel={!stayOnPage ? 'noopener noreferrer' : undefined}
        onClick={handleClick}
    >
        {children}
    </a>
);

export default connect(
    (state: RootState, ownProps: OwnProps): StateProps => ({
        conversationUuid: session.getConversationUuid(state),
        ...ownProps
    }),
    { sendAnchorClickEvent },
    (
        { conversationUuid, xPath, stayOnPage, actionType = ACTION_TYPE.ANCHOR_CLICK, ...ownProps }: StateProps,
        { sendAnchorClickEvent }: DispatchProps
    ): Props => ({
        stayOnPage,
        ...ownProps,

        handleClick: (event: MouseEvent) => {
            if (!!stayOnPage) {
                if (event.cancelable) {
                    event.preventDefault();
                }
            }

            sendAnchorClickEvent(xPath || '//DUMMY_XPATH', conversationUuid, actionType);
        }
    })
)(Anchor);
