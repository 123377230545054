import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type CustomPageProps = {
    icon: IconProp;
    iconColor?: string;
    iconSpin?: boolean;
    message: React.ReactNode;
    title: React.ReactNode;
};

const CustomPage: React.FC<CustomPageProps> = ({ iconColor, icon, iconSpin = false, message, title }) => (
    <div
        className="application-confirmed"
        style={{
            height: '500px',
            marginTop: '70px',
            padding: '40px',
            textAlign: 'center'
        }}
    >
        <span style={iconColor ? { color: iconColor } : undefined}>
            <FontAwesomeIcon icon={icon} spin={iconSpin ? true : undefined} size="5x" fixedWidth />
        </span>
        <h1 className="polygot">{title}</h1>
        <p className="polygot">{message}</p>
    </div>
);

CustomPage.displayName = 'CustomPage';

export default CustomPage;
