import { ofType } from 'redux-observable';
import { from, interval } from 'rxjs';
import { filter, ignoreElements, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { FORM_SUBMIT_SUCCESS_ACTION, POST_ACTION_SUCCESS_ACTION } from '../../../common/constants/form';
import { LOG_TYPE, REDIRECT_REASON_KEY } from '../../../common/constants/log';
import { REFRESH_SESSION_SUCCESS_ACTION, REFRESH_SESSION_TIME } from '../../../common/constants/session';
import { form, session } from '../../../common/reducers';
import { sendLog } from '../../../common/state/log/action';
import { redirectTo } from '../../../common/state/redirectTo/action';
import { setUrlParam } from '../../../utils';
import { createSessionRefreshAction } from '../../actions/session';
import { ERROR, STATUS_PARAM_NAME } from '../../constants/applicationStatus';
import { job } from '../../reducers';
import { initFormCompleted } from '../init/action';
import { writeSessionState } from '../persistency';

export const sessionRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(initFormCompleted),
        withLatestFrom(state$),
        // pass only first init message to start interval timer
        take(1),
        switchMap(([, state]) =>
            // start refresh interval
            interval(REFRESH_SESSION_TIME).pipe(
                map(() => createSessionRefreshAction(session.getConversationUuid(state)))
            )
        ),
        // stop emitting refresh action when the state reaches `isSuccessfullyApplied`, `isSessionExpired` or invalid state
        takeUntil(
            action$.pipe(
                withLatestFrom(state$),
                filter(([, state]) => {
                    const { isValid, isSuccessfullyApplied, isSessionExpired } = form.getFormStatuses(state);
                    return !isValid || isSuccessfullyApplied || isSessionExpired;
                })
            )
        )
    );

export const sessionExpiredEpic = (action$, state$) =>
    action$.pipe(
        ofType(REFRESH_SESSION_SUCCESS_ACTION),
        withLatestFrom(state$),
        filter(([, state]) => {
            const { isSessionExpired } = form.getFormStatuses(state);
            return isSessionExpired;
        }),
        switchMap(([, state]) => {
            const originalUrl = session.getEffectiveEndpointUrl(state);
            const postApplicationUrl = session.getPostApplicationUrl(state);
            const causedBy = form.getRedirectReason(state);
            const actions = [];

            if (postApplicationUrl || originalUrl) {
                const url = postApplicationUrl
                    ? setUrlParam(postApplicationUrl, STATUS_PARAM_NAME, ERROR)
                    : originalUrl;

                actions.push(
                    sendLog({
                        actionType: LOG_TYPE.FRONTEND_ATS_REDIRECT,
                        [REDIRECT_REASON_KEY]: causedBy,
                        ...job.getJobLoggingContext(state),
                        redirectTo: url
                    }),
                    redirectTo(url)
                );
            }
            // emit new actions:
            return from(actions);
        }),
        takeUntil(
            action$.pipe(
                withLatestFrom(state$),
                filter(([, state]) => {
                    const { isValid, isSuccessfullyApplied } = form.getFormStatuses(state);
                    return !isValid || isSuccessfullyApplied;
                })
            )
        )
    );

export const sessionStatePersistencyEpic = (action$, state$) =>
    action$.pipe(
        ofType(FORM_SUBMIT_SUCCESS_ACTION, POST_ACTION_SUCCESS_ACTION),
        withLatestFrom(state$),
        filter(([, state]) => {
            const { isSuccessfullyApplied } = form.getFormStatuses(state);
            return isSuccessfullyApplied;
        }),
        tap(([action, state]) => {
            writeSessionState(session.getConversationUuid(state), {
                status: action.response.status
            });
        }),
        ignoreElements()
    );
