import React, { forwardRef, ReactNode, Ref, RefObject } from 'react';
import { IMirageInputNode } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import { FormLabel } from 'react-bootstrap';

import FormGroup from '../FormGroup';
import RenderTextArray from '../../../../common/components/ui/RenderTextArray';
import HelpText from './HelpText';
import TooltipWidget from '../widgets/TooltipWidget';
import { useBrowserValidation } from './hooks';

type GenericInputRef = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

type GenericInputProps = {
    isEnabledBrowserValidation: boolean;
    forwardedRef: Ref<GenericInputRef>;
    children?: ReactNode;
    isDisabled?: boolean;
} & Pick<IMirageInputNode, 'help' | 'label' | 'status' | 'isRequired' | 'tooltipMessage' | 'id'>;

const GenericInput = ({
    isEnabledBrowserValidation,
    forwardedRef,
    children: formControl,
    isDisabled,
    help,
    label,
    status,
    tooltipMessage,
    isRequired,
    id
}: GenericInputProps) => {
    //todo fix type for forwardedRef
    const browserMessage = useBrowserValidation(forwardedRef as RefObject<GenericInputRef>, isEnabledBrowserValidation);
    const helpMessage = help || browserMessage;
    const validationStatus = status === 'error' || browserMessage ? 'error' : status;

    return (
        <FormGroup
            className={classNames({
                required: isRequired,
                disabled: isDisabled,
                'has-error': validationStatus === 'error'
            })}
        >
            {label && label.length > 0 && (
                <FormLabel htmlFor={id}>
                    <RenderTextArray text={label} />
                    <TooltipWidget tooltipMessage={tooltipMessage} />
                </FormLabel>
            )}
            {formControl}
            {helpMessage && <HelpText help={helpMessage} status={validationStatus} id={`${id}_HelpText`} />}
        </FormGroup>
    );
};

const ForwardedGenericInput = forwardRef<
    GenericInputRef,
    Omit<GenericInputProps, 'forwardedRef'> & { ref: GenericInputProps['forwardedRef'] }
>((props, ref) => <GenericInput {...props} forwardedRef={ref} />);

ForwardedGenericInput.displayName = 'ForwardedGenericInput';

export default ForwardedGenericInput;
