import React, { createRef, useCallback, FC, ChangeEvent } from 'react';
import { FormControl } from 'react-bootstrap';
import { IMirageInputNode } from '@stepstone/ats-scrapers-core/types';

import GenericInput from '../GenericInput';
import { form } from '../../../../../common/reducers';
import { connectInput } from '../utils';
import { debouncedAction } from '../../../../../common/actions/form';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import { useRefreshId } from '../hooks';

export type TextInputProps = {
    postAction: (e: ChangeEvent<HTMLInputElement>) => void;
    postActionWithType: (value: string, actionType: keyof typeof ACTION_TYPE) => void;
    refreshId: ReturnType<typeof form.getRefreshId>;
    isEnabledBrowserValidation: boolean;
    fieldValue: IMirageInputNode['value'];
    onBlur: () => void;
    onFocus: () => void;
} & IMirageInputNode;

const TextInput: FC<TextInputProps> = ({
    customProps = {},
    dataRole,
    help,
    id,
    isDisabled,
    isRequired,
    label,
    postAction,
    postActionWithType,
    postponeGetStructureTime,
    refreshId,
    status,
    type,
    tooltipMessage,
    onBlur,
    onFocus,
    fieldValue,
    isEnabledBrowserValidation,
    ...controlProps
}) => {
    const onChange = useCallback(debouncedAction(postActionWithType), []);
    const ref = createRef<HTMLInputElement>();

    //todo useRefreshId should be moved to GenericInput
    useRefreshId(ref, fieldValue, refreshId);

    return (
        <GenericInput
            {...{ help, label, status, isRequired, isDisabled, id, tooltipMessage, isEnabledBrowserValidation }}
            ref={ref}
        >
            <FormControl
                {...controlProps}
                ref={ref}
                aria-describedby={`${id}_HelpText`}
                aria-invalid={!!status}
                aria-required={isRequired}
                required={isRequired}
                as={'input'}
                autoComplete="off"
                data-role={dataRole}
                type={type}
                onChange={event => onChange(event.target.value, ACTION_TYPE.TEXT_INPUT)}
                disabled={isDisabled}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </GenericInput>
    );
};

TextInput.displayName = 'TextInput';

export default connectInput<TextInputProps>(form.getTextFieldData, TextInput);
