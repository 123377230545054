import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMirageBaseWidget, IMirageFieldNode } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React from 'react';
import { Button, FormLabel } from 'react-bootstrap';
import { connect } from 'react-redux';

import type { RootState } from '../../../../../app/store';
import { createPostAction } from '../../../../../common/actions/form';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import { form, session } from '../../../../../common/reducers';
import FormGroup from '../../FormGroup';

type OwnProps = { id: string };

type ComponentProps = IMirageBaseWidget & {
    isRequired: boolean;
    name?: IconProp;
    label?: IMirageFieldNode['label'] | undefined;
    value: string;
};

type StateProps = {
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

type DispatchProps = {
    createPostAction: typeof createPostAction;
};

export type Props = Omit<StateProps, 'conversationUuid' | 'xPath'> & {
    postAction: (e: unknown) => void;
};

const FileRemoveWidget: React.FC<Props> = ({ label, value, postAction, isRequired, name = 'trash-alt' }) => (
    <div className="row">
        <div className="col-xs-12 input-row">
            <FormGroup className={classNames({ required: isRequired }, 'float-right')}>
                {label && (
                    <FormLabel className="form-label">
                        <RenderTextArray text={label} />
                    </FormLabel>
                )}
                <br />
                {value}{' '}
                <Button size="sm" onClick={postAction} value="remove">
                    <FontAwesomeIcon icon={name} />
                </Button>
            </FormGroup>
        </div>
    </div>
);

FileRemoveWidget.displayName = 'FileRemoveWidget';

export default connect(
    (state: RootState, { id }: OwnProps): StateProps => ({
        ...form.getElement(state, id),
        conversationUuid: session.getConversationUuid(state)
    }),
    { createPostAction },
    ({ conversationUuid, xPath, ...restProps }: StateProps, { createPostAction }: DispatchProps) => ({
        ...restProps,
        // @ts-expect-error would be great to remove event type from createPostAction...
        postAction: (e: React.MouseEvent<HTMLButtonElement>) => createPostAction(e, xPath, conversationUuid)
    })
    // @ts-expect-error 2345 concerning `mapDispatchToProps` overloads (ThunkAction)
)(FileRemoveWidget);
