import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayProps } from 'react-bootstrap/Overlay';

import { uniqueId } from '../../../../common/uniqueId';

type TooltipWidgetProps = {
    tooltipMessage: string | undefined;
} & Pick<OverlayProps, 'placement'>;

// https://github.com/FortAwesome/react-fontawesome/issues/199
const QuestionCircleIcon = forwardRef((props, ref) => (
    <FontAwesomeIcon forwardedRef={ref} className="tooltip-icon" icon="question-circle" role="tooltip" {...props} />
));

QuestionCircleIcon.displayName = 'QuestionCircleIcon';

const TooltipWidget: React.FC<TooltipWidgetProps> = ({ tooltipMessage, placement = 'top' }) => {
    if (tooltipMessage) {
        return (
            <OverlayTrigger
                placement={placement}
                overlay={
                    <Tooltip id={`tooltip-${placement}-${uniqueId()}`} className={'tooltip-content'}>
                        {tooltipMessage}
                    </Tooltip>
                }
            >
                <QuestionCircleIcon />
            </OverlayTrigger>
        );
    } else {
        return null;
    }
};

TooltipWidget.displayName = 'TooltipWidget';

export default TooltipWidget;
