import { IMirageAsyncSelectAutosuggestWidget, MIRAGE_NODE_REFERENCE } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import FormGroup from '../../FormGroup';
import {
    createGetCurrentStructureAction,
    createPostActionWithType,
    postponeStructureRefresh
} from '../../../../../common/actions/form';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import HelpText from '../../fields/HelpText';
import TooltipWidget from '../TooltipWidget';
import styles from './AsyncSelectAutosuggestWidgetStyles';
import {
    CancelationSignal,
    getUniqueOptions,
    LocatorsRef,
    SelectAction,
    SelectOptionItem,
    useCancelationSignal,
    useDefaultOptions,
    useLoading,
    useLocatorsRef,
    useQueuedAction,
    useValue
} from './AsyncSelectAutosuggestWidgetUtils';

export type OwnProps = { id: string };

export type ComponentProps = Omit<IMirageAsyncSelectAutosuggestWidget, typeof MIRAGE_NODE_REFERENCE>;

export type StateProps = {
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

export type DispatchProps = {
    createPostActionWithType: (
        ...args: Parameters<typeof createPostActionWithType>
    ) => ReturnType<ReturnType<typeof createPostActionWithType>>;
    createGetCurrentStructureAction: () => ReturnType<ReturnType<typeof createGetCurrentStructureAction>>;
    postponeStructureRefresh: typeof postponeStructureRefresh;
};

export type Props = Omit<StateProps, 'conversationUuid' | 'selectedItems' | 'items' | 'repeatInterval'> & {
    loadOptions: (
        locators: LocatorsRef,
        cancelationSignal: CancelationSignal['cancelationSignal'],
        inputValue?: string
    ) => Promise<Array<SelectOptionItem>>;
    onFocus: (
        locators: LocatorsRef,
        cancelationSignal: CancelationSignal['cancelationSignal']
    ) => Promise<Array<SelectOptionItem>>;
    onBlur: (locators: LocatorsRef) => Promise<any> | undefined;
    defaultValue: Array<SelectOptionItem>;
    scrollAndGetData: (
        locators: LocatorsRef,
        cancelationSignal: CancelationSignal['cancelationSignal']
    ) => Promise<Array<SelectOptionItem>>;
    selectOption: (
        values: OnChangeValue<SelectOptionItem, boolean>,
        action: SelectAction,
        locators: LocatorsRef
    ) => Promise<any> | undefined;
};

export const AsyncSelectAutosuggestWidgetView: React.FC<Props> = ({
    blurXPath,
    clickXPath,
    fieldXPath,
    scrollXPath,
    xPath,
    help,
    isRequired,
    label,
    status,
    noOptionsMessage,
    loadingMessage,
    dataRole,
    loadOptions,
    onFocus,
    onBlur,
    scrollAndGetData,
    selectOption,
    defaultValue,
    id,
    isMulti,
    isClearable,
    isSearchable,
    tooltipMessage,
    placeholder = '',
    cacheOptions = false,
    blurInputOnSelect = false
}) => {
    const onFocusOptions = useDefaultOptions(onFocus);
    const onMenuScrollToBottom = useDefaultOptions(scrollAndGetData);
    const { value, onChange } = useValue(defaultValue, selectOption);
    const locatorsRef = useLocatorsRef(xPath, blurXPath, clickXPath, scrollXPath, fieldXPath);
    const [isLoading, loadOnFocus] = useLoading(onFocusOptions.handleEvent);
    const actionQueue = useQueuedAction();
    const cancelationSignal = useCancelationSignal();

    return (
        <div className="row">
            <div className="col-xs-12 input-row">
                <FormGroup
                    className={classNames({ required: isRequired })}
                    data-role={dataRole}
                    id={id}
                    aria-required={isRequired}
                    aria-invalid={status === 'error'}
                    aria-describedby={id && `${id}_HelpText`}
                    aria-multiselectable={isMulti}
                >
                    {label && label.length > 0 && (
                        <FormLabel htmlFor={id}>
                            <RenderTextArray text={label} />
                            <TooltipWidget tooltipMessage={tooltipMessage} />
                        </FormLabel>
                    )}

                    <AsyncSelect
                        cacheOptions={cacheOptions}
                        loadOptions={(value?: string) =>
                            loadOptions(locatorsRef, cancelationSignal.cancelationSignal, value)
                        }
                        placeholder={placeholder}
                        defaultOptions={getUniqueOptions(onFocusOptions.options, onMenuScrollToBottom.options)}
                        isClearable={isClearable}
                        onChange={(value, action) => actionQueue.next(() => onChange(value, action, locatorsRef))}
                        value={value}
                        onFocus={() => {
                            actionQueue.next(() => loadOnFocus(locatorsRef, cancelationSignal.cancelationSignal));
                        }}
                        // @ts-expect-error This is used for styling see Styles.tsx file
                        status={status}
                        isSearchable={isSearchable}
                        onBlur={() => {
                            cancelationSignal.sendCancel();
                            if (!cacheOptions) {
                                onFocusOptions.clear();
                            }
                            actionQueue.next(() => onBlur(locatorsRef));
                        }}
                        onMenuScrollToBottom={() => {
                            onMenuScrollToBottom.handleEvent(locatorsRef, cancelationSignal.cancelationSignal);
                        }}
                        inputId={id}
                        isMulti={isMulti}
                        styles={styles}
                        isLoading={isLoading}
                        noOptionsMessage={() => noOptionsMessage || null}
                        loadingMessage={() => loadingMessage || 'Loading...'}
                        blurInputOnSelect={blurInputOnSelect}
                    />
                    <HelpText help={help} status={status} id={`${id}_HelpText`} />
                </FormGroup>
            </div>
        </div>
    );
};

AsyncSelectAutosuggestWidgetView.displayName = 'AsyncSelectAutosuggestWidgetView';
