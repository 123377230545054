import React, { FC } from 'react';

import FileInput from './fields/fileInput/FileInput';
import TextInput from './fields/textInput/TextInput';
import SelectInput from './fields/select/SelectInput';
import CheckableInput from './fields/checkable/CheckableInput';
import TextAreaInput from './fields/textarea/TextareaInput';
import SubmitButton from './fields/SubmitButton';

interface IProps {
    id: string;
    type?: string;
}

const FieldElement: FC<IProps> = props => {
    if (!props.type) return null;
    switch (props.type.toLowerCase()) {
        case 'submit':
        case 'button':
            return <SubmitButton {...props} />;
        case 'text':
        case 'number':
        case 'email':
        case 'tel':
        case 'password':
            return <TextInput {...props} />;
        case 'textarea':
            return <TextAreaInput {...props} />;
        case 'select':
            return <SelectInput {...props} />;
        case 'radio':
        case 'checkbox':
            return <CheckableInput {...props} />;
        case 'file':
            return <FileInput {...props} />;
        default:
            return null;
    }
};

FieldElement.displayName = 'FieldElement';

export default FieldElement;
