import {
    CLICK_AND_WAIT_FOR_FRAME,
    FORM_SUBMIT_SUCCESS_ACTION,
    POST_ACTION_SUCCESS_ACTION
} from '../../common/constants/form';

export const scrollOnErrorMiddleware = () => next => action => {
    try {
        const conditions = [
            ({ type }) => type === FORM_SUBMIT_SUCCESS_ACTION,
            ({ type, request = {} }) =>
                request.actionType === CLICK_AND_WAIT_FOR_FRAME && type === POST_ACTION_SUCCESS_ACTION
        ];
        if (conditions.some(conditionFunc => conditionFunc(action))) {
            setTimeout(() => {
                const failedElem = document.querySelector('.alert-danger') || document.getElementById('root');
                if (failedElem) {
                    failedElem.parentElement.scrollIntoView(true);
                } else {
                    const mainForm = document.querySelector('.main-form');
                    if (mainForm) {
                        mainForm.scrollIntoView(true);
                    }
                }
            }, 500);
        }
    } finally {
        next(action);
    }
};
