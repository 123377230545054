import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { THelpText } from '@stepstone/ats-scrapers-core/types';

import RenderTextArray from '../../../../common/components/ui/RenderTextArray';

type HelpTextProps = {
    help: THelpText;
    id?: string;
    status: string | undefined;
};

export const STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
};

const HelpText: React.FC<HelpTextProps> = ({ help, id = '', status = '' }) => {
    if (!help) {
        return null;
    }
    switch ((status || '').toLowerCase()) {
        case STATUS.ERROR:
            return (
                <div className="alert alert-danger alert-mirage" id={id}>
                    <FontAwesomeIcon icon="exclamation-circle" className="alert-icon-mirage" />
                    <RenderTextArray text={help} />
                </div>
            );
        case STATUS.SUCCESS:
            return (
                <div className="alert alert-success alert-mirage">
                    <FontAwesomeIcon icon="check-circle" className="alert-icon-mirage" />
                    <RenderTextArray text={help} />
                </div>
            );
        case STATUS.INFO:
            return (
                <div className="alert alert-info alert-mirage" id={id}>
                    <FontAwesomeIcon icon="info-circle" className="alert-icon-mirage" />
                    <RenderTextArray text={help} />
                </div>
            );
        case STATUS.WARNING:
            return (
                <div className="alert alert-warning alert-mirage" id={id}>
                    <FontAwesomeIcon icon="exclamation-triangle" className="alert-icon-mirage" />
                    <RenderTextArray text={help} />
                </div>
            );
        default:
            return (
                <div>
                    <i>
                        <RenderTextArray text={help} />
                    </i>
                </div>
            );
    }
};

HelpText.displayName = 'HelpText';

export default HelpText;
