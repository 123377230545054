import { FORM_SUBMIT_SUCCESS_ACTION } from '../../common/constants/form';
import { createSynchronizeFormValuesAction } from '../../common/actions/form';

export const formSubmissionSuccessMiddleware =
    ({ dispatch }) =>
    next =>
    action => {
        next(action);
        if (action.type === FORM_SUBMIT_SUCCESS_ACTION) {
            dispatch(createSynchronizeFormValuesAction());
        }
    };
