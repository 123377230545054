import { connect } from 'react-redux';

import { form, session } from '../../../../common/reducers';
import { ACTION_TYPE } from '../../../../common/constants/form';

export const connectInput = (elementFieldsSelector, mapDispatchToProps, component) => {
    const mapStateToProps = (state, { id }) => ({
        ...elementFieldsSelector(state, id),
        conversationUuid: session.getConversationUuid(state),
        isEnabledBrowserValidation: form.hasBrowserValidation(state),
        id
    });
    const mergeProps = (
        { preFillLink, conversationUuid, value, xPath, ...stateProps },
        { onChangeAction, onChangeActionWithType }
    ) => ({
        ...stateProps,
        defaultValue: value,
        postAction: (e, { xPath, value, ...optionProps }) => {
            if (optionProps.customProps && optionProps.customProps.useCustomAction) {
                return onChangeActionWithType(ACTION_TYPE.CLICK_WITH_JS, value, xPath, conversationUuid);
            }
            return onChangeAction(e, xPath, conversationUuid);
        }
    });
    return connect(mapStateToProps, mapDispatchToProps, mergeProps)(component);
};
