import { connect } from 'react-redux';
import {
    createDeleteFileAction,
    createFileUploadAction,
    fileUploadErrorHandler
} from '../../../../../common/actions/form';
import { getCustomValidation } from '../../../../../common/fileUploadUtils';
import { form, session } from '../../../../../common/reducers';
import FileInputView, { ComponentProps, DispatchProps, OwnProps, Props, StateProps } from './FileInputView';
import type { RootState } from '../../../../../app/store';

const additionalFields = ['blockRemoveBtn', 'value'];
const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    // TODO: align selector with other file uploads
    ...form.getCommonFieldDataWithAdditionalFields<ComponentProps>(state, id, ...additionalFields),
    conversationUuid: session.getConversationUuid(state),
    customValidation: getCustomValidation(state, id),
    isBrowserValidationEnabled: form.hasBrowserValidation(state),
    id
});

const mapDispatchToProps = {
    onChangeAction: createFileUploadAction,
    deleteFile: createDeleteFileAction
};

const mergeProps = (
    { preFillLink, conversationUuid, value, xPath, id, ...stateProps }: StateProps,
    { onChangeAction, deleteFile }: DispatchProps
): Props => ({
    ...stateProps,
    id,
    defaultValue: value,
    // @ts-expect-error Problem is related to thunk action which here is already unpacked
    postAction: e => onChangeAction(e.target.files, xPath, conversationUuid, id).catch(fileUploadErrorHandler),
    deleteFile: e => deleteFile(e, xPath, conversationUuid)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FileInputView);
