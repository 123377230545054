import type React from 'react';

type ConditionalWrapProps = {
    condition: boolean;
    wrap: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
};

export const ConditionalWrap: React.FC<ConditionalWrapProps> = ({ condition, wrap, children }) =>
    condition ? wrap(children) : children;

ConditionalWrap.displayName = 'ConditionalWrap';
