import React, { ChangeEvent, RefObject, SyntheticEvent, useRef } from 'react';
import classNames from 'classnames';
import { Button, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMirageFileInputNode } from '@stepstone/ats-scrapers-core/types';

import FormGroup from '../../FormGroup';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import HelpText, { STATUS } from './../HelpText';
import TranslatedText from '../../../../../common/components/ui/TranslatedText';
import { BROWSE_TEXT, NO_FILE_TEXT } from '../../../../../common/constants/stringKeys';
import { isEmptyValidation } from '../../../../../common/fileUploadUtils';
import { createDeleteFileAction, createFileUploadAction } from '../../../../../common/actions/form';
import { useBrowserValidation } from '../hooks';

export type OwnProps = { id: string };

export type ComponentProps = Pick<
    IMirageFileInputNode,
    | 'help'
    | 'isRequired'
    | 'label'
    | 'status'
    | 'accept'
    | 'customProps'
    | 'blockRemoveBtn'
    | 'preFillLink'
    | 'value'
    | 'isDisabled'
    | 'dataRole'
    | 'xPath'
>;

export type StateProps = {
    isBrowserValidationEnabled: boolean;
    customValidation: string | undefined;
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

export type DispatchProps = {
    onChangeAction: typeof createFileUploadAction;
    deleteFile: typeof createDeleteFileAction;
};

export type Props = Omit<StateProps, 'conversationUuid' | 'value' | 'xPath' | 'preFillLink'> & {
    postAction: (e: ChangeEvent<HTMLInputElement>) => unknown;
    deleteFile: (e: SyntheticEvent<SVGSVGElement>) => unknown;
    defaultValue: StateProps['value'];
};

const reformatHashedFilename = (fileName: Props['defaultValue']) => {
    const FAKE_PATH_PREFIX = 'C:\\fakepath\\';
    const HASH_LENGTH = 19;
    fileName = fileName.replace(FAKE_PATH_PREFIX, '');
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex > 18) {
        const fileHash = fileName.substring(lastDotIndex - HASH_LENGTH, lastDotIndex);
        if (/\d{19}/.test(fileHash)) {
            fileName = `${fileName.substring(0, lastDotIndex - HASH_LENGTH)}${fileName.substring(lastDotIndex)}`;
        }
    }
    return fileName;
};

const renderLabel = (label: Props['label']) => {
    return label ? (
        <FormLabel>
            <RenderTextArray text={label} />
        </FormLabel>
    ) : null;
};

const clearValue = (ref: RefObject<HTMLInputElement>): void => {
    const input = ref.current;
    if (input !== null) {
        input.value = '';
    }
};

const FileInputView: React.FC<Props> = ({
    help,
    label,
    status,
    postAction,
    deleteFile,
    defaultValue,
    isRequired,
    blockRemoveBtn,
    id,
    dataRole,
    isDisabled,
    customProps = {},
    customValidation,
    isBrowserValidationEnabled,
    ...inputProps
}) => {
    const ref = useRef<HTMLInputElement>(null);
    const browserMessage = useBrowserValidation(ref, isBrowserValidationEnabled);
    const validationStatus = status === STATUS.ERROR || browserMessage ? STATUS.ERROR : status;
    const helpMessage = help || (!defaultValue ? browserMessage : undefined);

    const filePath = defaultValue ? (
        <span>{reformatHashedFilename(defaultValue)}</span>
    ) : (
        <TranslatedText textKey={NO_FILE_TEXT} />
    );
    const removeButton =
        !blockRemoveBtn && defaultValue ? (
            <FontAwesomeIcon
                className="delete-icon"
                onClick={event => {
                    deleteFile(event);
                    clearValue(ref);
                }}
                icon="times"
                role="img"
                aria-label="Remove"
            />
        ) : null;
    return (
        <FormGroup
            className={classNames({
                required: !!label && isRequired,
                'has-error': validationStatus === STATUS.ERROR || !isEmptyValidation(customValidation)
            })}
            controlId={id}
        >
            {renderLabel(label)}
            <div>
                <input
                    ref={ref}
                    type="file"
                    onChange={postAction}
                    required={isRequired}
                    style={{ display: 'none' }}
                    {...inputProps}
                    aria-hidden="true"
                    data-role={dataRole}
                />
                <Button
                    id={id}
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        const input = ref.current;
                        if (input !== null) {
                            input.value = '';
                            input.click();
                        }
                    }}
                    aria-required={isRequired}
                    aria-describedby={`${id}_HelpText`}
                    aria-invalid={!!status}
                    disabled={isDisabled}
                    variant="primary"
                >
                    <TranslatedText textKey={BROWSE_TEXT} />
                </Button>
                {filePath}
                {removeButton}
            </div>
            <HelpText help={helpMessage} status={validationStatus} id={`${id}_HelpText`} />
            <HelpText help={customValidation} status={STATUS.ERROR} id={`${id}_CustomValidation`} />
        </FormGroup>
    );
};

FileInputView.displayName = 'FileInputView';

export default FileInputView;
