import { connect } from 'react-redux';

import type { RootState } from '../../../../../app/store';
import { createPostActionWithType } from '../../../../../common/actions/form';
import { BASE_URL } from '../../../../../common/constants';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import { form, session } from '../../../../../common/reducers';
import { CaptchaWidgetView, ComponentProps, DispatchProps, OwnProps, Props, StateProps } from './CaptchaWidgetView';

/**
 * Provides Base64 encoded string. It uses Base64Url format. On backend side it's decodec with `Base64.getUrlDecoder().decode(encodedXPath)`
 * @param elementXPath
 */
const getEncodedXPath = (elementXPath: string) => btoa(elementXPath).replace('==', '').replace('=', '');

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getElement<ComponentProps>(state, id),
    conversationUuid: session.getConversationUuid(state),
    id
});

const mapDispatchToProps: DispatchProps = {
    createPostActionWithType
};

const mergeProps = (
    { xPath, conversationUuid, elementXPath, clickXPath, ...stateProps }: StateProps,
    { createPostActionWithType }: DispatchProps
): Props => ({
    ...stateProps,
    imageSrc: `${BASE_URL}/apply/resources/${conversationUuid}/image/${getEncodedXPath(elementXPath)}`,
    postAction: (value: string) => createPostActionWithType(ACTION_TYPE.TEXT_INPUT, value, xPath, conversationUuid),
    shouldRenderRefreshButton: !!clickXPath,
    click: () => createPostActionWithType(ACTION_TYPE.CLICK, clickXPath, clickXPath || xPath, conversationUuid)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CaptchaWidgetView);
