import { StylesConfig } from 'react-select';
import { SelectOptionItem } from './AsyncSelectAutosuggestWidgetUtils';

const styles: StylesConfig<SelectOptionItem, boolean> = {
    container: (provided, state) => ({
        ...provided,
        // @ts-expect-error We base our status on status props being passed on component, to be changed
        border: state.selectProps.status === 'error' ? '1px solid red' : '',
        // @ts-expect-error We base our status on status props being passed on component, to be changed
        borderRadius: state.selectProps.status === 'error' ? '0.25rem' : ''
    }),
    control: provided => ({
        ...provided,
        borderColor: 'var(--colorBorderDefault, #ced4da)',
        boxShadow: 'none',
        borderRadius: 'var(--radiusRound, 0.375rem)',
        '&:hover': {
            borderColor: '#ced4da',
            cursor: 'pointer'
        },
        '&:active': {
            borderColor: 'rgba(82, 168, 236, 0.8)',
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(82, 168, 236, 0.6)'
        }
    })
};

export default styles;
