export const TJG_ID = 'totaljobs';
export const CATERER_ID = 'caterer';
export const IRISH_JOBS_ID = 'irish_jobs_ie';
export const RETAIL_CHOICE_ID = 'retailchoice';
export const JOBS_IE_ID = 'jobs_ie';
export const CW_JOBS_ID = 'cwjobs';
export const CAREER_STRUCTURE_ID = 'careerstructure';
export const STEPSTONE_DE_ID = 'stepstone_de';
export const STEPSTONE_NL_ID = 'stepstone_nl';
export const STEPSTONE_PL_ID = 'stepstone_pl';
export const STEPSTONE_FR_ID = 'stepstone_fr';
export const STEPSTONE_BE_ID = 'stepstone_be';
export const STEPSTONE_AT_ID = 'stepstone_at';
export const NI_JOBS_COM_ID = 'ni_jobs_com';
export const CATERER_GLOBAL_ID = 'catererglobal';
export const MILKROUND_ID = 'milkround';
export const APPCAST_ID = 'appcast';

export const STEPSTONE_JOBBOARDS = [
    STEPSTONE_DE_ID,
    STEPSTONE_PL_ID,
    STEPSTONE_FR_ID,
    STEPSTONE_NL_ID,
    STEPSTONE_BE_ID,
    STEPSTONE_AT_ID
];

export type TJobBoard =
    | typeof TJG_ID
    | typeof CATERER_ID
    | typeof IRISH_JOBS_ID
    | typeof RETAIL_CHOICE_ID
    | typeof JOBS_IE_ID
    | typeof CW_JOBS_ID
    | typeof CAREER_STRUCTURE_ID
    | typeof STEPSTONE_DE_ID
    | typeof STEPSTONE_NL_ID
    | typeof STEPSTONE_PL_ID
    | typeof STEPSTONE_FR_ID
    | typeof STEPSTONE_BE_ID
    | typeof STEPSTONE_AT_ID
    | typeof NI_JOBS_COM_ID
    | typeof CATERER_GLOBAL_ID
    | typeof MILKROUND_ID
    | typeof APPCAST_ID;

export type JobBoardData = {
    id: TJobBoard;
    name: string;
    url: string;
};

type TJobBoardData = Record<TJobBoard, JobBoardData>;

export const JOB_BOARD_DATA: TJobBoardData = {
    [TJG_ID]: {
        id: TJG_ID,
        name: 'Totaljobs Group',
        url: 'https://www.totaljobs.com/'
    },
    [CATERER_ID]: {
        id: CATERER_ID,
        name: 'Caterer',
        url: 'https://www.caterer.com/'
    },
    [IRISH_JOBS_ID]: {
        id: IRISH_JOBS_ID,
        name: 'Irish Jobs',
        url: 'https://www.irishjobs.ie/'
    },
    [JOBS_IE_ID]: {
        id: JOBS_IE_ID,
        name: 'Jobs.ie',
        url: 'https://www.jobs.ie/'
    },
    [RETAIL_CHOICE_ID]: {
        id: RETAIL_CHOICE_ID,
        name: 'Retailchoice',
        url: 'https://www.retailchoice.com/'
    },
    [CW_JOBS_ID]: {
        id: CW_JOBS_ID,
        name: 'CWJobs',
        url: 'https://www.cwjobs.co.uk/'
    },
    [CAREER_STRUCTURE_ID]: {
        id: CAREER_STRUCTURE_ID,
        name: 'CareerStructure',
        url: 'https://www.careerstructure.com/'
    },
    [STEPSTONE_DE_ID]: {
        id: STEPSTONE_DE_ID,
        name: 'Stepstone',
        url: 'https://www.stepstone.de/'
    },
    [STEPSTONE_FR_ID]: {
        id: STEPSTONE_FR_ID,
        name: 'Stepstone',
        url: 'https://www.stepstone.fr/'
    },
    [NI_JOBS_COM_ID]: {
        id: NI_JOBS_COM_ID,
        name: 'NIJobs.com',
        url: 'https://www.nijobs.com/'
    },
    [STEPSTONE_PL_ID]: {
        id: STEPSTONE_PL_ID,
        name: 'Stepstone.pl',
        url: 'https://www.stepstone.pl/'
    },
    [STEPSTONE_NL_ID]: {
        id: STEPSTONE_NL_ID,
        name: 'Stepstone.nl',
        url: 'https://www.stepstone.nl/'
    },
    [STEPSTONE_AT_ID]: {
        id: STEPSTONE_AT_ID,
        name: 'Stepstone.at',
        url: 'https://www.stepstone.at/'
    },
    [STEPSTONE_BE_ID]: {
        id: STEPSTONE_BE_ID,
        name: 'Stepstone.be',
        url: 'https://www.stepstone.be/'
    },
    [MILKROUND_ID]: {
        id: MILKROUND_ID,
        name: 'Milkround',
        url: 'https://www.milkround.com/'
    },
    [CATERER_GLOBAL_ID]: {
        id: CATERER_GLOBAL_ID,
        name: 'Caterer Global',
        url: 'https://www.catererglobal.com/'
    },
    [APPCAST_ID]: {
        id: APPCAST_ID,
        name: 'Appcast',
        url: 'https://appcast.io/'
    }
};
