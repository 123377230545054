import { createAction } from '@reduxjs/toolkit';

import { actionType } from './actionType';

export const formStructurePollingStart = createAction(actionType.FORM_STRUCTURE_POLLING_START);

export const formStructurePollingStop = createAction(actionType.FORM_STRUCTURE_POLLING_STOP);

export const formStructurePollingEnabled = createAction(actionType.FORM_STRUCTURE_POLLING_ENABLED);

export const formStructurePollingDisabled = createAction(actionType.FORM_STRUCTURE_POLLING_DISABLED);
