import { RefObject, useEffect, useState } from 'react';

/**
 * useRefreshId hook updates value if refreshId changed
 */
export function useRefreshId(
    ref: RefObject<HTMLInputElement | HTMLTextAreaElement>,
    value: string,
    refreshId: number
): void {
    useEffect(() => {
        if (refreshId && ref.current !== null) {
            ref.current.value = value;
        }
    }, [refreshId]);
}

/**
 * useBrowserValidation hook returns `browserMessage` if browser validation is enabled
 */
export function useBrowserValidation(
    ref: RefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    isBrowserValidationEnabled: boolean
): string | undefined {
    const [browserMessage, setBrowserMessage] = useState<string | undefined>(undefined);
    useEffect(() => {
        setBrowserMessage(isBrowserValidationEnabled ? ref.current?.validationMessage : undefined);
    }, [isBrowserValidationEnabled, ref, ref.current?.validationMessage]);

    return browserMessage;
}
