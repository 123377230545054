import { IMiragePhoneWidget } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';

import FormGroup from '../../FormGroup';
import { createPostActionWithType } from '../../../../../common/actions/form';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import { form, session } from '../../../../../common/reducers';
import HelpText from '../../fields/HelpText';
import type { RootState } from '../../../../../app/store';

type OwnProps = { id: string };

type ComponentProps = IMiragePhoneWidget;

type StateProps = {
    conversationUuid: string;
} & ComponentProps;

type DispatchProps = {
    createPostActionWithType: typeof createPostActionWithType;
};

type Props = Omit<StateProps, 'conversationUuid' | 'xPath'> & {
    postAction: (value: string) => void;
};

/**
 * There is no @types/react-phone-input-2
 */
type PhoneInputStub = {
    state: {
        formattedNumber: string;
    };
};

const containerStyleError = {
    border: '1px solid red',
    borderRadius: '0.25rem',
    height: 'calc(1.5em + 0.75rem + 2px)'
};

const containerStyle = {
    height: 'calc(1.5em + 0.75rem + 2px)'
};

const PhoneWidget: React.FC<Props> = ({
    label,
    value,
    isRequired,
    placeholder = '',
    help,
    status,
    defaultFormat,
    autoFormat = false,
    alwaysDefaultMask = false,
    prefix = '',
    localization = { undefined },
    postAction,
    countryCode,
    country,
    dataRole,
    id
}) => {
    const [phone, setPhone] = useState(() => (value.startsWith('+') ? value : `${countryCode}${value}`));
    const [prefixState, setPrefix] = useState(prefix);
    const inputRef = React.createRef<PhoneInputStub>();

    useEffect(() => {
        if (inputRef.current) {
            const inputValueFormatted = inputRef.current.state.formattedNumber;
            setPhone(inputValueFormatted);
            postAction(inputValueFormatted);
        }
    }, []);

    const handleOnChange = () => {
        if (inputRef.current) {
            const inputValueFormatted = inputRef.current.state.formattedNumber;
            prefixState === inputValueFormatted ? setPrefix('') : setPrefix(prefix);
            setPhone(inputValueFormatted);
            postAction(inputValueFormatted);
        }
    };

    return (
        <div className="row">
            <div className="col-xs-12 input-row ">
                <FormGroup className={classNames({ required: isRequired })}>
                    {label && (
                        <FormLabel className="form-label w-100">
                            <RenderTextArray text={label} />
                        </FormLabel>
                    )}
                    <PhoneInput
                        ref={inputRef}
                        country={country}
                        autoFormat={autoFormat}
                        defaultMask={defaultFormat}
                        prefix={prefixState}
                        alwaysDefaultMask={alwaysDefaultMask}
                        localization={localization}
                        value={phone}
                        inputClass="w-100 h-100"
                        containerStyle={status === 'error' ? containerStyleError : containerStyle}
                        onChange={handleOnChange}
                        placeholder={placeholder}
                        inputProps={{
                            'data-role': dataRole,
                            'aria-describedby': id && `${id}_HelpText`,
                            'aria-required': isRequired,
                            'aria-invalid': status === 'error'
                        }}
                    />
                    {help && (
                        <div className="w-100">
                            <HelpText help={help} status={status} />
                        </div>
                    )}
                </FormGroup>
            </div>
        </div>
    );
};

PhoneWidget.displayName = 'PhoneWidget';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...(form.getPhoneData(state, id) as IMiragePhoneWidget),
    conversationUuid: session.getConversationUuid(state)
});

const mapDispatchToProps: DispatchProps = {
    createPostActionWithType
};

const mergeProps = (
    { xPath, conversationUuid, ...stateProps }: StateProps,
    { createPostActionWithType }: DispatchProps
): Props => ({
    ...stateProps,
    postAction: value => createPostActionWithType(ACTION_TYPE.TEXT_INPUT, value, xPath, conversationUuid)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PhoneWidget);
