import { connect } from 'react-redux';
import { LabelElement, OwnProps, StateProps } from './LabelElementView';
import { form } from '../../../../common/reducers';
import type { RootState } from '../../../../app/store';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getCustomData<StateProps>(state, id, ['text', 'status', 'isRequired'])
});

export default connect(mapStateToProps)(LabelElement);
