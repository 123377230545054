import { combineReducers, Reducer } from 'redux';
import { FormActions } from '../../common/actions/form';

import {
    FETCH_FORM_REQUEST_ACTION,
    FETCH_FORM_SUCCESS_ACTION,
    FORM_SUBMIT_SUCCESS_ACTION,
    INITIALIZATION_STATE_INITIALIZED
} from '../../common/constants/form';
import type { RootState } from '../../app/store';

const sessionStarted: Reducer<number, FormActions> = (state = 0, action) => {
    switch (action.type) {
        case FETCH_FORM_REQUEST_ACTION:
            return state || Date.now();
        default:
            return state;
    }
};

const isFormInitialized = (status: string) => status === INITIALIZATION_STATE_INITIALIZED;

const formRendered: Reducer<number, FormActions> = (state = 0, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            return isFormInitialized(action.response.status) ? Date.now() : state;
        default:
            return state;
    }
};

const currentPageOpened: Reducer<number, FormActions> = (state = 0, action) => {
    switch (action.type) {
        case FORM_SUBMIT_SUCCESS_ACTION:
            return Date.now();
        case FETCH_FORM_SUCCESS_ACTION:
            return isFormInitialized(action.response.status) ? Date.now() : state;
        default:
            return state;
    }
};

export default combineReducers({
    sessionStarted,
    formRendered,
    currentPageOpened
});

// selectors
export const getSessionStarted = (state: RootState['statistics']): ReturnType<typeof sessionStarted> =>
    state.sessionStarted;
