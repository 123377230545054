import { Middleware } from 'redux';

import { INVALIDATE_FILE_UPLOAD_ACTION } from '../../common/constants/form';
import { session } from '../../common/reducers';
import { sendLog } from '../../common/actions/log';
import { LOG_TYPE } from '../../common/constants/log';
import { job } from '../../mirage/reducers';

export const invalidateFileUploadLogMiddleware: Middleware = store => next => action => {
    try {
        const { type, fileSize = 0 } = action;
        if (INVALIDATE_FILE_UPLOAD_ACTION === type) {
            const state = store.getState();
            sendLog(session.getConversationUuid(state), LOG_TYPE.FILE_UPLOAD_FAILURE, {
                ...job.getJobLoggingContext(state),
                jobBoardId: session.getJobBoardId(state),
                info: { fileSize }
            });
        }
    } finally {
        next(action);
    }
};
