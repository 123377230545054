import React, { FormEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { FormCheck, FormLabel } from 'react-bootstrap';
import { IMirageMultiFieldNode } from '@stepstone/ats-scrapers-core/types';

import FormGroup from '../FormGroup';
import { createPostAction, createPostActionWithType } from '../../../../common/actions/form';
import { form } from '../../../../common/reducers';
import { connectInput } from './utils';
import RenderTextArray from '../../../../common/components/ui/RenderTextArray';
import HelpText from '../fields/HelpText';
import { CheckableInput, CheckableInputProps } from '../fields/checkable/CheckableInput';

type MultiFieldProps = {
    postAction: (e: React.FormEvent<HTMLInputElement>) => void;
    options: CheckableInputProps[];
    isEnabledBrowserValidation: boolean;
} & IMirageMultiFieldNode;

type MultiFieldOptionsProps = {
    isEnabledBrowserValidation: boolean;
    setMultiFieldBrowserMessage: React.Dispatch<React.SetStateAction<string>>;
    options: CheckableInputProps[];
    label: MultiFieldProps['label'];
    postAction: (e: React.FormEvent<HTMLInputElement>, optionProps: CheckableInputProps) => void;
    render: (
        props: {
            key: string;
        } & CheckableInputProps
    ) => ReactNode;
};

const MultiFieldOptions: React.FC<MultiFieldOptionsProps> = ({
    isEnabledBrowserValidation,
    setMultiFieldBrowserMessage,
    options,
    postAction,
    label,
    render
}) => (
    <>
        {options.map(optionProps =>
            render({
                ...optionProps,
                key: optionProps.xPath,
                LabelWrapper: label ? 'label' : FormCheck.Label,
                postAction: (e: FormEvent<HTMLInputElement>) => postAction(e, optionProps),
                shouldWrap: false,
                isEnabledBrowserValidation,
                setMultiFieldBrowserMessage
            })
        )}
    </>
);

MultiFieldOptions.displayName = 'MultiFieldOptions';

const getRole = (options: MultiFieldProps['options']) =>
    options.find(element => element.type === 'radio') ? 'radiogroup' : 'group';

const MultiField: React.FC<MultiFieldProps> = ({
    isEnabledBrowserValidation,
    help,
    label,
    status,
    isRequired,
    options,
    id,
    ...props
}) => {
    const [browserMessage, setMultiFieldBrowserMessage] = useState<string>('');
    const helpMessage = help || browserMessage;
    const validationStatus = status === 'error' || browserMessage ? 'error' : status;

    return (
        <div className="row">
            <div className="col-xs-12 input-row">
                <FormGroup
                    role={getRole(options)}
                    className={classNames({ required: isRequired })}
                    controlId={id}
                    aria-required={isRequired}
                    aria-invalid={!!status}
                    aria-describedby={`${id}_HelpText`}
                    id={id}
                >
                    {label && (
                        <FormLabel>
                            <RenderTextArray text={label} />
                        </FormLabel>
                    )}
                    <MultiFieldOptions
                        label={label}
                        options={options}
                        isEnabledBrowserValidation={isEnabledBrowserValidation}
                        setMultiFieldBrowserMessage={setMultiFieldBrowserMessage}
                        {...props}
                        render={optionProps => <CheckableInput {...optionProps} />}
                    />
                    <HelpText help={helpMessage} status={validationStatus} id={`${id}_HelpText`} />
                </FormGroup>
            </div>
        </div>
    );
};

MultiField.displayName = 'MultiField';

export default connectInput(
    form.getMultiFieldData,
    { onChangeAction: createPostAction, onChangeActionWithType: createPostActionWithType },
    MultiField
);
