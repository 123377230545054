import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { SurveyType } from '../../../common/constants/form';
import TranslatedTemplateText from '../../../common/components/ui/TranslatedTemplateText';
import { COMPANY_MESSAGE, DEFAULT_COMPANY_NAME } from '../../../common/constants/stringKeys';
import { language } from '../../../common/reducers';

import { SkeletonHeader } from '../SkeletonLoader/SkeletonLoader';
import type { RootState } from '../../../app/store';

type JobDetailsProps = {
    companyLogoUrl: string | null;
    companyName: string | null;
    defaultCompanyName: string;
    jobBoardName: string;
    jobTitle: string | null;
    onLogoError: React.DOMAttributes<HTMLImageElement>['onError'];
    surveyType: string;
    isFetching: boolean;
};

const JobDetails = ({
    companyLogoUrl,
    companyName,
    defaultCompanyName,
    jobBoardName,
    jobTitle,
    onLogoError,
    surveyType,
    isFetching
}: JobDetailsProps) => {
    const haveCompanyNameOrHeader = companyLogoUrl || companyName;

    if (isFetching && !haveCompanyNameOrHeader) {
        return <SkeletonHeader />;
    }

    return (
        <div className="col-xs-12 col-page-header">
            {haveCompanyNameOrHeader && (
                <div className="company-logo-container" style={{ display: 'table-cell' }}>
                    <span className="company-logo-link engagement-metric">
                        {companyLogoUrl ? (
                            <img
                                src={companyLogoUrl}
                                alt={companyName || undefined}
                                onError={onLogoError}
                                className="company-logo"
                            />
                        ) : (
                            <div className="company-missing-logo-wrapper">
                                <div className="company-missing-logo-text-wrapper">{companyName}</div>
                            </div>
                        )}
                    </span>
                </div>
            )}
            <div
                className={classNames('job-details-message-wrapper', {
                    'missing-company-data': !haveCompanyNameOrHeader
                })}
            >
                <h1>{jobTitle}</h1>
                {showInformationMessage(surveyType, companyName || defaultCompanyName, jobBoardName)}
            </div>
        </div>
    );
};

JobDetails.displayName = 'JobDetails';

const showInformationMessage = (surveyType: string, companyName: string, jobBoardName: string) => {
    switch (surveyType) {
        case SurveyType.REGISTRATION:
            return yourAreRegisteringMessage(companyName);
        case SurveyType.APPLICATION_FOR_A_JOB:
            return yourAreApplyingMessage(companyName, jobBoardName);
        default:
            return null;
    }
};

const DEFAULT_RECRUITERS_NAME = 'the recruiting company';
const companyNameOrDefault = (companyName: string) => companyName || DEFAULT_RECRUITERS_NAME;

const yourAreApplyingMessage = (companyName: string, jobBoardName: string) => (
    <span className="job-details-message">
        <TranslatedTemplateText
            textKey={COMPANY_MESSAGE}
            replaces={{ companyName: companyNameOrDefault(companyName), jobBoardName }}
        />
    </span>
);

const yourAreRegisteringMessage = (companyName: string) => (
    <span className="job-details-message">
        {`This application form requires you to Register an account with ${companyNameOrDefault(
            companyName
        )} and fill in the Apply form. You will be guided through the steps below.`}
    </span>
);

export default connect((state: RootState) => ({
    defaultCompanyName: language.getTranslatedText(state, DEFAULT_COMPANY_NAME)
}))(JobDetails);
