import React, { FC } from 'react';

import CustomPage from '../ui/CustomPage';
import { REQUIREMENTS_NOT_MET_PAGE_MESSAGE, REQUIREMENTS_NOT_MET_PAGE_TITLE } from '../../constants/stringKeys';
import TranslatedText from '../ui/TranslatedText';

const RequirementsNotMetPage: FC = () => (
    <CustomPage
        icon="exclamation-circle"
        message={<TranslatedText textKey={REQUIREMENTS_NOT_MET_PAGE_MESSAGE} />}
        title={<TranslatedText textKey={REQUIREMENTS_NOT_MET_PAGE_TITLE} />}
    />
);

export default RequirementsNotMetPage;
