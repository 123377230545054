import React from 'react';
import { FormGroup as BsFormGroup, FormGroupProps } from 'react-bootstrap';

import classNames from 'classnames';

type Props = {
    className?: string;
} & FormGroupProps;

const FormGroup: React.FC<Props> = ({ children, className, ...props }) => {
    return (
        <BsFormGroup {...props} className={classNames('form-group', className)}>
            {children}
        </BsFormGroup>
    );
};

FormGroup.displayName = 'FormGroup';

export default FormGroup;
