export const JOB_KEY = 'job';
export const FORM_KEY = 'form';
export const GUI_KEY = 'gui';
export const SESSION_KEY = 'session';
export const STATISTICS_KEY = 'statistics';
export const LANGUAGE_KEY = 'language';

export const JSON_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};
export const X_XSRF_TOKEN_HEADER_NAME = 'X-XSRF-TOKEN';
export const CSRF_TOKEN_COOKIE_NAME = 'CSRF-TOKEN';

export const BASE_URL_FOR_STYLING = process.env.REACT_APP_BASE_URL_FOR_STYLING;
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080/api';

export const EFFECTIVE_ENDPOINT_URL_PARAM_NAME = 'ou';
export const POST_APPLICATION_URL_PARAM_NAME = 'pa';
export const TIMEOUT_PARAM_NAME = 't';
export const CONVERSATION_UUID_PARAM_NAME = 'conversationUuid';
export const JOB_BOARD_ID_PARAM_NAME = 'jb';
export const DEV_MODE_ENABLED_PARAM_NAME = 'devMode';
export const LANGUAGE_PARAM_NAME = 'lang';
export const STYLING_ID_PARAM_NAME = 'si';

export const ENGLISH_LANGUAGE = 'en';
export const POLISH_LANGUAGE = 'pl';
export const GERMAN_LANGUAGE = 'de';
export const FRENCH_LANGUAGE = 'fr';
export const DUTCH_LANGUAGE = 'nl';
