import React, { FC } from 'react';
import classNames from 'classnames';
import { FormLabel, Table } from 'react-bootstrap';
import {
    IMirageActionCell,
    IMirageFieldNode,
    IMirageTableRow,
    IMirageTextCell
} from '@stepstone/ats-scrapers-core/types';

import FormGroup from '../../FormGroup';
import HelpText from '../../fields/HelpText';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import { ConnectedAction } from './SummaryTableConnectedAction';

export type SummaryTableProps = {
    rows: IMirageTableRow[];
} & IMirageFieldNode;

type CellProps = IMirageActionCell | IMirageTextCell;

export const SummaryTable: FC<SummaryTableProps> = ({ rows, help, status, label, isRequired, id }) => (
    <div className="row">
        <div className="col-xs-12 input-row">
            {label && (
                <FormGroup className={classNames({ required: isRequired })}>
                    <FormLabel className="form-label">
                        <RenderTextArray text={label} />
                    </FormLabel>
                </FormGroup>
            )}
            <Table responsive bordered striped>
                <tbody>{rows.map(SummaryTableRow)}</tbody>
            </Table>
            {help && <HelpText help={help} status={status} id={`${id}_HelpText`} />}
        </div>
    </div>
);

SummaryTable.displayName = 'SummaryTable';

const SummaryTableRow = ({ columns }: IMirageTableRow, index: number) => (
    <tr key={index}>{columns.map(SummaryTableCell)}</tr>
);

SummaryTableRow.displayName = 'SummaryTableRow';

const SummaryTableCell = ({ tagName, type, colspan, customProps = {}, ...props }: CellProps, index: number) => {
    const TagName = tagName.toLowerCase() as 'th' | 'td';
    return (
        <TagName
            key={index}
            colSpan={colspan || 1}
            className={customProps?.customClassName ? 'align-middle ' + customProps?.customClassName : 'align-middle'}
        >
            {'actions' in props ? (
                <div className="actions-cell">
                    {props.actions.map(({ xPath, ...props }) => (
                        // @ts-expect-error Missing props required by ConnectedAction
                        <ConnectedAction key={xPath} xPath={xPath} {...props} />
                    ))}
                </div>
            ) : (
                props.text
            )}
        </TagName>
    );
};

SummaryTableCell.displayName = 'SummaryTableCell';
