import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { IMirageValidation, TCustomProps } from '@stepstone/ats-scrapers-core/types';

import { form } from '../../../../../common/reducers';
import HelpText, { STATUS } from '../../fields/HelpText';
import type { RootState } from '../../../../../app/store';

export type ValidationsGroupProps = {
    validations: IMirageValidation[];
    customProps: TCustomProps;
};

const ValidationsGroup: React.FC<ValidationsGroupProps> = ({ validations, customProps = {} }) => {
    const wrapperClassNames = ['col-xs-12', 'input-row'];
    return (
        <div className="row">
            <div className={classNames(wrapperClassNames, customProps.customClassName)}>
                {validations.map(({ message, passed }) => {
                    const key = message.toString();
                    return (
                        <HelpText key={key} id={key} help={message} status={passed ? STATUS.SUCCESS : STATUS.ERROR} />
                    );
                })}
            </div>
        </div>
    );
};

ValidationsGroup.displayName = 'ValidationsGroup';

//TODO: ATSI-1930 Spike adding TypeScript typings to reflection state
const mapStateToProps = (state: RootState, { id }: { id: string }) => ({
    ...form.getElement(state, id)
});

export default connect(mapStateToProps)(ValidationsGroup);
