import { connect } from 'react-redux';

import { session } from '../../../../../common/reducers';
import * as actions from '../../../../../common/actions/form';
import { ACTION_TYPE, CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE } from '../../../../../common/constants/form';
import { Action } from './SummaryTableActionView';

export const ConnectedAction = connect(
    state => ({ userSessionUuid: session.getConversationUuid(state) }),
    actions,
    (
        { userSessionUuid },
        { sendAnchorClickEvent, createClickAndWaitForFrameAction, clickAndWaitForElement },
        { xPath, iframeSelector, selectorToRemoveElement, customClickAction, help, isRequired, label, ...ownProps }
    ) => ({
        ...ownProps,
        selectorToRemoveElement,
        handleClick: () => {
            if (customClickAction) {
                createClickAndWaitForFrameAction(xPath, userSessionUuid, iframeSelector);
            } else {
                sendAnchorClickEvent(xPath, userSessionUuid, ACTION_TYPE.CLICK);
            }
        },
        addOrRemoveElement: () =>
            clickAndWaitForElement(
                CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE,
                selectorToRemoveElement,
                xPath,
                userSessionUuid
            )
    })
)(Action);
