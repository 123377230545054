import { and, negate, or } from '@stepstone/ats-scrapers-core/fp';
import { IMirageNode, MIRAGE_NODE_REFERENCE } from '@stepstone/ats-scrapers-core/types';
import { Reducer, combineReducers } from 'redux';

import type { RootState } from '../../app/store';
import {
    FormActions,
    LogoErrorAction,
    PostponeStructureRefreshAction,
    SynchronizeFormValuesAction
} from '../../common/actions/form';
import { CONVERSATION_UUID_PARAM_NAME } from '../../common/constants';
import {
    ALREADY_APPLIED_STATE,
    APPLIED_FORM_STATE,
    ERROR_FORM_STATE,
    EXPIRED_FORM_STATE,
    FETCH_FORM_FAILURE_ACTION,
    FETCH_FORM_REQUEST_ACTION,
    FETCH_FORM_SUCCESS_ACTION,
    FILE_TOO_LARGE_FORM_STATE,
    FLOW_TYPE,
    FORM_SUBMIT_REQUEST_ACTION,
    FORM_SUBMIT_SUCCESS_ACTION,
    GET_STRUCTURE_SUCCESS_ACTION,
    INITIALIZATION_ERROR_MAX_COUNT,
    INITIALIZATION_STATE_ERROR,
    INITIALIZATION_STATE_INITIALIZED,
    INITIALIZATION_STATE_JOB_EXPIRED,
    INVALIDATE_FILE_UPLOAD_ACTION,
    INVALIDATE_SESSION_ACTION,
    LOGO_ERROR_ACTION,
    POSTPONE_STRUCTURE_REFRESH_ACTION,
    POST_ACTION_SUCCESS_ACTION,
    POST_UPLOAD_SUCCESS_ACTION,
    REQUIREMENTS_NOT_MET_STATE,
    SESSION_NOT_FOUND_FORM_STATE,
    SYNCHRONIZE_FORM_VALUES_ACTION,
    SurveyType
} from '../../common/constants/form';
import { ACTION_FAILURE_REASON, ERROR_CAUSE, REDIRECT_REASONS, REDIRECT_REASON_KEY } from '../../common/constants/log';
import { REFRESH_SESSION_SUCCESS_ACTION } from '../../common/constants/session';
import { VisibilityState, getVisibilityState } from '../../common/dom/helpers';
import { getUrlParam } from '../../utils';
import { SessionActions } from '../actions/session';
import { FormStructureDto, ResponseProvide } from '../api';
import { initFormRetry } from '../state/init/action';
import { hasUserSuccessfullyApplied } from '../state/persistency';

// TODO Refine helpers while doing ATSI-1912
const getTextHash = (text: any) =>
    Array.isArray(text) ? text.reduce((obj, c) => `${obj}_${c.type}|${c.text}_`, '_') : text;

const getElementKey = (elem: any) => elem.xPath || `${elem.tagName}_${getTextHash(elem.text)}_${elem.isVisible}`;

const processSurveyStructure = <S, P extends (...args: any) => any>(
    structure: FormStructureDto,
    prevState: S,
    func: P
): S | ReturnType<P> => {
    if (!structure) {
        return prevState;
    }

    const funcResult = func(structure);

    if (!funcResult || (Array.isArray(funcResult) && !funcResult.length)) {
        return prevState;
    }

    return funcResult;
};

const takeRedirectReasonBaseOnVisibility = () =>
    getVisibilityState() === VisibilityState.HIDDEN
        ? REDIRECT_REASONS.NO_ACTION_FROM_USER_APP_IN_BACKGROUND
        : REDIRECT_REASONS.NO_ACTION_FROM_USER;

const initializationStatuses = [
    INITIALIZATION_STATE_INITIALIZED,
    INITIALIZATION_STATE_ERROR,
    INITIALIZATION_STATE_JOB_EXPIRED
];
const finishedStatuses = [APPLIED_FORM_STATE, REQUIREMENTS_NOT_MET_STATE, ALREADY_APPLIED_STATE];
const errorStatuses = [SESSION_NOT_FOUND_FORM_STATE, ERROR_FORM_STATE];

const includesStatuses = (arr: string[], status: string) => arr.includes(status);

const isInitialized = (status: string) => includesStatuses(initializationStatuses, status);

const hasFinishedApplication = ({ status }: FormStructureDto) => includesStatuses(finishedStatuses, status);
const hasAlert = ({ alert }: FormStructureDto) => !!alert;
const isFileTooLarge = ({ status }: FormStructureDto) => status === FILE_TOO_LARGE_FORM_STATE;
const hasErrorState = ({ status }: FormStructureDto) => includesStatuses(errorStatuses, status);
const hasElements = ({ elements }: FormStructureDto) => !!(elements?.length && elements[0].tagName);
const hasNavigation = ({ navigation }: FormStructureDto) => !!navigation?.length;

const isStructureValid = or(
    hasFinishedApplication,
    hasAlert,
    isFileTooLarge,
    and(negate(hasErrorState), hasElements, hasNavigation)
);

const isFormExpired = (form: FormStructureDto) => form.status === EXPIRED_FORM_STATE;

const generateNestedFields = (elements: any) =>
    elements
        .filter((elem: any) => elem.tagName === 'WIDGET' && elem.type === 'INLINE_MULTIFIELD_WIDGET')
        .reduce((total: any, field: any) => [...total, ...field.fields], []);

const computeElements = (structure: FormStructureDto, state: any) =>
    processSurveyStructure(structure, state, structure => {
        const { elements, navigation } = structure;
        if (elements && navigation) {
            return [...elements, ...navigation, ...generateNestedFields(elements)].reduce(
                (obj, element) => ({
                    ...obj,
                    [getElementKey(element)]: { ...element, isLoading: false }
                }),
                { ...state }
            );
        }
        return {};
    });

const computeStructure = (structure: FormStructureDto, state: any) =>
    processSurveyStructure(structure, state, structure => {
        const { elements } = structure;
        if (!elements) {
            return [];
        }
        return elements.map((element: string) => getElementKey(element));
    });

const computeNavigation = (structure: FormStructureDto, state: any) =>
    processSurveyStructure(structure, state, structure => {
        const { navigation } = structure;
        if (!navigation) {
            return [];
        }
        return navigation.map((element: string) => getElementKey(element));
    });

// reducers
type FormStructuringActions = FormActions | SessionActions;

const elementsReducer: Reducer<any, FormStructuringActions> = (state = {}, action) => {
    switch (action.type) {
        case FORM_SUBMIT_REQUEST_ACTION:
            return {
                ...state,
                [action.request.inputXPath]: {
                    ...state[action.request.inputXPath],
                    isLoading: true
                }
            };
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_ACTION_SUCCESS_ACTION:
        case POST_UPLOAD_SUCCESS_ACTION:
        case GET_STRUCTURE_SUCCESS_ACTION:
            return computeElements(action.response, state);
        case FETCH_FORM_SUCCESS_ACTION:
            return computeElements(action.response.surveyStructure, state);
        default:
            return state;
    }
};

const initRetriesLeftReducer: Reducer<number, typeof initFormRetry> = (
    state = INITIALIZATION_ERROR_MAX_COUNT,
    action
) => {
    switch (action.type) {
        case initFormRetry.toString():
            return state - 1;
        default:
            return state;
    }
};

const initializationStatusReducer: Reducer<ResponseProvide['status'] | null, FormStructuringActions> = (
    state = null,
    action
) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            return ('status' in action.response && action.response.status) || state;
        case FETCH_FORM_FAILURE_ACTION:
            if (action.cause === ACTION_FAILURE_REASON.NETWORK_ERROR) {
                return state;
            }
            return INITIALIZATION_STATE_ERROR;
        default:
            return state;
    }
};

const flowTypeReducer: Reducer<ResponseProvide['flowType'] | null, FormStructuringActions> = (
    state = FLOW_TYPE.INTERACTIVE,
    action
) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            return action.response.flowType || FLOW_TYPE.INTERACTIVE;
        default:
            return state;
    }
};

const isSessionExpiredReducer: Reducer<boolean, FormStructuringActions> = (state = false, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            return isInitialized(action.response.status)
                ? processSurveyStructure(action.response.surveyStructure, state, isFormExpired)
                : state;
        case FORM_SUBMIT_SUCCESS_ACTION:
            return processSurveyStructure(action.response, state, isFormExpired);
        case POST_ACTION_SUCCESS_ACTION:
            return state || processSurveyStructure(action.response, state, isFormExpired);
        case REFRESH_SESSION_SUCCESS_ACTION:
            return !action.response.active;
        default:
            return state;
    }
};

const redirectReasonReducer: Reducer<string, FormStructuringActions> = (state = '', action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            const { surveyStructure, status, errorCause } = action.response;
            switch (true) {
                case errorCause === ERROR_CAUSE.EXTERNAL:
                    return REDIRECT_REASONS.ATS_EXTERNAL_ERROR;
                case isInitialized(status) && processSurveyStructure(surveyStructure, false, isStructureValid):
                    return state;
                case surveyStructure == null:
                case !hasElements(surveyStructure) && !hasNavigation(surveyStructure):
                    return REDIRECT_REASONS.INITIALIZATION_NO_STRUCTURE_ERROR;
                case !hasElements(surveyStructure):
                    return REDIRECT_REASONS.INITIALIZATION_NO_ELEMENTS_ERROR;
                case !hasNavigation(surveyStructure):
                    return REDIRECT_REASONS.INITIALIZATION_NO_NAVIGATION_ERROR;
                case hasErrorState(surveyStructure):
                    return REDIRECT_REASONS.INITIALIZATION_STATE_ERROR;
                default:
                    return REDIRECT_REASONS.INITIALIZATION_ERROR;
            }
        case POST_ACTION_SUCCESS_ACTION:
            return processSurveyStructure(action.response, false, isStructureValid)
                ? state
                : REDIRECT_REASONS.INTERMEDIATE_STAGE_ERROR_POST_ACTION;
        case POST_UPLOAD_SUCCESS_ACTION:
            return processSurveyStructure(action.response, false, isStructureValid)
                ? state
                : REDIRECT_REASONS.INTERMEDIATE_STAGE_ERROR_POST_UPLOAD;
        case FORM_SUBMIT_SUCCESS_ACTION:
            return processSurveyStructure(action.response, false, isStructureValid)
                ? state
                : // @ts-expect-error API response is not fully typed (doesn't include error response)
                  'cause' in action.response && REDIRECT_REASONS[action.response.cause]
                  ? // @ts-expect-error API response is not fully typed (doesn't include error response)
                    REDIRECT_REASONS[action.response.cause]
                  : REDIRECT_REASONS.INTERMEDIATE_STAGE_ERROR_FORM_SUBMIT;
        case GET_STRUCTURE_SUCCESS_ACTION:
            return processSurveyStructure(action.response, false, isStructureValid)
                ? state
                : REDIRECT_REASONS.INTERMEDIATE_STAGE_ERROR_GET_STRUCTURE;
        case INVALIDATE_SESSION_ACTION:
            return action[REDIRECT_REASON_KEY];
        case REFRESH_SESSION_SUCCESS_ACTION:
            return action.response.active
                ? state
                : action.response.expires == null
                  ? REDIRECT_REASONS.SESSION_NOT_AVAILABLE
                  : takeRedirectReasonBaseOnVisibility();
        default:
            return state;
    }
};

const isFetchingReducer: Reducer<boolean, FormStructuringActions> = (state = true, action) => {
    switch (action.type) {
        case FETCH_FORM_REQUEST_ACTION:
            return true;
        case FETCH_FORM_SUCCESS_ACTION:
            return isInitialized(action.response.status) ? false : state;
        case INVALIDATE_SESSION_ACTION:
            return false;
        default:
            return state;
    }
};

const isSuccessfullyAppliedReducer: Reducer<boolean, FormStructuringActions> = (
    state = hasUserSuccessfullyApplied(getUrlParam(CONVERSATION_UUID_PARAM_NAME) || ''),
    action
) => {
    const hasAppliedStatus = (surveyStructure: FormStructureDto) =>
        processSurveyStructure(surveyStructure, state, form => form.status === APPLIED_FORM_STATE);

    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            const { surveyStructure, flowType } = action.response;
            return flowType === FLOW_TYPE.ZERO_TOUCH && hasAppliedStatus(surveyStructure);
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_ACTION_SUCCESS_ACTION:
            return hasAppliedStatus(action.response);
        default:
            return state;
    }
};

const areRequirementsNotMetReducer: Reducer<boolean, FormStructuringActions> = (state = false, action) => {
    switch (action.type) {
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_ACTION_SUCCESS_ACTION:
            return processSurveyStructure(action.response, state, form => form.status === REQUIREMENTS_NOT_MET_STATE);
        case FETCH_FORM_SUCCESS_ACTION:
            const { surveyStructure } = action.response;
            return processSurveyStructure(surveyStructure, state, form => form.status === REQUIREMENTS_NOT_MET_STATE);
        default:
            return state;
    }
};

const wasAlreadyAppliedReducer: Reducer<boolean, FormStructuringActions> = (state = false, action) => {
    switch (action.type) {
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_ACTION_SUCCESS_ACTION:
            return processSurveyStructure(action.response, state, form => form.status === ALREADY_APPLIED_STATE);
        case FETCH_FORM_SUCCESS_ACTION:
            const { surveyStructure } = action.response;
            return processSurveyStructure(surveyStructure, state, form => form.status === ALREADY_APPLIED_STATE);
        default:
            return state;
    }
};

const isSubmittingReducer: Reducer<boolean, FormStructuringActions> = (state = false, action) => {
    switch (action.type) {
        case FORM_SUBMIT_REQUEST_ACTION:
            return true;
        case FORM_SUBMIT_SUCCESS_ACTION:
            return false;
        case FETCH_FORM_SUCCESS_ACTION:
            const { status, flowType } = action.response;
            return !isInitialized(status) && flowType === FLOW_TYPE.ZERO_TOUCH;
        default:
            return state;
    }
};

const isValidReducer: Reducer<boolean, FormStructuringActions> = (state = true, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            const { surveyStructure, status } = action.response;
            return isInitialized(status) ? processSurveyStructure(surveyStructure, false, isStructureValid) : state;
        case POST_ACTION_SUCCESS_ACTION:
        case POST_UPLOAD_SUCCESS_ACTION:
        case FORM_SUBMIT_SUCCESS_ACTION:
        case GET_STRUCTURE_SUCCESS_ACTION:
            return processSurveyStructure(action.response, false, isStructureValid);
        case INVALIDATE_SESSION_ACTION:
            return false;
        default:
            return state;
    }
};

const paramsReducer: Reducer<
    { jobTitle: string | null; companyLogoUrl: string | null; companyName: string | null },
    FormStructuringActions | LogoErrorAction
> = (state = { jobTitle: null, companyLogoUrl: null, companyName: null }, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            const { surveyStructure, atsiDataDto } = action.response;

            const getJobDetails = ({ companyName, companyLogoUrl, jobTitle }: FormStructureDto) => ({
                jobTitle,
                companyLogoUrl,
                companyName: companyName || ((atsiDataDto || {}).jobDetails || {}).companyName
            });
            return processSurveyStructure(surveyStructure, state, getJobDetails);
        case LOGO_ERROR_ACTION:
            return {
                ...state,
                companyLogoUrl: null
            };
        default:
            return state;
    }
};

const refreshStructureIdReducer: Reducer<number | null, PostponeStructureRefreshAction> = (state = null, action) => {
    switch (action.type) {
        case POSTPONE_STRUCTURE_REFRESH_ACTION:
            return action.id;
        default:
            return state;
    }
};

const structureReducer: Reducer<string[], FormStructuringActions> = (state = [], action) => {
    switch (action.type) {
        case POST_ACTION_SUCCESS_ACTION:
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_UPLOAD_SUCCESS_ACTION:
        case GET_STRUCTURE_SUCCESS_ACTION:
            return computeStructure(action.response, state);
        case FETCH_FORM_SUCCESS_ACTION:
            return computeStructure(action.response.surveyStructure, state);
        default:
            return state;
    }
};

const navigationReducer: Reducer<string[], FormStructuringActions> = (state = [], action) => {
    switch (action.type) {
        case POST_ACTION_SUCCESS_ACTION:
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_UPLOAD_SUCCESS_ACTION:
        case GET_STRUCTURE_SUCCESS_ACTION:
            return computeNavigation(action.response, state);
        case FETCH_FORM_SUCCESS_ACTION:
            return computeNavigation(action.response.surveyStructure, state);
        default:
            return state;
    }
};

const surveyTypeReducer: Reducer<string, FormStructuringActions> = (state = '', action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            const surveyStructure = action.response.surveyStructure || {};
            return surveyStructure.surveyType || '';
        case FORM_SUBMIT_SUCCESS_ACTION:
            return action.response.surveyType || '';
        default:
            return state;
    }
};

const refreshIdReducer: Reducer<number, SynchronizeFormValuesAction> = (state = -1, action) => {
    switch (action.type) {
        case SYNCHRONIZE_FORM_VALUES_ACTION:
            return action.id;
        default:
            return state;
    }
};

const isSubmitTriggeredReducer: Reducer<boolean, FormStructuringActions> = (state = false, action) => {
    switch (action.type) {
        case FORM_SUBMIT_SUCCESS_ACTION:
            return true;
        default:
            return state;
    }
};

const isBrowserValidationEnabledReducer: Reducer<boolean, FormStructuringActions> = (state = false, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            return (
                action.response.status === INITIALIZATION_STATE_INITIALIZED &&
                action.response.enabledBrowserValidation === true
            );
        default:
            return state;
    }
};

const fileUploadValidationIdReducer: Reducer<string, FormStructuringActions> = (state = '', action) => {
    switch (action.type) {
        case INVALIDATE_FILE_UPLOAD_ACTION:
            return action.id;
        case POST_ACTION_SUCCESS_ACTION:
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_UPLOAD_SUCCESS_ACTION:
            return '';
        default:
            return state;
    }
};

export default combineReducers({
    initRetriesLeft: initRetriesLeftReducer,
    areRequirementsNotMet: areRequirementsNotMetReducer,
    elements: elementsReducer,
    initializationStatus: initializationStatusReducer,
    isSessionExpired: isSessionExpiredReducer,
    isFetching: isFetchingReducer,
    isSuccessfullyApplied: isSuccessfullyAppliedReducer,
    isSubmitting: isSubmittingReducer,
    isValid: isValidReducer,
    redirectReason: redirectReasonReducer,
    params: paramsReducer,
    refreshId: refreshIdReducer,
    refreshStructureId: refreshStructureIdReducer,
    structure: structureReducer,
    navigation: navigationReducer,
    surveyType: surveyTypeReducer,
    wasAlreadyApplied: wasAlreadyAppliedReducer,
    isSubmitTriggered: isSubmitTriggeredReducer,
    isBrowserValidationEnabled: isBrowserValidationEnabledReducer,
    fileUploadValidationId: fileUploadValidationIdReducer,
    flowType: flowTypeReducer
});

// selectors

// TODO make `type` required after implementing ATSI-4073
export type BaseNode = Omit<IMirageNode, 'id' | typeof MIRAGE_NODE_REFERENCE> & {
    // id generated by getElementKey function
    id: string;
    type?: string;
};

export const getStructure = (state: RootState['form']): Array<BaseNode> =>
    state.structure.map((key: string) => {
        const data = state.elements[key] || {};
        return {
            id: key,
            isVisible: data.isVisible,
            tagName: data.tagName,
            type: data.type,
            xPath: data.xPath
        };
    });
export const getNavigation = (state: RootState['form']): Array<BaseNode> =>
    state.navigation.map((key: string) => {
        const data = state.elements[key] || {};
        return {
            id: key,
            isVisible: data.isVisible,
            tagName: data.tagName,
            type: data.type,
            xPath: data.xPath
        };
    });
export const getElement = <T extends Record<string, any>>(state: RootState['form'], key: string): T =>
    state.elements[key] || {};
export const getElementData = (state: RootState['form'], key: string): any => state.elements[key];
export const getFormStatuses = ({
    areRequirementsNotMet,
    isSessionExpired,
    isFetching,
    isSuccessfullyApplied,
    isSubmitting,
    isValid,
    initializationStatus,
    wasAlreadyApplied,
    initRetriesLeft,
    isBrowserValidationEnabled
}: RootState['form']) => ({
    areRequirementsNotMet,
    initializationStatus,
    isSessionExpired,
    isValid,
    isFetching,
    isSubmitting,
    isSuccessfullyApplied,
    wasAlreadyApplied,
    initRetriesLeft,
    isBrowserValidationEnabled
});
export const getRedirectReason = (state: RootState['form']): string => state.redirectReason;
export const getTextElementData = (
    state: RootState['form'],
    key: string
): { id: string; tagName: string; text: string; type: string } => {
    const data = getElement(state, key);
    return {
        id: key,
        tagName: data.tagName,
        text: data.text,
        type: data.type
    };
};

export const getJobData = (state: RootState['form']): ReturnType<typeof paramsReducer> => state.params;

const COMMON_INPUT_ATTRIBUTES = [
    'help',
    'id',
    'label',
    'name',
    'placeholder',
    'preFillLink',
    'status',
    'value',
    'xPath',
    'isRequired',
    'isDisabled',
    'type',
    'disabled',
    'customProps',
    'accept',
    'dataRole'
];

export const getFileFieldData = <T extends Record<string, any>>(state: RootState['form'], key: string): T =>
    [
        ...COMMON_INPUT_ATTRIBUTES,
        'blockRemoveBtn',
        'iframeSelector',
        'customLabel',
        'value',
        'removeButtonXPath',
        'buttonSelector',
        'isLoading'
    ].reduce((obj, prop) => ({ ...obj, [prop]: (state.elements[key] || {})[prop] }), {} as T);

const CUSTOM_PHONE_ATTRIBUTES = [
    'prefix',
    'localization',
    'defaultFormat',
    'isAlwaysDefaultFormat', // FIXME this looks like a bug IMiragePhoneWidget
    'masks', // FIXME this looks like a bug IMiragePhoneWidget
    'defaultFormat',
    'country',
    'countryCode'
];

export const getPhoneData = (state: RootState['form'], key: string): Record<string, any> =>
    [...COMMON_INPUT_ATTRIBUTES, ...CUSTOM_PHONE_ATTRIBUTES].reduce(
        (obj, prop) => ({ ...obj, [prop]: (state.elements[key] || {})[prop] }),
        {}
    );

export const getCommonFieldDataWithAdditionalFields = <T extends Record<string, any>>(
    state: RootState['form'],
    key: string,
    ...fields: string[]
): T =>
    [...COMMON_INPUT_ATTRIBUTES, ...fields].reduce(
        (obj, prop) => ({ ...obj, [prop]: (state.elements[key] || {})[prop] }),
        {} as T
    );

export const getCustomData = <T extends Record<string, any>>(
    state: RootState['form'],
    key: string,
    fields: string[] = []
): T => fields.reduce((obj, prop) => ({ ...obj, [prop]: (state.elements[key] || {})[prop] }), {} as T);

export const getTextFieldData = <T extends Record<string, any>>(state: RootState['form'], key: string): T =>
    [
        ...COMMON_INPUT_ATTRIBUTES,
        'postponeGetStructureTime',
        'tooltipMessage',
        'maxLength',
        'triggerFocus',
        'triggerBlur'
    ].reduce(
        (obj, prop) => ({
            ...obj,
            [prop]: ({ ...state.elements[key].customProps, ...state.elements[key] } || {})[prop]
        }),
        {} as T
    );

export const getCheckableFieldData = <T extends Record<string, any>>(state: RootState['form'], key: string): T =>
    [...COMMON_INPUT_ATTRIBUTES, 'checked', 'useCustomAction', 'postponeGetStructureTime'].reduce(
        (obj, prop) => ({
            ...obj,
            [prop]: ({ ...state.elements[key].customProps, ...state.elements[key] } || {})[prop]
        }),
        {} as T
    );

export const getMultiFieldData = <T extends Record<string, any>>(state: RootState['form'], key: string): T =>
    [...COMMON_INPUT_ATTRIBUTES, 'checked', 'options'].reduce(
        (obj, prop) => ({ ...obj, [prop]: (state.elements[key] || {})[prop] }),
        {} as T
    );

export const getSelectFieldData = <T extends Record<string, any>>(state: RootState['form'], key: string): T =>
    [
        ...COMMON_INPUT_ATTRIBUTES,
        'options',
        'postponeGetStructureTime',
        'tooltipMessage',
        'multiple',
        'triggerBlur',
        'triggerFocus'
    ].reduce((obj, prop) => ({ ...obj, [prop]: (state.elements[key] || {})[prop] }), {} as T);

export const getSubmitButtonData = <
    T extends Record<
        'isLoading' | 'isDisabled' | 'xPath' | 'value' | 'role' | 'customClassName' | 'customLabel' | 'timeout',
        any
    >
>(
    state: RootState['form'],
    key: string
): T =>
    ['isLoading', 'isDisabled', 'xPath', 'value', 'role', 'customClassName', 'customLabel', 'timeout'].reduce(
        (obj, prop) => ({
            ...obj,
            [prop]: ({ ...state.elements[key].customProps, ...state.elements[key] } || {})[prop]
        }),
        {} as T
    );

export const getXPath = (state: RootState['form'], key: string): string | null =>
    state.elements[key] ? state.elements[key].xPath : null;

export const hasRequired = (state: RootState['form']): boolean =>
    state.structure.some((id: string) => (state.elements[id] || {}).isRequired);

export const getSurveyType = (state: RootState['form']): string => state.surveyType || SurveyType.APPLICATION_FOR_A_JOB;

export const getRefreshId = (state: RootState['form']): number => state.refreshId;

export const hasBrowserValidation = (state: RootState['form']): boolean =>
    state.isSubmitTriggered && state.isBrowserValidationEnabled;

export const hasFileUploadValidation = (state: RootState['form'], elementId: string): boolean =>
    !!state.fileUploadValidationId && state.fileUploadValidationId === elementId;
