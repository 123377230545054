import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { connect } from 'react-redux';

import { sendLog } from '../../common/actions/log';
import { job } from '../../mirage/reducers';
import { LOG_TYPE } from '../constants/log';
import { session } from '../reducers';

export class ErrorBoundary extends React.Component {
    componentDidCatch(error, componentStack) {
        this.props.sendLog({ error, componentStack });
    }

    render() {
        return this.props.children;
    }
}

export default connect(
    state => ({
        ...job.getJobLoggingContext(state),
        conversationId: session.getConversationUuid(state),
        jobBoardId: session.getJobBoardId(state)
    }),
    { sendLog },
    ({ atsId, clientId, conversationId, integrationId, jobBoardId, jobId }, { sendLog }, ownProps) => ({
        sendLog: ({ error, componentStack }) => {
            datadogRum.addError(error, componentStack);

            return sendLog(conversationId, LOG_TYPE.FRONTEND_ERROR_OCCURRED, {
                atsId,
                clientId,
                integrationId,
                jobBoardId,
                jobId,
                error: error.toString(),
                info: componentStack.componentStack
            });
        },
        ...ownProps
    })
)(ErrorBoundary);
