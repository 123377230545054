import React, { Fragment } from 'react';
import Anchor from './Anchor';
import { ACTION_TYPE } from '../../constants/form';

const justifyStyle = { textAlign: 'justify' };

const renderTextElem = (elem, index) => {
    switch ((elem.type || '').toUpperCase()) {
        case 'A':
            return (
                <Anchor href={elem.href} key={index} style={justifyStyle} stayOnPage={elem.stayOnPage}>
                    <RenderTextArray text={elem.text} />
                </Anchor>
            );
        case 'CLICKABLE_ANCHOR':
            return (
                <Anchor
                    actionType={ACTION_TYPE.CLICK}
                    href="#"
                    xPath={elem.xPath}
                    key={index}
                    style={justifyStyle}
                    stayOnPage={elem.stayOnPage}
                >
                    <RenderTextArray text={elem.text} />
                </Anchor>
            );
        case 'H1':
            return (
                <h1 key={index}>
                    <RenderTextArray text={elem.text} />
                </h1>
            );
        case 'H2':
            return (
                <h2 key={index}>
                    <RenderTextArray text={elem.text} />
                </h2>
            );
        case 'H3':
            return (
                <h3 key={index}>
                    <RenderTextArray text={elem.text} />
                </h3>
            );
        case 'H4':
            return (
                <h4 key={index}>
                    <RenderTextArray text={elem.text} />
                </h4>
            );
        case 'H5':
            return (
                <h5 key={index}>
                    <RenderTextArray text={elem.text} />
                </h5>
            );
        case 'H6':
            return (
                <h6 key={index}>
                    <RenderTextArray text={elem.text} />
                </h6>
            );
        case 'DIV':
            return (
                <div key={index}>
                    <RenderTextArray text={elem.text} />
                </div>
            );
        case 'SPAN':
            return (
                <span key={index}>
                    <RenderTextArray text={elem.text} />
                </span>
            );
        case 'P':
            return (
                <p key={index} style={justifyStyle}>
                    <RenderTextArray text={elem.text} />
                </p>
            );
        case 'I':
            return (
                <i key={index}>
                    <RenderTextArray text={elem.text} />
                </i>
            );
        case 'EM':
            return (
                <em key={index}>
                    <RenderTextArray text={elem.text} />
                </em>
            );
        case 'B':
            return (
                <b key={index} style={justifyStyle}>
                    <RenderTextArray text={elem.text} />
                </b>
            );
        case 'STRONG':
            return (
                <strong key={index}>
                    <RenderTextArray text={elem.text} />
                </strong>
            );
        case 'UL':
            return (
                <ul key={index}>
                    <RenderTextArray text={elem.text} />
                </ul>
            );
        case 'OL':
            return (
                <ol key={index}>
                    <RenderTextArray text={elem.text} />
                </ol>
            );
        case 'LI':
            return (
                <li key={index}>
                    <RenderTextArray text={elem.text} />
                </li>
            );
        case 'BR':
            return <br key={index} />;
        default:
            return <RenderTextArray key={index} text={elem.text} />;
    }
};

const RenderTextArray = ({ text }) => (
    <Fragment>{text && Array.isArray(text) ? text.map(renderTextElem) : `${text} `}</Fragment>
);

RenderTextArray.displayName = 'RenderTextArray';

export default RenderTextArray;
