import React, { FC } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '../../../app/store';
import { session } from '../../reducers';

interface IProps {
    name?: string;
    url?: string;
}

export const Logo: FC<IProps> = ({ name, url }) => (
    <div className="plain-header">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <a className="site-logo-header" title={name} href={url} />
                </div>
            </div>
        </div>
    </div>
);

const mapStateToProps = (state: RootState) => ({ ...session.getJobBoardData(state) });

export default connect(mapStateToProps)(Logo);
