import { connect } from 'react-redux';

import { form } from '../../../../../common/reducers';
import { formStructurePollingStart, formStructurePollingStop } from '../../../../state/form/action';
import { DispatchProps, LoadingOverlayWidgetView, OwnProps, StateProps } from './LoadingOverlayWidgetView';
import type { RootState } from '../../../../../app/store';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getElement(state, id)
});

const mapDispatchToProps: DispatchProps = {
    startPolling: formStructurePollingStart,
    stopPolling: formStructurePollingStop
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingOverlayWidgetView);
