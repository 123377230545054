import { APPLIED_FORM_STATE } from '../../common/constants/form';

export type PersistedSession = {
    status: string;
};

export const hasUserSuccessfullyApplied = (conversationId: string): boolean => {
    const state = readSessionState(conversationId);
    if (state != null) {
        return state.status === APPLIED_FORM_STATE;
    }
    return false;
};

export const readSessionState = (conversationId: string): PersistedSession | null => {
    try {
        const state = window.sessionStorage.getItem(conversationId);
        return state ? JSON.parse(state) : state;
    } catch (e) {
        return null;
    }
};

export const writeSessionState = (conversationId: string, state: PersistedSession): void => {
    try {
        window.sessionStorage.setItem(conversationId, JSON.stringify(state));
    } catch (e) {
        console.error(e);
    }
};
