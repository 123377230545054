import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMirageCaptchaWidget } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React from 'react';
import { Button, FormControl, FormLabel } from 'react-bootstrap';

import { createPostActionWithType } from '../../../../../common/actions/form';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import FormGroup from '../../FormGroup';
import HelpText from '../../fields/HelpText';
import TranslatedText from '../../../../../common/components/ui/TranslatedText';
import { REFRESH_CAPTCHA_BTN_TEXT } from '../../../../../common/constants/stringKeys';

export type OwnProps = { id: string };

export type ComponentProps = IMirageCaptchaWidget;

export type StateProps = {
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

export type DispatchProps = {
    createPostActionWithType: typeof createPostActionWithType;
};

export type Props = Omit<StateProps, 'conversationUuid' | 'xPath' | 'elementXPath' | 'clickXPath'> & {
    imageSrc: string;
    shouldRenderRefreshButton: boolean;
    postAction: (value: string) => void;
    click: () => void;
};

export const CaptchaWidgetView: React.FC<Props> = ({
    label,
    value,
    id = 'captcha',
    imageSrc,
    shouldRenderRefreshButton = false,
    isRequired,
    help,
    status,
    postAction,
    click
}: Props) => {
    return (
        <div className="row captcha">
            <div className="col-xs-12 input-row">
                <FormGroup className={classNames({ required: isRequired })}>
                    {label && (
                        <FormLabel className="form-label w-100" htmlFor={id}>
                            <RenderTextArray text={label} />
                        </FormLabel>
                    )}
                    {shouldRenderRefreshButton && (
                        <Button variant="primary" className="mb-2" onClick={() => click()}>
                            <FontAwesomeIcon icon="redo" size="sm" className="me-1" />
                            <TranslatedText textKey={REFRESH_CAPTCHA_BTN_TEXT} />
                        </Button>
                    )}
                    <div className="mb-3">
                        <img src={`${imageSrc}?timestamp=${Date.now()}`} alt="Captcha" />
                    </div>
                    <FormControl
                        required={isRequired}
                        as={'input'}
                        onChange={event => postAction(event.target.value)}
                        defaultValue={value}
                        name="captcha_value"
                        id={id}
                    />
                    {help && <HelpText help={help} status={status} id={`${id}_HelpText`} />}
                </FormGroup>
            </div>
        </div>
    );
};

CaptchaWidgetView.displayName = 'CaptchaWidgetView';
