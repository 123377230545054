import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './LoadingOverlay.css';

export type LoadingOverlayProps = {
    message?: string | undefined;
};

const LoadingOverlay = ({ message }: LoadingOverlayProps) => (
    <div className="loading-overlay">
        <div className="overlay-text" style={{ textAlign: 'center' }}>
            {message && <p>{message}</p>}
            <FontAwesomeIcon icon="spinner" spin />
        </div>
    </div>
);

export default LoadingOverlay;
