import { datadogRum } from '@datadog/browser-rum';
import { Middleware } from 'redux';

import { sendLog } from '../../common/actions/log';
import {
    FETCH_FORM_FAILURE_ACTION,
    FORM_SUBMIT_FAILURE_ACTION,
    GET_STRUCTURE_FAILURE_ACTION,
    POST_ACTION_FAILURE_ACTION,
    POST_UPLOAD_FAILURE_ACTION
} from '../../common/constants/form';
import { LOG_TYPE } from '../../common/constants/log';
import { session } from '../../common/reducers';
import { job } from '../reducers';

export const responseFailureLogMiddleware: Middleware = store => next => action => {
    try {
        const { type, request = {}, cause, error, status } = action;
        const { inputXPath, actionType, buttonRole } = request;
        if (
            [
                GET_STRUCTURE_FAILURE_ACTION,
                FETCH_FORM_FAILURE_ACTION,
                POST_ACTION_FAILURE_ACTION,
                POST_UPLOAD_FAILURE_ACTION,
                FORM_SUBMIT_FAILURE_ACTION
            ].includes(type)
        ) {
            datadogRum.addError(error, {
                actionType,
                buttonRole,
                cause,
                inputXPath,
                status,
                type
            });

            const state = store.getState();
            sendLog(session.getConversationUuid(state), LOG_TYPE.NO_RESPONSE_FROM_SERVER, {
                ...job.getJobLoggingContext(state),
                jobBoardId: session.getJobBoardId(state),
                info: {
                    actionType,
                    buttonRole,
                    error: typeof error === 'object' ? error.toString() : undefined,
                    inputXPath,
                    type,
                    status
                },
                cause
            });
        }
    } finally {
        next(action);
    }
};
