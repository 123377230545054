import { IMirageLabelNode } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React from 'react';
import { FormLabel } from 'react-bootstrap';

import RenderTextArray from '../../../../common/components/ui/RenderTextArray';
import FormGroup from '../FormGroup';

export type OwnProps = { id: string };

type ComponentProps = IMirageLabelNode;

export type StateProps = OwnProps & Pick<ComponentProps, 'text' | 'isRequired'>;

type Props = StateProps & {
    // FIXME This props probably shouldn't be even here (it's not being picked from state), added in fca289d
    status?: unknown;
};

export const LabelElement: React.FC<Props> = ({ text, id, isRequired = false, status = null }: Props) => {
    if (!text) {
        return null;
    }
    return (
        <div className="row">
            <div className="col-xs-12 input-row w-100">
                <FormGroup
                    className={classNames('mb-0', { required: isRequired })}
                    aria-invalid={!!status}
                    controlId={id}
                >
                    <FormLabel className="form-label">
                        <RenderTextArray text={text} />
                    </FormLabel>
                </FormGroup>
            </div>
        </div>
    );
};

LabelElement.displayName = 'LabelElement';
