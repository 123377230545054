import React, { FC } from 'react';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMirageAction } from '@stepstone/ats-scrapers-core/types';

import { ClickAndWaitForElementWidget } from '../ClickAndWaitForElementWidget';
import { SummaryTableProps } from './SummaryTableWidgetView';

const actionStyle = { marginLeft: '10px ', padding: '1px 10px' };

type ActionProps = {
    handleClick: () => void;
    addOrRemoveElement: () => void;
    selectorToRemoveElement: string | undefined;
} & IMirageAction &
    Pick<SummaryTableProps, 'label' | 'help' | 'isRequired' | 'status'>;

export const Action: FC<ActionProps> = ({ handleClick, ...props }, index: number) => {
    const { selectorToRemoveElement } = props;
    if ('name' in props) {
        return (
            <div>
                <FontAwesomeIcon
                    icon={props.name}
                    className={`text-${props.style || 'primary'} delete-icon-summary-table`}
                    size="2x"
                    onClick={handleClick}
                    key={index}
                />
                {props.label && <span>{props.label}</span>}
            </div>
        );
    } else if (selectorToRemoveElement !== undefined) {
        // @ts-expect-error Missing props required by ClickAndWaitForElementWidget
        return <ClickAndWaitForElementWidget {...props} />;
    } else if ('text' in props) {
        return (
            <Button variant={'primary'} size="sm" key={index} style={actionStyle} onClick={handleClick}>
                {props.text}
            </Button>
        );
    } else {
        return null;
    }
};

Action.displayName = 'SummaryTableAction';
