export const EXPIRE_PAGE_TITLE = 'expirePageTitle';
export const EXPIRE_PAGE_MESSAGE = 'expirePageMessage';
export const REDIRECT_PAGE_TITLE = 'redirectPageTitle';
export const REDIRECT_PAGE_MESSAGE = 'redirectPageMessage';
export const THANK_YOU_PAGE_TITLE = 'thankYouPageTitle';
export const THANK_YOU_PAGE_MESSAGE = 'thankYouPageMessage';
export const REQUIREMENTS_NOT_MET_PAGE_TITLE = 'requirementsNotMetPageTitle';
export const REQUIREMENTS_NOT_MET_PAGE_MESSAGE = 'requirementsNotMetPageMessage';
export const ALREADY_APPLIED_PAGE_TITLE = 'alreadyAppliedPageTitle';
export const ALREADY_APPLIED_PAGE_MESSAGE = 'alreadyAppliedPageMessage';
export const WRONG_FORM_PAGE_TITLE = 'wrongFormPageTitle';
export const WRONG_FORM_PAGE_MESSAGE = 'wrongFormPageMessage';
export const LOADING_PAGE_TITLE = 'loadingPageTitle';
export const PREFILLING_MESSAGE = 'prefillingMessage';
export const CREATING_MESSAGE = 'creatingMessage';
export const FETCHING_MESSAGE = 'fetchingMessage';
export const TERMS_MESSAGE = 'termsMessage';
export const ACCOUNT_CREATION_MESSAGE = 'accountCreationMessage';
export const REQUIRED_FIELDS_MESSAGE = 'requiredFieldsMessage';
export const COMPANY_MESSAGE = 'companyMessage';
export const REGISTER_BUTTON_TEXT = 'registerButtonText';
export const APPLY_BUTTON_TEXT = 'applyButtonText';
export const BACK_BUTTON_TEXT = 'backButtonText';
export const NEXT_BUTTON_TEXT = 'nextButtonText';
export const NO_FILE_TEXT = 'noFileText';
export const BROWSE_TEXT = 'browseText';
export const DEFAULT_COMPANY_NAME = 'defaultCompanyName';
export const COLLAPSE_BTN_SHOW_TEXT = 'collapseBtnShowText';
export const COLLAPSE_BTN_HIDE_TEXT = 'collapseBtnHideText';
export const UPLOADING_LABEL_TEXT = 'uploadingLabelText';
export const UPLOADED_LABEL_TEXT = 'uploadedLabelText';
export const OK = 'ok';
export const CANCEL = 'cancel';
export const FILE_TOO_LARGE_MESSAGE = 'fileTooLargeMessage';
export const REFRESH_CAPTCHA_BTN_TEXT = 'refreshCaptchaBtnText';

export type TranslationKeys =
    | typeof EXPIRE_PAGE_TITLE
    | typeof EXPIRE_PAGE_MESSAGE
    | typeof REDIRECT_PAGE_TITLE
    | typeof REDIRECT_PAGE_MESSAGE
    | typeof THANK_YOU_PAGE_TITLE
    | typeof THANK_YOU_PAGE_MESSAGE
    | typeof REQUIREMENTS_NOT_MET_PAGE_TITLE
    | typeof REQUIREMENTS_NOT_MET_PAGE_MESSAGE
    | typeof ALREADY_APPLIED_PAGE_TITLE
    | typeof ALREADY_APPLIED_PAGE_MESSAGE
    | typeof WRONG_FORM_PAGE_TITLE
    | typeof WRONG_FORM_PAGE_MESSAGE
    | typeof LOADING_PAGE_TITLE
    | typeof PREFILLING_MESSAGE
    | typeof CREATING_MESSAGE
    | typeof FETCHING_MESSAGE
    | typeof TERMS_MESSAGE
    | typeof ACCOUNT_CREATION_MESSAGE
    | typeof REQUIRED_FIELDS_MESSAGE
    | typeof COMPANY_MESSAGE
    | typeof REGISTER_BUTTON_TEXT
    | typeof APPLY_BUTTON_TEXT
    | typeof BACK_BUTTON_TEXT
    | typeof NEXT_BUTTON_TEXT
    | typeof NO_FILE_TEXT
    | typeof BROWSE_TEXT
    | typeof DEFAULT_COMPANY_NAME
    | typeof COLLAPSE_BTN_SHOW_TEXT
    | typeof COLLAPSE_BTN_HIDE_TEXT
    | typeof UPLOADING_LABEL_TEXT
    | typeof UPLOADED_LABEL_TEXT
    | typeof OK
    | typeof CANCEL
    | typeof FILE_TOO_LARGE_MESSAGE
    | typeof REFRESH_CAPTCHA_BTN_TEXT;

export type Translations = Record<TranslationKeys, string>;
