export const SEND_LOG = 'SEND_LOG';
export const UNKNOWN_CONVERSATION_UUID = 'UNKNOWN_CONVERSATION_UUID';

export const LOG_TYPE = {
    FORM_RENDERED: 'FORM_RENDERED',
    FRONTEND_ATS_REDIRECT: 'FRONTEND_ATS_REDIRECT',
    FRONTEND_ERROR_OCCURRED: 'FRONTEND_ERROR_OCCURRED',
    URL_PARSE_ERROR: 'URL_PARSE_ERROR',
    NO_RESPONSE_FROM_SERVER: 'NO_RESPONSE_FROM_SERVER',
    POST_APPLICATION_URL_PARSE_ERROR: 'POST_APPLICATION_URL_PARSE_ERROR',
    FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE'
} as const;

export const REDIRECT_REASONS = {
    // May occur during application initialization when the app didn't get a proper response from the back-end in a certain amount of time (for example slow ATS, network delays, or other issues)
    INIT_TIMEOUT: 'INIT_TIMEOUT',
    // May occur during application initialization when the app didn't get a proper response from the back-end, thats general error it might be divided in to following groups:
    INITIALIZATION_ERROR: 'INITIALIZATION_ERROR',
    // Groups all external ats errors despite the reason under one group
    ATS_EXTERNAL_ERROR: 'ATS_EXTERNAL_ERROR',
    // during application initialization the app didn't get a valid structure or structure was empty (no form elements nor navigation)
    INITIALIZATION_NO_STRUCTURE_ERROR: 'INITIALIZATION_NO_STRUCTURE_ERROR',
    // during application initialization the app received error status from back-end
    INITIALIZATION_STATE_ERROR: 'INITIALIZATION_STATE_ERROR',
    // during application initialization the app didn't get a valid structure - no form elements
    INITIALIZATION_NO_ELEMENTS_ERROR: 'INITIALIZATION_NO_ELEMENTS_ERROR',
    // during application initialization the app didn't get a valid structure - no navigation elements
    INITIALIZATION_NO_NAVIGATION_ERROR: 'INITIALIZATION_NO_NAVIGATION_ERROR',
    // May occur after app initialization, and user started interaction with form, it may occur during form filling or page navigation (for example app received an invalid response from back-end)
    INTERMEDIATE_STAGE_ERROR: 'INTERMEDIATE_STAGE_ERROR',
    INTERMEDIATE_STAGE_ERROR_POST_ACTION: 'INTERMEDIATE_STAGE_ERROR_POST_ACTION',
    INTERMEDIATE_STAGE_ERROR_POST_UPLOAD: 'INTERMEDIATE_STAGE_ERROR_POST_UPLOAD',
    INTERMEDIATE_STAGE_ERROR_FORM_SUBMIT: 'INTERMEDIATE_STAGE_ERROR_FORM_SUBMIT',
    INTERMEDIATE_STAGE_ERROR_GET_STRUCTURE: 'INTERMEDIATE_STAGE_ERROR_GET_STRUCTURE',
    // May occur during application initialization when we try to open an expired offer that was not blacklisted yet
    JOB_EXPIRED: 'JOB_EXPIRED',
    // May occur when the user is not interacting with a form at all (for example opening offer on the browser tab and leaving it as it is)
    NO_ACTION_FROM_USER: 'NO_ACTION_FROM_USER',
    // May occur when the user is not interacting with a form at all and app is not visible to the user (hidden tab, minimised window, hidden window on mobile)
    NO_ACTION_FROM_USER_APP_IN_BACKGROUND: 'NO_ACTION_FROM_USER_APP_IN_BACKGROUND',
    // May occur when the user is returning to form after a while (for example: opening closed browser with form opened)
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    // May occur when session is closed unexpectedly on back-end side and we were not able to fetch any information about it (for example back-end issues)
    SESSION_NOT_AVAILABLE: 'SESSION_NOT_AVAILABLE',
    // May occur when the user is submitting the form, but the application was not able to respond in a certain amount of time (for example slow ATS)
    SUBMIT_TIMEOUT: 'SUBMIT_TIMEOUT'
} as const;

export const REDIRECT_REASON_KEY = 'cause';

export const ACTION_FAILURE_REASON = Object.freeze({
    NETWORK_ERROR: 'NETWORK_ERROR' as const,
    MALFORMED_RESPONSE: 'MALFORMED_RESPONSE' as const,
    RESPONSE_PROCESSING_ERROR: 'RESPONSE_PROCESSING_ERROR' as const
});

export const ERROR_CAUSE = {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL'
} as const;
