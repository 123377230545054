import {
    ACCOUNT_CREATION_MESSAGE,
    ALREADY_APPLIED_PAGE_MESSAGE,
    ALREADY_APPLIED_PAGE_TITLE,
    APPLY_BUTTON_TEXT,
    BACK_BUTTON_TEXT,
    BROWSE_TEXT,
    CANCEL,
    COLLAPSE_BTN_HIDE_TEXT,
    COLLAPSE_BTN_SHOW_TEXT,
    COMPANY_MESSAGE,
    CREATING_MESSAGE,
    DEFAULT_COMPANY_NAME,
    EXPIRE_PAGE_MESSAGE,
    EXPIRE_PAGE_TITLE,
    FETCHING_MESSAGE,
    LOADING_PAGE_TITLE,
    NEXT_BUTTON_TEXT,
    NO_FILE_TEXT,
    OK,
    PREFILLING_MESSAGE,
    REDIRECT_PAGE_MESSAGE,
    REDIRECT_PAGE_TITLE,
    REGISTER_BUTTON_TEXT,
    REQUIRED_FIELDS_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_TITLE,
    TERMS_MESSAGE,
    THANK_YOU_PAGE_MESSAGE,
    THANK_YOU_PAGE_TITLE,
    FILE_TOO_LARGE_MESSAGE,
    UPLOADED_LABEL_TEXT,
    UPLOADING_LABEL_TEXT,
    WRONG_FORM_PAGE_MESSAGE,
    WRONG_FORM_PAGE_TITLE,
    Translations,
    REFRESH_CAPTCHA_BTN_TEXT
} from '../stringKeys';

export const de: Translations = {
    [EXPIRE_PAGE_TITLE]: 'Sitzung abgelaufen',
    [EXPIRE_PAGE_MESSAGE]: 'Ihre Sitzung ist abgelaufen. Versuchen wir es noch einmal. Viel Glück!',
    [REDIRECT_PAGE_TITLE]: 'Weiterleitung zur Recruiter-Seite',
    [REDIRECT_PAGE_MESSAGE]: 'Sie werden zur Recruiter-Seite weitergeleitet. Viel Glück!',
    [THANK_YOU_PAGE_TITLE]: 'Bewerbung eingereicht',
    [THANK_YOU_PAGE_MESSAGE]: 'Ihre Bewerbung wurde erfolgreich eingereicht. Viel Glück!',
    [WRONG_FORM_PAGE_TITLE]: 'Form ist ungültig',
    [WRONG_FORM_PAGE_MESSAGE]: 'Bewerbungsform ist ungültig. Bitte versuche es später wieder.',
    [LOADING_PAGE_TITLE]: 'Daten laden',
    [PREFILLING_MESSAGE]: 'Vorausgefüllte Form...',
    [CREATING_MESSAGE]: 'Daten laden...',
    [FETCHING_MESSAGE]: 'Daten werden gelesen. Bitte warten einen Moment.',
    [TERMS_MESSAGE]:
        'Durch das Klicken auf Bewerben, stimmen Sie zu, dass Ihre Bewerbung an {companyName} versendet wird und Sie stimmen den Geschäftsbedingungen und Datenschutzrichtlinie von {companyName} zu.',
    [ACCOUNT_CREATION_MESSAGE]:
        'Durch das Registrieren, stimmen Sie zu, dass Ihre Bewerbung an {companyName} versendet wird und Sie stimmen den Geschäftsbedingungen und Datenschutzrichtlinie von {companyName}zu.',
    [REQUIRED_FIELDS_MESSAGE]: 'Erforderte Dateien sind markiert mit',
    [COMPANY_MESSAGE]:
        'Sie bewerben sich für einen Job bei {companyName} für Sie zur Verfügung gestellt von {jobBoardName}',
    [REGISTER_BUTTON_TEXT]: 'Registrieren und fortsetzen',
    [APPLY_BUTTON_TEXT]: 'Bewerbung absenden',
    [BACK_BUTTON_TEXT]: 'Zurück',
    [NEXT_BUTTON_TEXT]: 'Fortsetzen',
    [COLLAPSE_BTN_SHOW_TEXT]: 'Text einblenden',
    [COLLAPSE_BTN_HIDE_TEXT]: 'Text ausblenden',
    [NO_FILE_TEXT]: 'Keine Datei ausgewählt.',
    [BROWSE_TEXT]: 'Datei auswählen',
    [DEFAULT_COMPANY_NAME]: 'Arbeitgeber',
    [REQUIREMENTS_NOT_MET_PAGE_TITLE]: 'Voraussetzungen sind nicht erfüllt',
    [REQUIREMENTS_NOT_MET_PAGE_MESSAGE]: 'Entschuldigung, aber Sie haben versiechede Voraussetzungen nicht erfüllt',
    [ALREADY_APPLIED_PAGE_TITLE]: 'Mehrmalige Bewerbung verboten',
    [ALREADY_APPLIED_PAGE_MESSAGE]: 'Enstschuldingung, aber Sie haben sich bereits einmal auf diese Stelle beworben',
    [UPLOADING_LABEL_TEXT]: 'Dateien Hochladen',
    [UPLOADED_LABEL_TEXT]: 'Datei Löschen',
    [OK]: 'Ok',
    [CANCEL]: 'Stornieren',
    [FILE_TOO_LARGE_MESSAGE]:
        'Es können nur Dateien mit einer Größe von maximal {fileSize} hochgeladen werden. Bitte verwende eine kleinere Datei.',
    [REFRESH_CAPTCHA_BTN_TEXT]: 'Captcha-Code aktualisieren'
};
