import React from 'react';

const CatererFooter = () => (
    <div className="copyright" style={{ margin: '50px 0' }}>
        <div className="container">
            <div className="row">
                <div className="col-xs-12 content" style={{ textAlign: 'center', lineHeight: '50px' }}>
                    <div className="footer-container">
                        <ul className="list-inline footer">
                            <li className="first">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.caterer.com/about-us">
                                    About us
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.caterer.com/contact-us">
                                    Contact us
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.caterer.com/cookies">
                                    Cookies
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.caterer.com/terms-and-conditions"
                                >
                                    Terms and conditions
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.caterer.com/privacy-policy"
                                >
                                    Privacy policy
                                </a>
                            </li>
                        </ul>
                    </div>
                    <span>© Copyright and database rights Totaljobs Group Ltd 2018</span>
                    <br />
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Totaljobs Group"
                        href="https://www.totaljobsgroup.com"
                        className="tjglogo"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        &nbsp;
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default CatererFooter;
