import React from 'react';
import { connect } from 'react-redux';
import { TranslationKeys } from '../../constants/stringKeys';

import { language } from '../../reducers';
import type { RootState } from '../../../app/store';

const TranslatedTemplateText: React.FC<{ text: string }> = ({ text }) => <React.Fragment>{text || ''}</React.Fragment>;

TranslatedTemplateText.displayName = 'TranslatedTemplateText';

type OwnProps = { textKey: TranslationKeys; replaces: Record<string, string> };

const mapStateToProps = (state: RootState, { textKey, replaces }: OwnProps) => ({
    text: language.getTranslatedTemplateText(state, textKey, replaces)
});

export default connect(mapStateToProps)(TranslatedTemplateText);
