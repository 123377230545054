import { WidgetType } from '@stepstone/ats-scrapers-core/types';
import React from 'react';

import HiddenSelect from '../hiddenSelect/HiddenSelect';
import AccordionWidget from './widgets/accordionWidget/AccordionWidget';
import ApplicationProgress from './widgets/ApplicationProgress';
import AsyncFileInput from './widgets/AsyncFileInput';
import AsyncSelectAutosuggestWidget from './widgets/asyncSelectAutosuggestWidget/AsyncSelectAutosuggestWidget';
import AsyncSingleFileInputWithDelete from './widgets/asyncSingleFileInputWithDeleteWidget/AsyncSingleFileInputWithDelete';
import CaptchaWidget from './widgets/captchaWidget/CaptchaWidget';
import ClickAndWaitForElementWidget from './widgets/ClickAndWaitForElementWidget';
import DatePickerWidget from './widgets/datePicker/DatePickerWidget';
import FileRemoveWidget from './widgets/fileRemove/FileRemoveWidget';
import IframeBasedFormWidget from './widgets/iframeBasedForm/IframeBasedFormWidget';
import InlineMultiField from './widgets/inlineMultiField/InlineMultiField';
import LoadingOverlayWidget from './widgets/loadingOverlay/LoadingOverlayWidget';
import PhoneWidget from './widgets/phone/PhoneWidget';
import PreClickedUploadWithValidationCheckWidget from './widgets/preClickedUploadWithValidationCheck/PreClickedUploadWithValidationCheckWidget';
import SummaryTable from './widgets/summaryTableWidget/SummaryTable';
import ValidationsGroup from './widgets/validationGroup/ValidationsGroup';

const WidgetElement = ({ type, id }) => {
    switch (type) {
        case WidgetType.APPLICATION_PROGRESS:
            return <ApplicationProgress id={id} />;
        case WidgetType.HIDDEN_SELECT:
            return <HiddenSelect id={id} />;
        case WidgetType.VALIDATIONS_GROUP:
            return <ValidationsGroup id={id} />;
        case WidgetType.ASYNC_FILE_UPLOAD:
            return <AsyncFileInput id={id} />;
        case WidgetType.ASYNC_SINGLE_FILE_UPLOAD_WITH_DELETE:
            return <AsyncSingleFileInputWithDelete id={id} />;
        case WidgetType.SUMMARY_TABLE:
            return <SummaryTable id={id} />;
        case WidgetType.REMOVE_FILE_WIDGET:
            return <FileRemoveWidget id={id} />;
        case WidgetType.INLINE_MULTI_FIELD_WIDGET:
            return <InlineMultiField id={id} />;
        case WidgetType.IFRAME_BASED_FORM_WIDGET:
            return <IframeBasedFormWidget id={id} />;
        case WidgetType.PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK_TYPE:
            return <PreClickedUploadWithValidationCheckWidget id={id} />;
        case WidgetType.PHONE_WIDGET:
            return <PhoneWidget id={id} />;
        case WidgetType.CLICK_AND_WAIT_FOR_ELEMENT_WIDGET:
            return <ClickAndWaitForElementWidget id={id} />;
        case WidgetType.DATE_PICKER_WIDGET:
            return <DatePickerWidget id={id} />;
        case WidgetType.ASYNC_SELECT_AUTOSUGGEST_WIDGET:
            return <AsyncSelectAutosuggestWidget id={id} />;
        case WidgetType.LOADING_OVERLAY_WIDGET:
            return <LoadingOverlayWidget id={id} />;
        case WidgetType.ACCORDION_WIDGET:
            return <AccordionWidget id={id} />;
        case WidgetType.CAPTCHA_WIDGET:
            return <CaptchaWidget id={id} />;
        default:
            return null;
    }
};

WidgetElement.displayName = 'WidgetElement';

export default WidgetElement;
