import React, { createRef } from 'react';
import { FormSelect } from 'react-bootstrap';
import classNames from 'classnames';

import { IMirageInputNode } from '@stepstone/ats-scrapers-core/types';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import LoadingOverlay from '../../../../../common/components/ui/LoadingOverlay';
import GenericInput from '../GenericInput';
import { connectInput } from '../utils';
import { form } from '../../../../../common/reducers';

type State = {
    isLoading: boolean;
};

export type SelectInputProps = {
    postAction: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    postActionWithType: (value: string, type: keyof typeof ACTION_TYPE) => void;
    refreshId: ReturnType<typeof form.getRefreshId>;
    defaultValue: string;
    onBlur?: () => void;
    onFocus?: () => void;
    fieldValue?: string;
    multiple?: boolean;
    isEnabledBrowserValidation: boolean;
} & IMirageInputNode;

const VALUES_SEPARATOR = '|';

const SELECTED_ATTRIBUTE = 'selected';

class SelectInput extends React.Component<SelectInputProps, State> {
    static displayName = 'SelectInput';

    readonly state: State = {
        isLoading: false
    };
    private readonly refToInput = createRef<HTMLSelectElement>();
    private timeoutId: number | undefined;

    componentDidUpdate(prevProps: SelectInputProps) {
        if (prevProps.refreshId !== this.props.refreshId && this.refToInput.current && this.props.defaultValue) {
            if (this.props.multiple) {
                this.selectMultipleOptions(this.refToInput.current, this.props.defaultValue?.split(VALUES_SEPARATOR));
            } else {
                this.refToInput.current.value = this.props.defaultValue;
            }
        }
    }

    private selectMultipleOptions(select: HTMLSelectElement, values: string[]) {
        const options = select.options;
        for (let i = 0; i < options.length; i++) {
            if (values.includes(options[i].value)) {
                options[i].setAttribute(SELECTED_ATTRIBUTE, SELECTED_ATTRIBUTE);
            } else {
                options[i].removeAttribute(SELECTED_ATTRIBUTE);
            }
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { postponeGetStructureTime, postAction } = this.props;
        postAction(event);
        if (postponeGetStructureTime) {
            this.setState({ isLoading: true });
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = window.setTimeout(() => this.setState({ isLoading: false }), postponeGetStructureTime);
        }
    };

    render() {
        const {
            options,
            postAction,
            help,
            label,
            status,
            isRequired,
            refreshId,
            isDisabled,
            customProps,
            postActionWithType,
            placeholder,
            id,
            type,
            defaultValue,
            dataRole,
            postponeGetStructureTime,
            onBlur,
            onFocus,
            tooltipMessage,
            fieldValue,
            isEnabledBrowserValidation,
            ...inputProps
        } = this.props;

        // todo ATSI-2474 - allow multiple values
        const mappedDefaultValue = inputProps.multiple ? defaultValue?.split(VALUES_SEPARATOR) : defaultValue;

        return (
            <GenericInput
                {...{ help, label, status, isRequired, id, type, tooltipMessage, isEnabledBrowserValidation }}
                ref={this.refToInput}
            >
                {this.state.isLoading && <LoadingOverlay />}
                <FormSelect
                    placeholder={placeholder ? placeholder : undefined}
                    onChange={this.handleChange}
                    ref={this.refToInput}
                    {...inputProps}
                    required={isRequired}
                    aria-required={isRequired}
                    aria-invalid={!!status}
                    aria-labelledby={id}
                    aria-describedby={`${id}_HelpText`}
                    defaultValue={mappedDefaultValue}
                    className={classNames(['form-control', { disabled: isDisabled }])}
                    disabled={isDisabled}
                    data-role={dataRole}
                    onBlur={onBlur}
                    onFocus={onFocus}
                >
                    {options &&
                        options.map((option, index) => (
                            <option key={`${option.value}/${index}`} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                </FormSelect>
            </GenericInput>
        );
    }
}

export default connectInput<SelectInputProps>(form.getSelectFieldData, SelectInput);
