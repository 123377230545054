import React, { FC } from 'react';

import CustomPage from '../ui/CustomPage';
import TranslatedText from '../ui/TranslatedText';
import { WRONG_FORM_PAGE_MESSAGE, WRONG_FORM_PAGE_TITLE } from '../../constants/stringKeys';

const WrongForm: FC = () => (
    <CustomPage
        icon={['far', 'file-excel']}
        message={<TranslatedText textKey={WRONG_FORM_PAGE_MESSAGE} />}
        title={<TranslatedText textKey={WRONG_FORM_PAGE_TITLE} />}
    />
);

export default WrongForm;
