import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUploadStatus } from '@stepstone/ats-scrapers-core/types';

import FormGroup from '../FormGroup';
import {
    createFileUploadAction,
    createGetCurrentStructureAction,
    createSynchronizeFormValuesAction,
    fileUploadErrorHandler
} from '../../../../common/actions/form';
import { BROWSE_TEXT, UPLOADING_LABEL_TEXT } from '../../../../common/constants/stringKeys';
import TranslatedText from '../../../../common/components/ui/TranslatedText';
import { session, form } from '../../../../common/reducers';
import RenderTextArray from '../../../../common/components/ui/RenderTextArray';
import HelpText, { STATUS } from '../fields/HelpText';
import { formStructurePollingStart, formStructurePollingStop } from '../../../state/form/action';
import { getCustomValidation, isEmptyValidation } from '../../../../common/fileUploadUtils';

const LABELS = {
    UPLOADING: UPLOADING_LABEL_TEXT,
    BROWSE: BROWSE_TEXT
};

class AsyncFileInput extends React.Component {
    static displayName = 'AsyncFileInput';

    constructor(props, context) {
        super(props, context);
        this.selectFile = this.selectFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentDidMount() {
        this.setWidgetStatusBasedOnProps();
    }

    componentDidUpdate(prevProps) {
        this.setWidgetStatusBasedOnProps(prevProps);
    }

    setWidgetStatusBasedOnProps(prevProps = {}) {
        this.startPollingBasedOnProps(prevProps);
        this.stopPollingBasedOnProps(prevProps);
    }

    startPollingBasedOnProps(prevProps) {
        if (this.shouldStartPolling(prevProps)) {
            this.startPolling();
        }
    }

    stopPollingBasedOnProps(prevProps) {
        if (this.shouldStopPolling(prevProps)) {
            this.stopPolling();
            this.props.syncValues();
        }
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    render() {
        const { isUploading, isRequired, accept, isUploaded, files, label, help, status, id, customValidation } =
            this.props;
        if (isUploaded && files) {
            return <div>{files && files.map(fileName => fileName)}</div>;
        } else {
            return (
                <div className="row">
                    <div className="col-xs-12 input-row">
                        <FormGroup
                            className={classNames({
                                required: !!label && isRequired,
                                'has-error': status === STATUS.ERROR || !isEmptyValidation(customValidation)
                            })}
                            controlId={id}
                        >
                            {label && (
                                <FormLabel>
                                    <RenderTextArray text={label} />
                                </FormLabel>
                            )}
                            <div>
                                <input
                                    ref={input => {
                                        this.input = input;
                                    }}
                                    type="file"
                                    accept={accept}
                                    onChange={this.uploadFile}
                                    style={{ display: 'none' }}
                                    aria-hidden="true"
                                />
                                <Button
                                    id={id}
                                    style={{ marginRight: '10px' }}
                                    onClick={this.selectFile}
                                    aria-required={isRequired}
                                    aria-invalid={status === FileUploadStatus.ERROR}
                                    aria-describedby={`${id}_HelpText`}
                                    disabled={isUploading}
                                    variant="primary"
                                >
                                    {isUploading && <FontAwesomeIcon icon="spinner" spin />}
                                    <TranslatedText textKey={isUploading ? LABELS.UPLOADING : LABELS.BROWSE} />
                                </Button>
                            </div>
                        </FormGroup>
                        <HelpText help={help} status={status} id={`${id}_HelpText`} />
                        <HelpText help={customValidation} status={STATUS.ERROR} id={`${id}_CustomValidation`} />
                    </div>
                </div>
            );
        }
    }

    selectFile() {
        this.input.value = '';
        this.input.click();
    }

    shouldStartPolling(prevProps) {
        if (prevProps.isUploading) {
            return false;
        }
        return this.props.isUploading;
    }

    shouldStopPolling(prevProps) {
        if (prevProps.isUploaded) {
            return false;
        }
        return this.props.isUploaded;
    }

    uploadFile(event) {
        this.startPolling();
        this.props.uploadFile(event);
    }

    startPolling() {
        this.props.startPolling();
    }

    stopPolling() {
        this.props.stopPolling();
    }
}

export default connect(
    (state, { id }) => {
        const fieldData = form.getFileFieldData(state, id);
        return {
            isUploading: fieldData.status === FileUploadStatus.UPLOADING,
            isUploaded: fieldData.status === FileUploadStatus.UPLOADED,
            isEmpty: fieldData.status === FileUploadStatus.EMPTY,
            ...fieldData,
            conversationUuid: session.getConversationUuid(state),
            customValidation: getCustomValidation(state, id),
            id
        };
    },
    {
        refreshForm: createGetCurrentStructureAction,
        createFileUploadAction,
        syncValues: createSynchronizeFormValuesAction,
        startPolling: formStructurePollingStart,
        stopPolling: formStructurePollingStop
    },
    ({ conversationUuid, xPath, id, ...props }, { createFileUploadAction, ...dispatchProps }) => ({
        ...props,
        ...dispatchProps,
        id,
        uploadFile: e =>
            createFileUploadAction(e.target.files, xPath, conversationUuid, id).catch(fileUploadErrorHandler)
    })
)(AsyncFileInput);
