import {
    ACCOUNT_CREATION_MESSAGE,
    ALREADY_APPLIED_PAGE_MESSAGE,
    ALREADY_APPLIED_PAGE_TITLE,
    APPLY_BUTTON_TEXT,
    BACK_BUTTON_TEXT,
    BROWSE_TEXT,
    COLLAPSE_BTN_HIDE_TEXT,
    COLLAPSE_BTN_SHOW_TEXT,
    COMPANY_MESSAGE,
    CREATING_MESSAGE,
    DEFAULT_COMPANY_NAME,
    EXPIRE_PAGE_MESSAGE,
    EXPIRE_PAGE_TITLE,
    FETCHING_MESSAGE,
    LOADING_PAGE_TITLE,
    NEXT_BUTTON_TEXT,
    NO_FILE_TEXT,
    PREFILLING_MESSAGE,
    REDIRECT_PAGE_MESSAGE,
    REDIRECT_PAGE_TITLE,
    REGISTER_BUTTON_TEXT,
    REQUIRED_FIELDS_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_TITLE,
    TERMS_MESSAGE,
    THANK_YOU_PAGE_MESSAGE,
    THANK_YOU_PAGE_TITLE,
    WRONG_FORM_PAGE_MESSAGE,
    WRONG_FORM_PAGE_TITLE,
    UPLOADING_LABEL_TEXT,
    UPLOADED_LABEL_TEXT,
    OK,
    CANCEL,
    FILE_TOO_LARGE_MESSAGE,
    Translations,
    REFRESH_CAPTCHA_BTN_TEXT
} from '../stringKeys';

export const en: Translations = {
    [EXPIRE_PAGE_TITLE]: 'Session expired',
    [EXPIRE_PAGE_MESSAGE]: "Your session expired. Let's try one more time. Good luck!",
    [REDIRECT_PAGE_TITLE]: 'Redirecting. Please wait...',
    [REDIRECT_PAGE_MESSAGE]: "We had some problems connecting to the recruiter's site.",
    [THANK_YOU_PAGE_TITLE]: 'Application Submitted',
    [THANK_YOU_PAGE_MESSAGE]: 'Your application has been submitted successfully. Good luck!',
    [WRONG_FORM_PAGE_TITLE]: 'Form is invalid',
    [WRONG_FORM_PAGE_MESSAGE]: 'Apply form is not valid. Try again later...',
    [LOADING_PAGE_TITLE]: 'Loading data',
    [PREFILLING_MESSAGE]: 'Pre-filling form...',
    [CREATING_MESSAGE]: 'Initializing session...',
    [FETCHING_MESSAGE]: 'Fetching data. Please wait a moment.',
    [TERMS_MESSAGE]:
        'By clicking apply, you consent to your application being transferred to {companyName} and you agree with {companyName}’s Terms & Conditions and Privacy Policy',
    [ACCOUNT_CREATION_MESSAGE]:
        'By registering your account, you consent to your application being transferred to {companyName} and you agree with {companyName}’s Terms & Conditions and' +
        ' Privacy Policy',
    [REQUIRED_FIELDS_MESSAGE]: 'Required fields are marked with',
    [COMPANY_MESSAGE]: 'You are applying for a job on {companyName}’s website brought to you by {jobBoardName}',
    [REGISTER_BUTTON_TEXT]: 'Register and continue',
    [APPLY_BUTTON_TEXT]: 'Submit Application',
    [BACK_BUTTON_TEXT]: 'Back',
    [NEXT_BUTTON_TEXT]: 'Continue',
    [COLLAPSE_BTN_SHOW_TEXT]: 'Show full text',
    [COLLAPSE_BTN_HIDE_TEXT]: 'Hide',
    [NO_FILE_TEXT]: 'No file selected.',
    [BROWSE_TEXT]: 'Browse ...',
    [DEFAULT_COMPANY_NAME]: 'recruiter',
    [REQUIREMENTS_NOT_MET_PAGE_TITLE]: 'Requirements not met',
    [REQUIREMENTS_NOT_MET_PAGE_MESSAGE]: 'Sorry, but you miss some of requirements for this job',
    [ALREADY_APPLIED_PAGE_TITLE]: 'Already applied',
    [ALREADY_APPLIED_PAGE_MESSAGE]: "Sorry, but it looks like you've already applied for this role",
    [UPLOADING_LABEL_TEXT]: 'Uploading...',
    [UPLOADED_LABEL_TEXT]: 'Delete file',
    [OK]: 'Ok',
    [CANCEL]: 'Cancel',
    [FILE_TOO_LARGE_MESSAGE]: 'The attachment can be a maximum of {fileSize}. Please upload a smaller file.',
    [REFRESH_CAPTCHA_BTN_TEXT]: 'Refresh captcha code'
};
