import React, { FC } from 'react';
import { connect } from 'react-redux';

import { form, language } from '../../reducers';
import ConnectedTranslatedTemplateText from './TranslatedTemplateText';
import {
    ACCOUNT_CREATION_MESSAGE,
    DEFAULT_COMPANY_NAME,
    TERMS_MESSAGE,
    TranslationKeys
} from '../../constants/stringKeys';
import { SurveyType } from '../../constants/form';
import type { RootState } from '../../../app/store';

interface IProps {
    companyName: string;
    termText: TranslationKeys;
}

const TermsBox: FC<IProps> = ({ companyName, termText }) => (
    <div className="row">
        <div
            className="col-xs-12 input-row"
            style={{ borderTop: '2px solid #dfdfdf', paddingTop: '12px', marginTop: '12px' }}
        >
            <div className="terms-box">
                <div className="report" />
                <div className="terms-info">
                    <ConnectedTranslatedTemplateText textKey={termText} replaces={{ companyName }} />
                </div>
            </div>
        </div>
    </div>
);

export default connect((state: RootState) => {
    const termText: TranslationKeys =
        form.getSurveyType(state) === SurveyType.REGISTRATION ? ACCOUNT_CREATION_MESSAGE : TERMS_MESSAGE;

    return {
        companyName: form.getJobData(state).companyName || language.getTranslatedText(state, DEFAULT_COMPANY_NAME),
        termText
    };
}, {})(TermsBox);
