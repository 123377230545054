import { ButtonRole, IMirageModal } from '@stepstone/ats-scrapers-core/types';
import { Reducer, combineReducers } from 'redux';
import type { RootState } from '../../app/store';
import { ModalDto } from '../../mirage/api';
import { LoaderType } from '../../mirage/constants/loaderType';
import { FormActions } from '../actions/form';
import { AlertContent, GUIActions } from '../actions/gui';
import {
    FETCH_FORM_SUCCESS_ACTION,
    FLOW_TYPE,
    FORM_SUBMIT_REQUEST_ACTION,
    FORM_SUBMIT_SUCCESS_ACTION,
    GET_STRUCTURE_SUCCESS_ACTION,
    POST_ACTION_SUCCESS_ACTION,
    POST_UPLOAD_SUCCESS_ACTION
} from '../constants/form';
import { CREATE_ALERT_ACTION, DELETE_ALERT_ACTION } from '../constants/gui';

const alerts: Reducer<AlertContent[]> = (state = [], action: GUIActions) => {
    switch (action.type) {
        case CREATE_ALERT_ACTION:
            return [{ message: action.message, title: action.title }];
        case DELETE_ALERT_ACTION:
            return state.slice(1);
        default:
            return state;
    }
};

const modal: Reducer<ModalDto | Record<string, never>, FormActions> = (state = {}, action) => {
    switch (action.type) {
        case FORM_SUBMIT_SUCCESS_ACTION:
        case POST_ACTION_SUCCESS_ACTION:
        case POST_UPLOAD_SUCCESS_ACTION:
        case GET_STRUCTURE_SUCCESS_ACTION:
            return action.response.modal || {};
        default:
            return state;
    }
};

const loaderType: Reducer<typeof LoaderType.LOADER_SUBMIT | typeof LoaderType.LOADER_FORM, FormActions> = (
    state = LoaderType.LOADER_FORM,
    action
) => {
    switch (action.type) {
        case FORM_SUBMIT_REQUEST_ACTION:
            const { buttonRole } = action.request;
            return ButtonRole.APPLY.toUpperCase() === buttonRole ? LoaderType.LOADER_SUBMIT : LoaderType.LOADER_FORM;
        case FETCH_FORM_SUCCESS_ACTION:
            const { flowType } = action.response;
            return flowType === FLOW_TYPE.ZERO_TOUCH ? LoaderType.LOADER_SUBMIT : LoaderType.LOADER_FORM;
        default:
            return state;
    }
};

export default combineReducers({ alerts, modal, loaderType });

// selectors
export const isAlertAvailable = (state: RootState['gui']): boolean => state.alerts.length > 0;
export const getAlert = (state: RootState['gui']): AlertContent | undefined => state.alerts[0];
export const isModalAvailable = (state: RootState['gui']): boolean =>
    state.modal && state.modal.closeBtnXPath && state.modal.elements ? state.modal.elements.length > 0 : false;

//TODO: align backend types with actual usage in ATSI-4518
export const getModal = (state: any): IMirageModal => state.modal;
