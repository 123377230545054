import { connect } from 'react-redux';

import { form, session } from '../../../../../common/reducers';
import {
    CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE,
    CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_VISIBLE
} from '../../../../../common/constants/form';
import { clickAndWaitForElement } from '../../../../../common/actions/form';
import AccordionWidgetView, { DispatchProps, OwnProps, StateProps, Props } from './AccordionWidgetView';
import type { RootState } from '../../../../../app/store';

export const ACCORDION_WIDGET = 'ACCORDION_WIDGET';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getElement(state, id),
    conversationUuid: session.getConversationUuid(state)
});

const mapDispatchToProps: DispatchProps = {
    clickAndWaitForElement
};

const mergeProps = (
    { xPath, waitForCssSelector, isAccordionExpanded, conversationUuid, ...stateProps }: StateProps,
    { clickAndWaitForElement }: DispatchProps
): Props => {
    const actionType = isAccordionExpanded
        ? CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_INVISIBLE
        : CLICK_AND_WAIT_FOR_ELEMENT_TO_BE_VISIBLE;
    return {
        ...stateProps,
        isAccordionExpanded,
        handleClick: () => clickAndWaitForElement(actionType, waitForCssSelector, xPath, conversationUuid)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccordionWidgetView);
