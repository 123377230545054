import { delay, ignoreElements, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { redirectTo } from './action';

export const redirectToEpic = action$ =>
    action$.pipe(
        ofType(redirectTo),
        delay(500),
        tap(({ payload: targetUrl }) => window.location.replace(targetUrl)),
        ignoreElements()
    );
