import { isInlineMultiFieldWidget } from '@stepstone/ats-scrapers-core';
import { IMirageAsyncSelectAutosuggestWidget, TMirageFormElement } from '@stepstone/ats-scrapers-core/types';
import type { Dispatch, SetStateAction } from 'react';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { Observable, Subject, defer, of, throwError } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, delay, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';

export type Locators = {
    xPath: string;
    blurXPath: string | undefined;
    clickXPath: string | undefined;
    scrollXPath: string | undefined;
    fieldXPath: string | undefined;
};

export type LocatorsRef = MutableRefObject<Locators | undefined>;

export const useLocatorsRef = (
    xPath: Locators['xPath'],
    blurXPath: Locators['blurXPath'],
    clickXPath: Locators['clickXPath'],
    scrollXPath: Locators['scrollXPath'],
    fieldXPath: Locators['fieldXPath']
): LocatorsRef => {
    const valueRef = useRef<Locators>();
    useEffect(() => {
        valueRef.current = {
            xPath,
            blurXPath,
            clickXPath,
            scrollXPath,
            fieldXPath
        };
    }, [xPath, blurXPath, clickXPath, scrollXPath, fieldXPath]);

    return valueRef;
};

export type SelectOptionItem = {
    label: string;
    value: string;
    xPath: string;
    isDisabled: boolean | undefined;
};

export type SelectAction = ActionMeta<SelectOptionItem>;

export function useSafeState<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>];

export function useSafeState<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

export function useSafeState<S>(initialState?: S | (() => S)) {
    const mountedRef = useRef(false);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const setCurrentState = useCallback((currentState: S) => {
        if (mountedRef.current) {
            setState(currentState);
        }
    }, []);

    return [state, setCurrentState] as const;
}

export function useDefaultOptions<T extends (...args: any[]) => Promise<SelectOptionItem[]>>(
    callback: T
): {
    handleEvent: (...args: Parameters<T>) => Promise<SelectOptionItem[]>;
    options: Awaited<ReturnType<T>>;
    clear: () => void;
} {
    const [options, setOptions] = useSafeState<any>([]);

    const handleEvent = (...args: Parameters<T>) => {
        return callback(...args).then(elements => {
            setOptions(elements);
            return elements;
        });
    };

    const clear = () => setOptions([]);

    return { handleEvent, options, clear };
}

type Value<T> = OnChangeValue<T, boolean>;
type CallbackFunction<T> = (
    value: Value<T>,
    action: ActionMeta<SelectOptionItem>,
    locators: LocatorsRef
) => Promise<any> | undefined;

export function useValue<T extends SelectOptionItem>(
    defaultValue: Value<T>,
    callback: CallbackFunction<T>
): {
    value: Value<T>;
    onChange: CallbackFunction<T>;
} {
    const [value, setValue] = useState<Value<T>>(defaultValue);

    const onChange: CallbackFunction<T> = (value, ...args) => {
        setValue(value);
        return callback(value, ...args);
    };

    return { value, onChange };
}

export function useQueuedAction() {
    const reactiveRef = useRef(new Subject<() => Promise<any> | undefined>());

    useEffect(() => {
        const subject = reactiveRef.current;
        const subscription = subject.pipe(concatMap(async callable => callable())).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return reactiveRef.current;
}

export type CancelationSignal = {
    cancelationSignal: Observable<boolean>;
    sendCancel: () => void;
};

export function useCancelationSignal() {
    const reactiveRef = useRef<CancelationSignal>();

    if (!reactiveRef.current) {
        const cancelation = new Subject<boolean>();
        const sendCancel = () => {
            cancelation.next(true);
        };

        reactiveRef.current = {
            cancelationSignal: cancelation.asObservable(),
            sendCancel
        };
    }

    useEffect(() => {
        const cancelation = reactiveRef.current;

        return () => {
            cancelation?.sendCancel();
        };
    }, []);

    return reactiveRef.current;
}

export function getUniqueOptions<T extends SelectOptionItem>(
    onFocusOptions: Array<T>,
    onMenuScrollToBottom: Array<T>
): Array<T> {
    return Array.from(new Map([...onFocusOptions, ...onMenuScrollToBottom].map(item => [item.xPath, item])).values());
}

export type WidgetWithItems = Pick<IMirageAsyncSelectAutosuggestWidget, 'items'>;

export const toSelectOption = (option: WidgetWithItems['items'][0]): SelectOptionItem => {
    const { text, xPath, isDisabled } = option || {};
    return {
        label: text,
        value: text,
        xPath,
        isDisabled
    };
};

const MAX_RETRIES = 5;

/**
 * Allows to read options through `observableFactory` multiple times
 *
 * @param config.repeatInterval - delay in milliseconds between each retry
 * @param config.maxRetries - maximal number of retries (maximal number of operations on factory = maxRetries + 1)
 * @param config.cancelationSignal - allows to cancel active polling through observable
 * @param observableFactory
 */
export function handleReadOptionsFromBackend<T extends WidgetWithItems>(
    config: { repeatInterval: number; maxRetries?: number; cancelationSignal?: CancelationSignal['cancelationSignal'] },
    observableFactory: () => Promise<T>
): Promise<SelectOptionItem[]> {
    const {
        maxRetries = MAX_RETRIES,
        repeatInterval,
        cancelationSignal = new Subject<boolean>().asObservable()
    } = config;
    let retriesLeft = maxRetries;
    const latestResult: WidgetWithItems = {
        items: []
    };

    return defer(() => observableFactory())
        .pipe(
            tap(result => {
                if (result.items.length === 0) {
                    throw result;
                } else if (latestResult.items.length !== result.items.length) {
                    retriesLeft = maxRetries;
                    latestResult.items = result.items;
                    throw result;
                }
            }),
            retryWhen(errors =>
                errors.pipe(
                    switchMap(error =>
                        retriesLeft-- > 0
                            ? of(error).pipe(delay(repeatInterval))
                            : throwError(new Error('Max number of operation retries have been exceeded'))
                    )
                )
            ),
            takeUntil(cancelationSignal),
            catchError(() => of(latestResult)),
            defaultIfEmpty(latestResult)
        )
        .toPromise()
        .then(result => result.items.map(toSelectOption));
}

export function useLoading<T extends (...args: any[]) => any>(
    callable: T
): [boolean, (...args: Parameters<T>) => ReturnType<T>] {
    const [loading, setLoading] = useSafeState(false);

    const withLoading = (...args: Parameters<T>) => {
        setLoading(true);
        return callable(...args).finally(() => setLoading(false));
    };

    return [loading, withLoading];
}

export function findElementByLocator(
    elements: TMirageFormElement[],
    locators: LocatorsRef
): WidgetWithItems | undefined {
    let found: WidgetWithItems | undefined;
    for (const element of elements) {
        if (element.xPath === locators.current?.xPath) {
            found = element as WidgetWithItems;
            break;
        }
        if (isInlineMultiFieldWidget(element)) {
            const field = findElementByLocator(element.fields, locators);
            if (field) {
                found = field;
                break;
            }
        }
    }
    return found;
}
