import {
    ACCOUNT_CREATION_MESSAGE,
    ALREADY_APPLIED_PAGE_MESSAGE,
    ALREADY_APPLIED_PAGE_TITLE,
    APPLY_BUTTON_TEXT,
    BACK_BUTTON_TEXT,
    BROWSE_TEXT,
    COLLAPSE_BTN_HIDE_TEXT,
    COLLAPSE_BTN_SHOW_TEXT,
    COMPANY_MESSAGE,
    CREATING_MESSAGE,
    DEFAULT_COMPANY_NAME,
    EXPIRE_PAGE_MESSAGE,
    EXPIRE_PAGE_TITLE,
    FETCHING_MESSAGE,
    LOADING_PAGE_TITLE,
    NEXT_BUTTON_TEXT,
    NO_FILE_TEXT,
    PREFILLING_MESSAGE,
    REDIRECT_PAGE_MESSAGE,
    REDIRECT_PAGE_TITLE,
    REGISTER_BUTTON_TEXT,
    REQUIRED_FIELDS_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_MESSAGE,
    REQUIREMENTS_NOT_MET_PAGE_TITLE,
    TERMS_MESSAGE,
    THANK_YOU_PAGE_MESSAGE,
    THANK_YOU_PAGE_TITLE,
    WRONG_FORM_PAGE_MESSAGE,
    WRONG_FORM_PAGE_TITLE,
    UPLOADING_LABEL_TEXT,
    UPLOADED_LABEL_TEXT,
    OK,
    CANCEL,
    FILE_TOO_LARGE_MESSAGE,
    Translations,
    REFRESH_CAPTCHA_BTN_TEXT
} from '../stringKeys';

export const nl: Translations = {
    [EXPIRE_PAGE_TITLE]: 'Sessie verlopen',
    [EXPIRE_PAGE_MESSAGE]: 'Uw sessie is verlopen. Laten we het nog een keer proberen. Veel succes',
    [REDIRECT_PAGE_TITLE]: 'Verwijs naar de site van de recruiter',
    [REDIRECT_PAGE_MESSAGE]: 'Je wordt doorverwezen naar de site van de recruiter. Veel succes',
    [THANK_YOU_PAGE_TITLE]: 'Sollicitatie ingediend',
    [THANK_YOU_PAGE_MESSAGE]: 'Uw sollicitatie werd succesvol ingediend. Veel succes',
    [WRONG_FORM_PAGE_TITLE]: 'Formulier is ongeldig',
    [WRONG_FORM_PAGE_MESSAGE]: 'Aanvraagformulier is niet geldig. Probeer het later opnieuw....',
    [LOADING_PAGE_TITLE]: 'Gegevens laden',
    [PREFILLING_MESSAGE]: 'Vooraf invullen van het formulier....',
    [CREATING_MESSAGE]: 'Sessie initialiseren.....',
    [FETCHING_MESSAGE]: 'Gegevens ophalen. Wacht alstublieft even.',
    [TERMS_MESSAGE]:
        'Door op solliciteren te klikken gaat u akkoord met de overdracht van uw sollicitatie naar {companyName} en gaat u akkoord met de algemene voorwaarden en het privacybeleid van {companyName}',
    [ACCOUNT_CREATION_MESSAGE]:
        'Door uw account te registreren gaat u akkoord met de overdracht van uw aanvraag naar {companyName} en gaat u akkoord met de algemene voorwaarden van {companyName} en' +
        ' Privacybeleid',
    [REQUIRED_FIELDS_MESSAGE]: 'Verplichte velden zijn gemarkeerd met',
    [COMPANY_MESSAGE]:
        'U solliciteert naar een job op de website van {companyName} die u wordt aangeboden door {jobBoardName}',
    [REGISTER_BUTTON_TEXT]: 'Registreren en doorgaan',
    [APPLY_BUTTON_TEXT]: 'Sollicitatie indienen',
    [BACK_BUTTON_TEXT]: 'Terug',
    [NEXT_BUTTON_TEXT]: 'Doorgaan',
    [COLLAPSE_BTN_SHOW_TEXT]: 'Toon volledige tekst',
    [COLLAPSE_BTN_HIDE_TEXT]: 'Verberg',
    [NO_FILE_TEXT]: 'Geen bestand geselecteerd.',
    [BROWSE_TEXT]: 'Bladeren ....',
    [DEFAULT_COMPANY_NAME]: 'recruiter',
    [REQUIREMENTS_NOT_MET_PAGE_TITLE]: 'Vereisten niet vervuld',
    [REQUIREMENTS_NOT_MET_PAGE_MESSAGE]: 'Sorry, maar je mist een aantal van de eisen voor deze baan',
    [ALREADY_APPLIED_PAGE_TITLE]: 'Reeds gesolliciteerd',
    [ALREADY_APPLIED_PAGE_MESSAGE]: 'Sorry, maar het lijkt erop dat je al hebt gesolliciteerd voor deze job',
    [UPLOADING_LABEL_TEXT]: 'Uploaden...',
    [UPLOADED_LABEL_TEXT]: 'Bestand verwijderen',
    [OK]: 'Goed',
    [CANCEL]: 'Annuleren',
    [FILE_TOO_LARGE_MESSAGE]:
        'De maximale grootte van een bijlage is {fileSize}. Gelieve een kleiner bestand te uploaden.',
    [REFRESH_CAPTCHA_BTN_TEXT]: 'Captcha-code vernieuwen'
};
