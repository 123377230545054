import { map, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { sendLog as sendLogRequest } from './action';
import { sendLog } from '../../../common/actions/log';
import { session } from '../../reducers';

export const sendLogEpic = (action$, state$) =>
    action$.pipe(
        ofType(sendLogRequest),
        withLatestFrom(state$),
        map(
            ([
                {
                    payload: { actionType, ...logData }
                },
                state
            ]) =>
                sendLog(session.getConversationUuid(state), actionType, {
                    ...logData,
                    jobBoardId: session.getJobBoardId(state)
                })
        )
    );
