import React from 'react';

import CustomPage, { CustomPageProps } from '../ui/CustomPage';

type LoadingPageProps = {
    message: CustomPageProps['message'];
    title: CustomPageProps['title'];
};

const LoadingPage: React.FC<LoadingPageProps> = props => <CustomPage icon="spinner" iconSpin={true} {...props} />;

LoadingPage.displayName = 'LoadingPage';

export default LoadingPage;
