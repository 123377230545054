import { ElementType, IMirageInputNode } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, createRef, useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';

import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import { form } from '../../../../../common/reducers';
import FormGroup from '../../FormGroup';
import { ConditionalWrap } from '../ConditionalWrap';
import HelpText from '../HelpText';
import { useBrowserValidation } from '../hooks';
import { connectInput } from '../utils';

export type CheckableInputProps = {
    postAction: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type: typeof ElementType.RADIO | typeof ElementType.CHECKBOX;
    LabelWrapper: FunctionComponent | string;
    shouldWrap: boolean;
    isEnabledBrowserValidation: boolean;
    setMultiFieldBrowserMessage?: React.Dispatch<React.SetStateAction<string>>;
} & IMirageInputNode;

export const CheckableInput: React.FC<CheckableInputProps> = ({
    help,
    label,
    postAction,
    status,
    isRequired,
    checked,
    id,
    dataRole,
    isDisabled,
    type,
    LabelWrapper = FormCheck.Label,
    shouldWrap = true,
    isEnabledBrowserValidation,
    name,
    setMultiFieldBrowserMessage,
    ...props
}) => {
    const ref = createRef<HTMLInputElement>();
    const browserMessage = useBrowserValidation(ref, isEnabledBrowserValidation);
    const helpMessage = help || browserMessage;
    const validationStatus = status === 'error' || browserMessage ? 'error' : status;

    useEffect(() => {
        if (browserMessage && setMultiFieldBrowserMessage) {
            setMultiFieldBrowserMessage(browserMessage);
        }
    }, [browserMessage]);

    return (
        <ConditionalWrap
            condition={shouldWrap}
            wrap={(children: ReactNode) => (
                <FormGroup className={classNames({ required: isRequired })} controlId={id}>
                    {children}
                    <HelpText help={helpMessage} status={validationStatus} id={`${id}_HelpText`} />
                </FormGroup>
            )}
        >
            <FormCheck {...props} className="form-check">
                <FormCheck.Input
                    ref={ref}
                    onChange={postAction}
                    type={type}
                    aria-invalid={!!validationStatus}
                    aria-required={isRequired}
                    checked={checked}
                    aria-describedby={`${id}_HelpText`}
                    data-role={dataRole}
                    disabled={isDisabled}
                    required={isRequired}
                    name={name}
                />
                <LabelWrapper htmlFor={id}>
                    <RenderTextArray text={label} />
                </LabelWrapper>
            </FormCheck>
        </ConditionalWrap>
    );
};

CheckableInput.displayName = 'CheckableInput';

export default connectInput<CheckableInputProps>(form.getCheckableFieldData, CheckableInput);
