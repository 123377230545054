import { combineReducers, Reducer } from 'redux';

import { getUrlParam } from '../../utils';
import { CONVERSATION_UUID_PARAM_NAME, DEV_MODE_ENABLED_PARAM_NAME, TIMEOUT_PARAM_NAME } from '../constants';
import { JobBoardData, STEPSTONE_JOBBOARDS } from '../constants/jobBoard';
import { INIT_SESSION_TIME } from '../constants/session';
import { decodeBase64, urlHasParam } from '../urlUtils';
import type { RootState } from '../../app/store';

const conversationUuid: Reducer<string> = (state = getUrlParam(CONVERSATION_UUID_PARAM_NAME) || '', action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const devMode: Reducer<boolean> = (
    state = urlHasParam(DEV_MODE_ENABLED_PARAM_NAME) && !!process.env.REACT_APP_CONFIG_MODE_ENABLED
) => state;

const timeoutHash = getUrlParam(TIMEOUT_PARAM_NAME);
const defaultTimeout = timeoutHash ? decodeBase64(timeoutHash) : '';

const initTimeout: Reducer<number> = (state = parseInt(defaultTimeout) || INIT_SESSION_TIME, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const jobBoard: Reducer<JobBoardData | null> = (state = null, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const effectiveEndpointUrl: Reducer<string | null> = (state = null) => {
    return state;
};

const postApplicationUrl: Reducer<string | null> = (state = null) => {
    return state;
};

export default combineReducers({
    conversationUuid,
    devMode,
    effectiveEndpointUrl,
    postApplicationUrl,
    jobBoard,
    initTimeout
});

// selectors
export const isDevModeEnabled = (state: RootState['session']): ReturnType<typeof devMode> => state.devMode;
export const getConversationUuid = (state: RootState['session']): ReturnType<typeof conversationUuid> =>
    state.conversationUuid;
export const getEffectiveEndpointUrl = (state: RootState['session']): ReturnType<typeof effectiveEndpointUrl> =>
    state.effectiveEndpointUrl;
export const getPostApplicationUrl = (state: RootState['session']): ReturnType<typeof postApplicationUrl> =>
    state.postApplicationUrl;
export const getInitTimeout = (state: RootState['session']): ReturnType<typeof initTimeout> => state.initTimeout;
export const getJobBoardData = (state: RootState['session']): ReturnType<typeof jobBoard> => state.jobBoard;
export const getJobBoardId = (state: RootState['session']): JobBoardData['id'] | undefined => (state.jobBoard || {}).id;
export const getJobBoardName = (state: RootState['session']): JobBoardData['name'] | undefined =>
    (state.jobBoard || {}).name;
export const hasContinentalStyling = (state: RootState['session']): boolean =>
    state.jobBoard?.id ? STEPSTONE_JOBBOARDS.includes(state.jobBoard.id) : false;
