import React, { FC } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormLabel, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMirageIframeBasedFormWidget, IMirageInputNode } from '@stepstone/ats-scrapers-core/types';

import FormGroup from '../../FormGroup';
import { form, session } from '../../../../../common/reducers';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import {
    createClickAndWaitForFrameAction,
    createPostAction,
    postponeStructureRefresh
} from '../../../../../common/actions/form';
import { TranslationKeys } from '../../../../../common/constants/stringKeys';
import TranslatedText from '../../../../../common/components/ui/TranslatedText';
import HelpText from '../../fields/HelpText';
import type { RootState } from '../../../../../app/store';

type OwnProps = { id: string };

type Props = Pick<IMirageInputNode, 'label' | 'isRequired' | 'value' | 'help' | 'status' | 'dataRole'> & {
    customLabel: string;
    buttonTxt: string;
    clickAction: () => void;
    removeAction: (e: React.MouseEvent<SVGSVGElement>) => ReturnType<typeof createPostAction>;
} & OwnProps;

type StateProps = Pick<IMirageIframeBasedFormWidget, 'iframeSelector' | 'removeButtonXPath'> & {
    conversationUuid: string;
    xPath: string;
} & Props;

type DispatchProps = {
    clickAction: typeof createClickAndWaitForFrameAction;
    removeAction: typeof createPostAction;
};

const IframeBasedFormWidget: FC<Props> = ({
    label,
    isRequired,
    clickAction,
    customLabel,
    buttonTxt,
    value,
    removeAction,
    help,
    status,
    id,
    dataRole
}) => {
    return (
        <div className="row mt-2 mb-2">
            <div className="col-xs-12 input-row">
                {label && (
                    <FormGroup className={classNames('d-flex, w-100', { required: isRequired })}>
                        <FormLabel className="form-label">
                            <RenderTextArray text={label} />
                        </FormLabel>
                    </FormGroup>
                )}
                <Button
                    className="button-row"
                    variant="primary"
                    key="button"
                    type="button"
                    onClick={clickAction}
                    data-role={dataRole}
                    aria-describedby={id && `${id}_HelpText`}
                    aria-required={isRequired}
                    aria-invalid={status === 'error'}
                >
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        // This is a bit of lying because looks like buttonText might be optional (according to tests)
                        <TranslatedText textKey={buttonTxt as TranslationKeys} />
                    )}
                </Button>
                <span className="ms-2">{!!value && <RenderTextArray text={value} />}</span>
                {!!value && (
                    <FontAwesomeIcon
                        className="delete-icon"
                        onClick={removeAction}
                        icon="times"
                        data-testid="delete-icon"
                    />
                )}
                {help && (
                    <div className="w-100">
                        <HelpText help={help} status={status} />
                    </div>
                )}
            </div>
        </div>
    );
};

IframeBasedFormWidget.displayName = 'IframeBasedFormWidget';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getFileFieldData<Omit<StateProps, 'conversationUuid'>>(state, id),
    conversationUuid: session.getConversationUuid(state)
});

const mapDispatchToProps = {
    clickAction: createClickAndWaitForFrameAction,
    removeAction: createPostAction
};

const mergeProps = (
    { conversationUuid, xPath, removeButtonXPath, iframeSelector, ...stateProps }: StateProps,
    { clickAction, removeAction }: DispatchProps
): Props => ({
    ...stateProps,
    clickAction: () => {
        clickAction(xPath, conversationUuid, iframeSelector);
        postponeStructureRefresh();
    },
    //@ts-expect-error removeButtonXPath is optional but required in removeAction
    removeAction: e => removeAction(e, removeButtonXPath, conversationUuid)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IframeBasedFormWidget);
