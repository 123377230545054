import React from 'react';

import Logo from '../common/components/ui/Logo';
import FormContainer from './components/form/FormContainer';
import Footer from '../common/components/ui/Footer';
import ErrorBoundary from '../common/components/ErrorBoundary';

const App = () => (
    <ErrorBoundary>
        <Logo />
        <FormContainer />
        <Footer />
    </ErrorBoundary>
);

App.displayName = 'App';

export default App;
