import { createRequestName, createSuccessName, createFailureName } from '../../common/action';

export const REFRESH_SESSION_ACTION = 'REFRESH_SESSION';
export const REFRESH_SESSION_REQUEST_ACTION = createRequestName(REFRESH_SESSION_ACTION);
export const REFRESH_SESSION_SUCCESS_ACTION = createSuccessName(REFRESH_SESSION_ACTION);
export const REFRESH_SESSION_FAILURE_ACTION = createFailureName(REFRESH_SESSION_ACTION);

export const KEEP_ALIVE_ACTION_INTERVAL_TIME = 60000;
export const REFRESH_SESSION_TIME = process.env.REACT_APP_REFRESH_SESSION_TIME || 5000;
export const INIT_SESSION_TIME = parseInt(`${process.env.REACT_APP_INIT_SESSION_TIME}`) || 30000;
export const INITIALIZE_INTERVAL_TIME = 500;
/**
 * Maximal size of uploaded file in bytes (4 MiB). If the limit is reached, custom validation will be displayed
 */
export const MAX_FILE_SIZE = 4 * 1024 * 1024;
