import React, { FC } from 'react';
import classNames from 'classnames';

import FormHeader from '../../../common/components/ui/FormHeader';
import Element from './Element';
import AsteriskHelp from '../../../common/components/ui/AsteriskHelp';
import TermsBox from '../../../common/components/ui/TermsBox';
import Navigation from '../../../common/components/form/Navigation';
import { SkeletonForm } from '../SkeletonLoader/SkeletonLoader';
import { LoaderType } from '../../constants/loaderType';
import { BaseNode } from '../../reducers/form';

interface IProps {
    loaderType: LoaderType;
    className?: classNames.Argument;
    companyName: string | null;
    surveyType: string;
    structure: BaseNode[];
    navigation: BaseNode[];
    hasRequired: boolean;
    shouldDisplayLoader: boolean;
}

export const MainForm: FC<IProps> = ({
    className,
    companyName,
    surveyType,
    structure,
    hasRequired,
    navigation,
    shouldDisplayLoader,
    loaderType
}) => {
    if (shouldDisplayLoader) {
        return <SkeletonForm loaderType={loaderType} />;
    }

    return (
        <div className={classNames([className])}>
            <FormHeader companyName={companyName} surveyType={surveyType} />
            {structure.map(({ id, ...props }, index) => (
                // Following task should address key calculation using stable element id
                // https://vulcan.stepstone.com/browse/ATSI-3923
                <Element {...props} id={id} key={`${index}`} />
            ))}
            <AsteriskHelp hasRequired={hasRequired} />
            <TermsBox key="termsBox" />
            <Navigation>
                {navigation.map(({ id, ...props }, index) => (
                    <Element {...props} id={id} key={`${id}_${index}`} />
                ))}
            </Navigation>
        </div>
    );
};

MainForm.displayName = 'MainForm';

export default MainForm;
