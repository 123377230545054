import React from 'react';
import { connect } from 'react-redux';

import { form, session } from '../../../common/reducers';
import { createKeepAliveAction, createLogoErrorAction } from '../../../common/actions/form';
import WrongForm from '../../../common/components/pages/WrongForm';
import { KEEP_ALIVE_ACTION_INTERVAL_TIME } from '../../../common/constants/session';
import AlertContainer from '../../../common/components/ui/Alert';
import ThankYouPage from '../../../common/components/pages/ThankYouPage';
import RequirementsNotMetPage from '../../../common/components/pages/RequirementsNotMetPage';
import AlreadyAppliedPage from '../../../common/components/pages/AlreadyAppliedPage';
import LoadingPage from '../../../common/components/pages/LoadingPage';
import SessionExpired from '../../../common/components/pages/SessionExpired';
import RedirectPage from '../../../common/components/pages/RedirectPage';
import JobDetails from '../JobDetails/JobDetails';
import SectionContainer from './SectionContainer';
import { INITIALIZATION_STATE_ERROR, INITIALIZATION_STATE_JOB_EXPIRED } from '../../../common/constants/form';
import ModalContainer from '../../../common/components/ui/modal/Modal';
import { STATUS_PARAM_NAME, SUCCESS } from '../../constants/applicationStatus';
import { setUrlParam } from '../../../utils';
import { job } from '../../reducers';
import { initForm } from '../../state/init/action';
import { redirectTo } from '../../../common/state/redirectTo/action';
import MainForm from './MainForm';
import { LoaderType } from '../../constants/loaderType';
import { BaseNode } from '../../reducers/form';
import type { RootState } from '../../../app/store';

type TProps = {
    companyLogoUrl: string | null;
    companyName: string | null;
    hasRequired: boolean;
    initForm: () => void;
    redirectTo: (postApplicationUrl: string | URL | null) => void;
    initializationStatus?: string | null;
    initTimeout?: number;
    isDevModeEnabled?: boolean;
    isSessionExpired: boolean;
    isFetching: boolean;
    isSuccessfullyApplied: boolean;
    isValid: boolean;
    shouldDisplayLoader: boolean;
    loaderType: LoaderType;
    jobTitle: string | null;
    onLogoError: () => void;
    keepAliveAction: () => void;
    structure: BaseNode[];
    navigation: BaseNode[];
    surveyType: string;
    userDataUUID?: string;
    hasContinentalStyling: boolean;
    postApplicationUrl: string | URL | null;
    originalUrl: string | URL | null;
    jobBoardName: string | undefined;
    areRequirementsNotMet: boolean;
    isBrowserValidationEnabled: boolean;
    wasAlreadyApplied: boolean;
};

class Form extends React.Component<TProps> {
    static displayName = 'FormContainer';

    componentDidMount() {
        this.props.initForm();
        this.setupSessionKeepAliveIntervalTime();
    }

    setupSessionKeepAliveIntervalTime() {
        if (process.env.REACT_APP_CONFIG_MODE_ENABLED && this.props.isDevModeEnabled) {
            setInterval(this.props.keepAliveAction, KEEP_ALIVE_ACTION_INTERVAL_TIME);
        }
    }

    redirect() {
        this.props.redirectTo(
            !!this.props.postApplicationUrl
                ? setUrlParam(this.props.postApplicationUrl, STATUS_PARAM_NAME, SUCCESS)
                : this.props.originalUrl
        );
    }

    render() {
        const {
            hasContinentalStyling,
            isFetching,
            companyName,
            surveyType,
            structure,
            hasRequired,
            navigation,
            isBrowserValidationEnabled,
            shouldDisplayLoader,
            loaderType
        } = this.props;
        switch (true) {
            case this.props.isSuccessfullyApplied: {
                if (this.props.postApplicationUrl) {
                    this.redirect();
                    // @ts-ignore TODO required attributes are not provided
                    return <LoadingPage />;
                }
                return <ThankYouPage />;
            }
            case this.props.areRequirementsNotMet:
                return <RequirementsNotMetPage />;
            case this.props.wasAlreadyApplied:
                return <AlreadyAppliedPage />;
            case this.props.isSessionExpired:
            case this.props.initializationStatus === INITIALIZATION_STATE_ERROR:
            case this.props.initializationStatus === INITIALIZATION_STATE_JOB_EXPIRED:
                return <RedirectPage redirectUrl={this.props.originalUrl} backupComponent={SessionExpired} />;
            case !this.props.isValid:
                return <RedirectPage redirectUrl={this.props.originalUrl} backupComponent={WrongForm} />;
            default:
                return (
                    <div className="container">
                        <AlertContainer />
                        <ModalContainer />
                        <SectionContainer className={['my-3', { 'main-form': hasContinentalStyling }]}>
                            <JobDetails
                                onLogoError={this.props.onLogoError}
                                companyLogoUrl={this.props.companyLogoUrl}
                                companyName={this.props.companyName}
                                jobBoardName={this.props.jobBoardName || ''}
                                jobTitle={this.props.jobTitle}
                                surveyType={this.props.surveyType}
                                isFetching={isFetching}
                            />
                        </SectionContainer>
                        <SectionContainer
                            className={['main-form', { 'mb-5': hasContinentalStyling }]}
                            isValidating={isBrowserValidationEnabled}
                        >
                            <MainForm
                                className={{ [`form-content${hasContinentalStyling ? '' : '-padded'}`]: true }}
                                companyName={companyName}
                                surveyType={surveyType}
                                structure={structure}
                                hasRequired={hasRequired}
                                navigation={navigation}
                                shouldDisplayLoader={shouldDisplayLoader}
                                loaderType={loaderType}
                            />
                        </SectionContainer>
                    </div>
                );
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    structure: form.getStructure(state),
    navigation: form.getNavigation(state),
    surveyType: form.getSurveyType(state),
    jobBoardName: session.getJobBoardName(state),
    jobBoardId: session.getJobBoardId(state),
    clientId: job.getCompanyId(state),
    originalUrl: session.getEffectiveEndpointUrl(state),
    postApplicationUrl: session.getPostApplicationUrl(state),
    conversationUuid: session.getConversationUuid(state),
    initTimeout: session.getInitTimeout(state),
    hasRequired: form.hasRequired(state),
    isDevModeEnabled: session.isDevModeEnabled(state),
    atsId: job.getAtsId(state),
    hasContinentalStyling: session.hasContinentalStyling(state),
    loaderType: state.gui.loaderType,
    ...form.getJobData(state),
    ...form.getFormStatuses(state)
});

const mapDispatchToProps = {
    createKeepAliveAction,
    initForm,
    createLogoErrorAction,
    redirectTo
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    (
        { conversationUuid, atsId, clientId, isSubmitting, isFetching, ...stateProps },
        { initForm, createLogoErrorAction, createKeepAliveAction, redirectTo }
    ) => ({
        ...stateProps,
        isFetching,
        shouldDisplayLoader: isSubmitting || isFetching,
        onLogoError: createLogoErrorAction,
        conversationUuid,
        initForm: () => initForm(),
        // @ts-ignore TODO redirectTo() function has type ActionCreatorWithoutPayload<"REDIRECT">, however payload is provided
        redirectTo: url => redirectTo(url),
        keepAliveAction: () => createKeepAliveAction(conversationUuid)
    })
)(Form);
