import React, { FC } from 'react';

import CustomPage from '../ui/CustomPage';
import TranslatedText from '../ui/TranslatedText';
import { REDIRECT_PAGE_MESSAGE, REDIRECT_PAGE_TITLE } from '../../constants/stringKeys';

type RedirectPageProps = {
    redirectUrl?: string | URL | null;
    /** @see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts */
    backupComponent: React.ElementType;
};

const RedirectPage: FC<RedirectPageProps> = ({ redirectUrl, backupComponent: BackupComponent }) =>
    redirectUrl ? (
        <CustomPage
            icon="external-link-alt"
            message={<TranslatedText textKey={REDIRECT_PAGE_MESSAGE} />}
            title={<TranslatedText textKey={REDIRECT_PAGE_TITLE} />}
        />
    ) : (
        <BackupComponent />
    );

export default RedirectPage;
