import { Reducer } from 'redux';
import { FormActions } from '../../common/actions/form';

import { FETCH_FORM_SUCCESS_ACTION } from '../../common/constants/form';
import { AtsiJobDetailsDto } from '../api';
import type { RootState } from '../../app/store';

const jobReducer: Reducer<AtsiJobDetailsDto | Record<string, never>, FormActions> = (state = {}, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS_ACTION:
            return ((action.response || {}).atsiDataDto || {}).jobDetails || {};
        default:
            return state;
    }
};

export default jobReducer;

// selectors
export const getAtsId = (state: RootState['job']): AtsiJobDetailsDto['atsId'] | undefined => (state || {}).atsId;
export const getCompanyId = (state: RootState['job']): AtsiJobDetailsDto['companyId'] | undefined =>
    (state || {}).companyId;
export const getJobId = (state: RootState['job']): AtsiJobDetailsDto['jobId'] | undefined => (state || {}).jobId;
export const getIntegrationId = (state: RootState['job']): AtsiJobDetailsDto['integrationId'] | undefined =>
    (state || {}).integrationId;

export const getJobLoggingContext = (
    state: RootState['job']
): {
    atsId: AtsiJobDetailsDto['atsId'] | undefined;
    clientId: AtsiJobDetailsDto['companyId'] | undefined;
    integrationId: AtsiJobDetailsDto['integrationId'] | undefined;
    jobId: AtsiJobDetailsDto['jobId'] | undefined;
} => ({
    atsId: getAtsId(state),
    clientId: getCompanyId(state),
    integrationId: getIntegrationId(state),
    jobId: getJobId(state)
});
