import React from 'react';

const JobsIeFooter = () => (
    <div className="footer">
        <div className="container" style={{ textAlign: 'center' }}>
            <div className="wrap">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="logo-footer hide-text"
                    alt="jobs.ie logo"
                    href="https://www.jobs.ie/"
                >
                    jobs.ie
                </a>
            </div>
            <div className="footer-container">
                <ul className="list-inline footer">
                    <li className="first">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.jobs.ie/about/about-us">
                            About Us
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.jobs.ie/about/terms-and-conditions"
                        >
                            Terms of Use
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.jobs.ie/about/cookies">
                            Cookie Policy
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.jobs.ie/about/privacy-policy">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.jobs.ie/about/partners">
                            Partner Sites
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.jobs.ie/about/help-and-support">
                            Contact Us
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="copyright">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div
                        className="wrap col-xs-12 content d-flex align-items-center justify-content-sm-center justify-content-md-start"
                        style={{ color: '#747c83' }}
                    >
                        <p>© {new Date().getFullYear()} Jobs.ie</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default JobsIeFooter;
