import {
    IMirageInlineMultiFieldWidget,
    IMirageTextNode,
    TInlineFieldType,
    WidgetType
} from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React from 'react';
import { FormLabel } from 'react-bootstrap';

import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import FieldElement from '../../FieldElement';
import FormGroup from '../../FormGroup';
import WidgetElement from '../../WidgetElement';
import HelpText from '../../fields/HelpText';

export type OwnProps = { id: string };

export type Props = IMirageInlineMultiFieldWidget & OwnProps;

const ACCEPTED_WIDGETS: string[] = [
    WidgetType.ASYNC_SINGLE_FILE_UPLOAD_WITH_DELETE,
    WidgetType.ASYNC_SELECT_AUTOSUGGEST_WIDGET
];

const isTextNode = (elem: TInlineFieldType): elem is IMirageTextNode => elem.tagName.toUpperCase() === 'SPAN';

const mapField = ({ ...props }: TInlineFieldType, index: number, array: TInlineFieldType[]) => {
    const { xPath } = props;
    let inlineClassName;

    if (isTextNode(props)) {
        return (
            <div key={index} className="d-flex flex-row align-items-center justify-content-center w-25">
                {props.text}
            </div>
        );
    }

    if (!index) {
        inlineClassName = 'pr-md-1';
    } else if (index === array.length - 1) {
        inlineClassName = 'pl-md-1';
    } else {
        inlineClassName = 'px-md-1';
    }

    const { customProps } = props;
    const addStyles = customProps?.customClassName ? `${customProps.customClassName}` : `col-md ${inlineClassName}`;

    return (
        <div key={index} className={addStyles}>
            {ACCEPTED_WIDGETS.includes(props.type) ? (
                <WidgetElement {...props} id={xPath} />
            ) : (
                <FieldElement {...props} id={xPath} />
            )}
        </div>
    );
};

export const InlineMultiFieldView: React.FC<Props> = ({ label, fields, help, status, isRequired, id }) => (
    <div className="row">
        <div className="col-xs-12 input-row w-100 mb-0">
            {label && (
                <FormGroup className={classNames('d-flex', 'w-100', 'mb-0', { required: isRequired })}>
                    <FormLabel className="form-label">
                        <RenderTextArray text={label} />
                    </FormLabel>
                </FormGroup>
            )}
            <div className="row">{fields && fields.map(mapField)}</div>
            {help && <HelpText help={help} status={status} id={`${id}_HelpText`} />}
        </div>
    </div>
);

InlineMultiFieldView.displayName = 'InlineMultiFieldView';
