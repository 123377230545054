import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

type FormLayoutProps = {
    children: React.ReactNode;
    className?: classNames.Argument;
    isValidating?: boolean;
};

const SectionContainer = ({ children, className, isValidating }: FormLayoutProps) => (
    <Row>
        <Col xs={12}>
            <div data-validate={isValidating} className={classNames(className)}>
                {children}
            </div>
        </Col>
    </Row>
);

SectionContainer.displayName = 'SectionContainer';

export default SectionContainer;
