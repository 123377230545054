import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonRole, IMirageFormNavigation, IMirageInputNode } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createPostAction, createSubmitAction } from '../../../../common/actions/form';
import RenderTextArray from '../../../../common/components/ui/RenderTextArray';
import TranslatedText from '../../../../common/components/ui/TranslatedText';
import { SurveyType } from '../../../../common/constants/form';
import {
    APPLY_BUTTON_TEXT,
    BACK_BUTTON_TEXT,
    CANCEL,
    NEXT_BUTTON_TEXT,
    OK,
    REGISTER_BUTTON_TEXT
} from '../../../../common/constants/stringKeys';
import { form, session } from '../../../../common/reducers';
import './SubmitButton.css';
import type { RootState } from '../../../../app/store';

type OwnProps = { id: string };

// Since we may get `IMirageInputNode` or `IMirageFormNavigation`,
// form.getSubmitButtonData just pick following props from state and we don't have corresponding interface for this
type ComponentProps = Pick<IMirageInputNode | IMirageFormNavigation, 'xPath'> & {
    value: IMirageInputNode['value'] | undefined;
    // Comes from customProps
    customClassName: string | undefined;
    customLabel: IMirageFormNavigation['customLabel'];
    isDisabled: IMirageInputNode['isDisabled'] | undefined;
    // Come from state reducers `computeElements` / `elementsReducer`
    isLoading: boolean;
    role: IMirageFormNavigation['role'] | undefined;
    timeout: IMirageFormNavigation['timeout'] | undefined;
};

const ButtonsText = {
    applyText: APPLY_BUTTON_TEXT,
    nextText: NEXT_BUTTON_TEXT,
    backText: BACK_BUTTON_TEXT,
    okText: OK,
    cancelText: CANCEL
} as const;

type StateProps = {
    conversationUuid: string;
    buttonsText: {
        applyText: typeof APPLY_BUTTON_TEXT;
        nextText: typeof NEXT_BUTTON_TEXT | typeof REGISTER_BUTTON_TEXT;
        backText: typeof BACK_BUTTON_TEXT;
        okText: typeof OK;
        cancelText: typeof CANCEL;
    };
} & ComponentProps;

type DispatchProps = {
    submitAction: typeof createSubmitAction;
    clickAction: typeof createPostAction;
};

type Props = Omit<StateProps, 'conversationUuid' | 'value' | 'xPath' | 'timeout'> & {
    defaultValue: StateProps['value'];
    submit: (buttonRole: string) => (e: any) => unknown;
    click: () => (e: any) => unknown;
};

const SubmitButton: React.FC<Props> = ({
    isLoading,
    isDisabled,
    customLabel,
    submit,
    click,
    role,
    defaultValue,
    buttonsText,
    customClassName = undefined
}) => {
    // eslint-disable-next-line default-case
    switch (role) {
        case ButtonRole.APPLY:
            return (
                <Button
                    className="apply-button col-sm-12 col-md-1"
                    variant="primary"
                    key="button"
                    type="submit"
                    aria-label={role}
                    onClick={submit(ButtonRole.APPLY.toUpperCase())}
                    disabled={isLoading || isDisabled}
                >
                    {isLoading && <FontAwesomeIcon icon="spinner" spin />}
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        <TranslatedText textKey={buttonsText.applyText} />
                    )}
                </Button>
            );
        case ButtonRole.NEXT:
            return (
                <Button
                    className="next-button"
                    variant="primary"
                    key="button"
                    type="button"
                    onClick={submit(ButtonRole.NEXT.toUpperCase())}
                    disabled={isLoading || isDisabled}
                    aria-label={role}
                >
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        <TranslatedText textKey={buttonsText.nextText} />
                    )}
                    <FontAwesomeIcon icon={isLoading ? 'spinner' : 'arrow-right'} spin={isLoading} />
                </Button>
            );
        case ButtonRole.BACK:
            return (
                <Button
                    className="back-button"
                    variant="primary"
                    key="button"
                    type="button"
                    onClick={submit(ButtonRole.BACK.toUpperCase())}
                    disabled={isLoading || isDisabled}
                    aria-label={role}
                >
                    <FontAwesomeIcon icon={isLoading ? 'spinner' : 'arrow-left'} spin={isLoading} />
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        <TranslatedText textKey={buttonsText.backText} />
                    )}
                </Button>
            );
        case ButtonRole.CANCEL:
            return (
                <Button
                    className="cancel-button"
                    variant="primary"
                    key="button"
                    type="button"
                    aria-label={role}
                    onClick={submit(ButtonRole.CANCEL.toUpperCase())}
                    disabled={isLoading || isDisabled}
                >
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        <TranslatedText textKey={buttonsText.cancelText} />
                    )}
                    <FontAwesomeIcon icon={isLoading ? 'spinner' : 'times'} spin={isLoading} />
                </Button>
            );
        case ButtonRole.OK:
            return (
                <Button
                    className="ok-button"
                    variant="primary"
                    key="button"
                    type="button"
                    aria-label={role}
                    onClick={submit(ButtonRole.OK.toUpperCase())}
                    disabled={isLoading || isDisabled}
                >
                    {!!customLabel ? (
                        <RenderTextArray text={customLabel} />
                    ) : (
                        <TranslatedText textKey={buttonsText.okText} />
                    )}
                    <FontAwesomeIcon icon={isLoading ? 'spinner' : 'check'} spin={isLoading} />
                </Button>
            );
    }
    return (
        <Button
            className={classNames('offset-2', 'button-row', customClassName)}
            variant="primary"
            key="button"
            type="button"
            onClick={click()}
            disabled={isLoading || isDisabled}
        >
            {isLoading && <FontAwesomeIcon icon="spinner" spin />}
            {defaultValue}
        </Button>
    );
};

SubmitButton.displayName = 'SubmitButton';

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getSubmitButtonData<ComponentProps>(state, id),
    conversationUuid: session.getConversationUuid(state),
    buttonsText:
        form.getSurveyType(state) === SurveyType.REGISTRATION
            ? { ...ButtonsText, nextText: REGISTER_BUTTON_TEXT }
            : { ...ButtonsText }
});

const mapDispatchToProps: DispatchProps = {
    submitAction: createSubmitAction,
    clickAction: createPostAction
};

const mergeProps = (
    { conversationUuid, value, xPath, timeout, ...stateProps }: StateProps,
    { submitAction, clickAction }: DispatchProps
): Props => ({
    ...stateProps,
    defaultValue: value,
    submit: (buttonRole: string) => e => submitAction(e, xPath, conversationUuid, buttonRole, timeout),
    click: () => e => clickAction(e, xPath, conversationUuid)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SubmitButton);
