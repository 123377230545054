import { combineEpics } from 'redux-observable';

import { initEpic, initRetryEpic, initTimeoutEpic, initErrorEpic } from './init/epic';
import { redirectToEpic } from '../../common/state/redirectTo/epic';
import { sendLogEpic } from '../../common/state/log/epic';
import {
    formErrorEpic,
    formStructurePollingControllerEpic,
    formStructurePollingEpic,
    formRenderedEpic
} from './form/epic';
import { sessionStatePersistencyEpic, sessionExpiredEpic, sessionRefreshEpic } from './session/epic';

export const rootEpic = combineEpics(
    sendLogEpic,
    initEpic,
    initTimeoutEpic,
    initRetryEpic,
    initErrorEpic,
    formErrorEpic,
    formRenderedEpic,
    sessionRefreshEpic,
    sessionExpiredEpic,
    sessionStatePersistencyEpic,
    formStructurePollingControllerEpic,
    formStructurePollingEpic,
    redirectToEpic
);
