import { IMirageDatePickerWidget } from '@stepstone/ats-scrapers-core/types';
import classNames from 'classnames';
import { isValid, parse } from 'date-fns';
import React from 'react';
import { FormLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { createPostActionWithType } from '../../../../../common/actions/form';
import { sendLog } from '../../../../../common/actions/log';
import RenderTextArray from '../../../../../common/components/ui/RenderTextArray';
import { CONVERSATION_UUID_PARAM_NAME } from '../../../../../common/constants';
import { LOG_TYPE } from '../../../../../common/constants/log';
import { getUrlParam } from '../../../../../utils';
import FormGroup from '../../FormGroup';
import HelpText from '../../fields/HelpText';

export type OwnProps = { id: string };

type ComponentProps = IMirageDatePickerWidget;

export type StateProps = {
    conversationUuid: string;
} & OwnProps &
    ComponentProps;

export type DispatchProps = {
    createPostActionWithType: typeof createPostActionWithType;
};

export type Props = Omit<StateProps, 'conversationUuid' | 'xPath'> & {
    onCalendarOpen: () => void;
    onClickOutside: () => void;
    setValue: (value: Date | [Date, Date] | null, dateFormat: IMirageDatePickerWidget['dateFormat']) => void;
};

const hasError = (status: IMirageDatePickerWidget['status']) => (status === 'error' ? 'border-danger' : undefined);

const setRangeDate = (date: string | undefined) => (!!date ? new Date(date) : undefined);

const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    customProps: IMirageDatePickerWidget['customProps']
) => {
    if (customProps && customProps.disableKeyboardInput) {
        if (e.cancelable) {
            e.preventDefault();
        }
        return false;
    }
};

const getSelectedDate = (selectedDate: string, dateFormat: Props['dateFormat']): Date | undefined => {
    let data: Date | undefined = undefined;

    if (!selectedDate) {
        return;
    }

    try {
        data = parse(selectedDate, dateFormat, new Date());
    } catch (e) {
        const conversationId = getUrlParam(CONVERSATION_UUID_PARAM_NAME);
        if (conversationId) {
            sendLog(conversationId, LOG_TYPE.FRONTEND_ERROR_OCCURRED, { error: (e as Error).toString() });
        }
    }

    if (isValid(data)) {
        return data;
    }

    return;
};

export const DatePickerWidget: React.FC<Props> = ({
    help,
    status,
    isRequired,
    label,
    value,
    dateFormat,
    localeDateFormat = dateFormat,
    locale,
    placeholder,
    startDate,
    endDate,
    setValue,
    onCalendarOpen,
    onClickOutside,
    dataRole,
    id,
    customProps = {}
}) => (
    <div className="row">
        <div className="col-xs-12 input-row ">
            <FormGroup className={classNames({ required: isRequired })}>
                {label && (
                    <FormLabel className="form-label w-100" htmlFor={id}>
                        <RenderTextArray text={label} />
                    </FormLabel>
                )}
                <DatePicker
                    onChange={date => setValue(date, localeDateFormat)}
                    onCalendarOpen={onCalendarOpen}
                    onClickOutside={onClickOutside}
                    selected={getSelectedDate(value, dateFormat)}
                    dateFormat={localeDateFormat}
                    locale={locale}
                    placeholderText={placeholder}
                    minDate={setRangeDate(startDate)}
                    maxDate={setRangeDate(endDate)}
                    className={classNames('form-control', hasError(status), customProps.customClassName)}
                    wrapperClassName="w-100"
                    showMonthDropdown
                    showYearDropdown
                    strictParsing
                    dropdownMode="select"
                    id={id}
                    isClearable={!!(customProps && customProps.isClearable)}
                    ariaRequired={isRequired ? 'true' : 'false'}
                    ariaInvalid={status === 'error' ? 'true' : 'false'}
                    ariaDescribedBy={id && `${id}_HelpText`}
                    customInput={
                        <input data-role={dataRole} id={id} onKeyPress={e => handleKeyPress(e, customProps)} />
                    }
                    popperModifiers={[
                        {
                            name: 'arrow',
                            options: {
                                padding: 10
                            }
                        }
                    ]}
                />
                {help && (
                    <div className="w-100">
                        <HelpText help={help} status={status} id={`${id}_HelpText`} />
                    </div>
                )}
            </FormGroup>
        </div>
    </div>
);

DatePickerWidget.displayName = 'DatePickerWidget';
