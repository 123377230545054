import { ChangeEvent } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '../../../../../app/store';
import {
    createFileUploadAction,
    createGetCurrentStructureAction,
    createPostActionWithType,
    createSynchronizeFormValuesAction,
    fileUploadErrorHandler,
    postponeStructureRefresh
} from '../../../../../common/actions/form';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import { getCustomValidation } from '../../../../../common/fileUploadUtils';
import { form, session } from '../../../../../common/reducers';
import { formStructurePollingStart, formStructurePollingStop } from '../../../../state/form/action';
import { handlePostponedStructureCheck } from '../../fields/handlePostponedStructureCheck';
import AsyncSingleFileInputWithDeleteView, {
    ComponentProps,
    DispatchProps,
    OwnProps,
    Props,
    StateProps
} from './AsyncSingleFileInputWithDeleteView';

export const ASYNC_SINGLE_FILE_UPLOAD_WITH_DELETE_TYPE = 'ASYNC_SINGLE_FILE_UPLOAD_WITH_DELETE';

export const FILE_STATUS = {
    EMPTY: 'EMPTY',
    ERROR: 'ERROR',
    UPLOADING: 'UPLOADING',
    UPLOADED: 'UPLOADED'
} as const;

export default connect(
    (state: RootState, { id }: OwnProps): StateProps => {
        // This should be common for all file input widgets
        const fieldData = form.getCustomData<ComponentProps>(state, id, [
            'accept',
            'xPath',
            'fileName',
            'fileSizeLimit',
            'status',
            'label',
            'mode',
            'isRequired',
            'help',
            'forceDeleteButton',
            'customProps',
            'dataRole',
            'postponeGetStructureTime',
            'tooltipMessage',
            'uploadMethod'
        ]);
        return {
            isUploading: fieldData.status === FILE_STATUS.UPLOADING,
            isUploaded: fieldData.status === FILE_STATUS.UPLOADED,
            isEmpty: fieldData.status === FILE_STATUS.EMPTY,
            error: fieldData.status === FILE_STATUS.ERROR,
            ...fieldData,
            conversationUuid: session.getConversationUuid(state),
            customValidation: getCustomValidation(state, id, fieldData.fileSizeLimit),
            id
        };
    },
    {
        refreshForm: createGetCurrentStructureAction,
        createFileUploadAction,
        createPostActionWithType,
        postponeStructureRefresh,
        syncValues: createSynchronizeFormValuesAction,
        startPolling: formStructurePollingStart,
        stopPolling: formStructurePollingStop
    },
    (
        { conversationUuid, xPath, postponeGetStructureTime, id, fileSizeLimit, uploadMethod, ...props }: StateProps,
        {
            refreshForm,
            createFileUploadAction,
            createPostActionWithType,
            postponeStructureRefresh,
            ...dispatchProps
        }: DispatchProps
    ): Props => ({
        ...props,
        id,
        refreshForm,
        ...dispatchProps,
        uploadFile: (e: ChangeEvent<HTMLInputElement>) => {
            handlePostponedStructureCheck(postponeGetStructureTime, refreshForm, conversationUuid);
            return createFileUploadAction(
                e.target.files,
                xPath,
                conversationUuid,
                id,
                { fileSizeLimit, uploadMethod }
                // @ts-expect-error Problem is related to thunk action which here is already unpacked
            ).catch(fileUploadErrorHandler);
        },
        removeFile: () => {
            createPostActionWithType(ACTION_TYPE.CLICK, xPath, xPath, conversationUuid);
            postponeStructureRefresh();
        }
    })
)(AsyncSingleFileInputWithDeleteView);
