import { Middleware, configureStore } from '@reduxjs/toolkit';

import { JobBoardData } from '../common/constants/jobBoard';
import { Translations } from '../common/constants/stringKeys';
import { epicMiddleware } from '../mirage/middlewares/epicMiddleware';
import { formSubmissionSuccessMiddleware } from '../mirage/middlewares/formSubmissionSuccessMiddleware';
import { invalidateFileUploadLogMiddleware } from '../mirage/middlewares/invalidateFileUploadLogMiddleware';
import { refreshOnScraperChangeMiddleware } from '../mirage/middlewares/refreshOnScraperChangeMiddleware';
import { responseFailureLogMiddleware } from '../mirage/middlewares/responseFailureLogMiddleware';
import { scrollOnErrorMiddleware } from '../mirage/middlewares/scrollOnErrorMiddleware';
import { showAlertIfFileTooLargeStateMiddleware } from '../mirage/middlewares/showAlertIfFileTooLargeStateMiddleware';
import { showAlertIfRequiredMiddleware } from '../mirage/middlewares/showAlertIfRequiredMiddleware';
import mirageReducer from '../mirage/reducers';

type PreloadedState = {
    session: {
        effectiveEndpointUrl: string | null;
        postApplicationUrl: string | null;
        jobBoard: JobBoardData;
    };
    language: Translations;
};

export function createStore(
    preloadedState: PreloadedState,
    middlewares: Middleware[] = [
        epicMiddleware,
        scrollOnErrorMiddleware,
        refreshOnScraperChangeMiddleware,
        showAlertIfRequiredMiddleware,
        responseFailureLogMiddleware,
        formSubmissionSuccessMiddleware,
        showAlertIfFileTooLargeStateMiddleware,
        invalidateFileUploadLogMiddleware
    ]
) {
    return configureStore({
        reducer: mirageReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
        preloadedState
    });
}

export type RootStore = ReturnType<typeof createStore>;

export type RootState = ReturnType<RootStore['getState']>;

export type AppDispatch = ReturnType<typeof createStore>['dispatch'];
