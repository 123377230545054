import { IMirageApplicationProgressWidget } from '@stepstone/ats-scrapers-core/types';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormGroup from '../FormGroup';
import { form } from '../../../../common/reducers';
import type { RootState } from '../../../../app/store';

type OwnProps = { id: string };

type ComponentProps = IMirageApplicationProgressWidget;

type Props = ComponentProps;

const ApplicationProgress: React.FC<Props> = ({ title, progress }) => (
    <div className="row">
        <div className="col-xs-12 input-row">
            <FormGroup>
                {title && <h4>{title}</h4>}
                {progress && !isNaN(progress) ? <ProgressBar variant="success" striped={true} now={progress} /> : null}
            </FormGroup>
        </div>
    </div>
);

ApplicationProgress.displayName = 'ApplicationProgress';

export default connect((state: RootState, { id }: OwnProps) => ({ ...form.getElement<ComponentProps>(state, id) }))(
    ApplicationProgress
);
