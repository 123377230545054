import { connect } from 'react-redux';

import { form } from '../../../../common/reducers/index';
import TextElementView, { TTextElementProps, TRef } from './TextElementView';
import type { RootState } from '../../../../app/store';

type TConnectedTextElementProps = {
    id: string;
    ref: TRef;
};

// @ts-expect-error 2322 refine while doing ATSI-2995
const mapStateToProps = (state: RootState, { id }: TConnectedTextElementProps): TTextElementProps => ({
    ...form.getTextElementData(state, id)
});

const connector = connect(mapStateToProps, null, null, {
    forwardRef: true
});

export default connector(TextElementView);
