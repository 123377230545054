import { getUrlParam, isValidUrl } from '../utils';
import { EFFECTIVE_ENDPOINT_URL_PARAM_NAME } from './constants';

const unescapeUrlBase64 = <T extends string | null>(escapedString: T) =>
    escapedString ? escapedString.replace(/-/g, '+').replace(/_/g, '/') : escapedString;

export const getPostApplicationUrl = (urlParameter: string | null): string | null => {
    const postApplicationUrl = urlParameter && decodeBase64(urlParameter);
    return postApplicationUrl && isValidUrl(postApplicationUrl) ? postApplicationUrl : null;
};

export const getEffectiveEndpointUrl = (): string | null => {
    const urlParameter = getUrlParam(EFFECTIVE_ENDPOINT_URL_PARAM_NAME);
    return urlParameter && decodeBase64(urlParameter);
};

export const decodeBase64 = (encodedString: string): string => {
    try {
        return atob(unescapeUrlBase64(encodedString));
    } catch (e) {
        return '';
    }
};

export const urlHasParam = (parameter: string): boolean => {
    try {
        return new URL(window.location.href).searchParams.has(parameter);
    } catch (e) {
        return false;
    }
};
